html,
body {
  font-family: "Manrope", sans-serif;
}

* {
  font-family: "Manrope", sans-serif;
}

h1#heading {
  background-color: #00aeef;
  text-align: left;
  color: white;
  width: fit-content;
  padding: 1px 11px;
  font-size: 23px;
  margin-left: 97px;
  margin-left: 97px;
  margin-bottom: -12px;
  font-weight: 600;
  margin-top: 65px !important;
  text-align: center !important;
}

.advisory,
.digital-marketing,
.Performance-Marketing {
  background-color: #f8f8f8;
  padding: 10px 38px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.main-wrapper {
  overflow-x: hidden !important;
}

.downloadpopup_popup__In2QG {
  bottom: -8px !important;
  width: 355px !important;
  border-top-left-radius: 49px !important;
  border-top-right-radius: 69px !important;
}

.react-tel-input.form-control.mt-2.contact {
  height: 48px;
}

.single-form.contact {
  height: 54px;
}

.single-form.interest {
  height: 49px;
}

.form-control.mt-2.bg_gray_select.country {
  height: 46px;
}

.form-control.mt-2.bg_gray_select.interest {
  height: 46px;
}

.downloadpopup_popupHeader__gXWP5 {
  border-top-left-radius: 49px !important;
  border-top-right-radius: 49px !important;
  line-height: 27px !important;
}

.downloadpopup_headDownload__IMlO3 {
  font-size: 25px !important;
}

.downloadpopup_closeButton___wYP1 {
  top: 16px !important;
  right: 35px !important;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ),
textarea {
  line-height: 20px !important;
  margin-bottom: 8px !important;
}

.mt-2 {
  margin-top: 3px !important;
}

p.title.pro-sol {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin-left: 48px;
  text-align: left;
  color: grey;
}

.react-tel-input.form-control.mt-2.my-1.contact {
  height: 46px !important;
}

select.form-control.my-1.bg_gray_select.country {
  height: 42px !important;
}

.single-form.country {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.section.overview-section.section-padding {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.overview-content {
  padding-top: 15px !important;
}

.faq-heads {
  color: #4c4d56;
  font-size: 25px;
}

a.popup-video-home {
  margin-top: 20px !important;
  width: 100px;
  height: 100px;
  background: #00aeef;
  display: inline-block;
  line-height: 100px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s linear;
  /* padding: 20px; */
}

a.popup-video-home:hover {
  background: #fff;
  color: #00aeef;
}

.iso-9001 {
  margin-left: 48px !important;
}

img.sliderimg {
  width: 100vw !important;
  height: auto !important;
}

.hero-content2 h2,
.hero-content1 h2 {
  color: white !important;
}

a#button\ index {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 107px;
  right: 19px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

div.language_es label {
  left: calc(50% - 0.5rem);
  line-height: 1;
  padding-top: 0px !important;
  position: relative;
  /* text-orientation: mixed; */
  -webkit-transform: rotate(180deg);
  /* transform: rotate(0deg); */
  transform: rotate(-90deg);
  white-space: nowrap;
  -webkit-writing-mode: vertical-rl;
  writing-mode: horizontal-tb;
  top: 75%;
  margin-right: 0px;
  text-align: center;
  justify-content: center;
}

div.language_es {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 14px;
  border-radius: 15px 0px 0px 15px;
}

div.language_es {
  position: fixed;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1055;
  right: 0;
  background: linear-gradient(
    45deg,
    #f17c58,
    #e94584,
    #24aadb,
    #27dbb1,
    #ffdc18,
    #ff3706
  );
  animation: gradient 5s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
  animation-direction: alternate;
  -webkit-animation: gradient 3s ease infinite;
  /* background: rgb(88, 97, 166);
                                            background: linear-gradient(90deg, rgba(88, 97, 166, 1) 0%, rgba(202, 52, 97, 1) 50%, rgba(243, 120, 52, 1) 100%); */
  /* background-color: #00aeef; */
  height: 37vh;
  width: 28px;
  background-size: 600%;
}

a#button\ index:hover {
  cursor: pointer;
  background-color: #333;
}

a#button\ index:active {
  background-color: #555;
}

a#button\ index.show {
  opacity: 1;
  visibility: visible;
}

.button-scroll.show {
  opacity: 1;
  visibility: visible;
}

.button-scroll {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 107px;
  right: 19px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

a#button\ index img {
  width: 45px !important;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.modal-dialog.contact {
  bottom: 0px !important;
  position: fixed !important;
  float: right !important;
  right: 85px !important;
  margin-bottom: -10px !important;
}

.modal-dialog.download {
  bottom: 0px !important;
  position: fixed !important;
  float: right !important;
  right: 85px !important;
  margin-bottom: -10px !important;
}

/* div#contactModal {
        overflow-y: hidden !important;
    } */

/* button.btn {
    font-size: 14px;
    } */

input#phones {
  width: 23vw !important;
}

.head-sec {
  padding: 2px 0px !important;
  width: 289px;
  margin-left: 0px !important;
  border-radius: 44px 29px 0px 0px !important;
}

.head-sec p span {
  font-size: 25px !important;
}

p.fs-5.aboutcontent {
  font-size: 16px !important;
  text-align: justify;
}

.modal-content.download {
  border-radius: 20px !important;
}

button.btn-close.download {
  float: right !important;
  border-radius: 50%;
  margin-top: 6px;
}

.head-sec {
  width: 344px;
  margin-left: 0px;
  border-radius: 44px 29px 0px 0px !important;
}

h2.title.abour-recogn {
  text-align: left !important;
}

h5.name {
  margin-top: 20px !important;
}

h3.explore-head {
  font-size: 20px;
  color: #8d8d8d;
  margin: 35px 0px;
  font-weight: 550;
}

.features-icon img {
  margin-top: 17px;
  margin-bottom: 10px;
}

.grecaptcha-badge {
  /* display: none !important; */
  visibility: hidden !important;
}

.section-title2.text-center h2 {
  text-transform: capitalize;
}

.iti--separate-dial-code .iti__selected-dial-code {
  color: #000 !important;
}

.iti__selected-flag {
  height: 77% !important;
}

.iti__flag-box,
.iti__country-name {
  color: #000 !important;
}

.single-item.text-center:hover {
  background-color: #00aeef94;
  color: #fff !important;
}

label.btn.btn-2.inactiveTab {
  background-color: #fff !important;
  color: #00aeef;
}

button.btn-close {
  float: right !important;
  background-color: #fff !important;
  padding: 6px !important;
  border: 1px solid #000 !important;
  margin-right: 7px;
}

div#tabs {
  padding-top: 20px;
  text-align: center;
}

#button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 107px;
  right: 19px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#button:hover {
  cursor: pointer;
  background-color: #333;
}

#button:active {
  background-color: #555;
}

#button.show {
  opacity: 1;
  visibility: visible;
}

body {
  font-size: 14px;
  line-height: 1.75;
  font-weight: 400;
  font-family: "Manrope", sans-serif !important;
  color: #4c4d56;
  outline: none;
  visibility: visible;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body[dir="rtl"] {
  text-align: right;
}

sapn.vertical {
  color: #00aeef;
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 16px;
  padding-top: 0;
}

.fs-5 {
  font-size: 16px !important;
}

h3.title {
  margin-top: 14px;
}

h1,
h2,
h4,
h5,
h6 {
  font-family: "Manrope", sans-serif;
  color: #090d2f;
  font-weight: 700;
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
}

.meet-form {
  margin-bottom: 10px;
}

.form-title.text-center h3 {
  margin-bottom: 10px;
  font-size: 21px;
}

button#btn-close {
  float: right !important;
}

.bgcolor-gray {
  background-color: #cbc1c1 !important;
}

.meet-form select {
  background-color: #fff;
  color: #415674;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
}

.book-meet-btn.text-center button {
  height: 45px;
}

h1 {
  font-size: 36px;
}

@media only screen and (min-width: 1890px) and (max-width: 1925px) {
  /* div#head-ban {
            height: 63vh;
        } */
  /* .carousel-inner {
            height: 70vh;
        } */
  /* .modal-dialog.contact {
            margin-left: 92pc !important;
            margin-top: 25.5pc !important;
            width: 308px !important;
        } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */
  .sticky-button {
    top: 56% !important;
    height: 17vh !important;
  }

  input#phones {
    width: 17.3vw !important;
  }

  .head-sec {
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
    width: 365px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 10px !important;
  }

  button.btn-close.download {
    margin-top: -10px;
    margin-left: -19px;
  }

  .modal-body.download {
    padding: 0px 0px 10px 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }

  img.img-fluid.lrs {
    margin-left: 58px !important;
  }

  .modal-dialog.contact {
    top: 0px !important;
    position: fixed !important;
    float: right !important;
    right: 0px !important;
    margin-bottom: 0px !important;
    margin-left: -96px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (min-width: 1281px) and (max-width: 1345px) {
  /* .modal-dialog.contact {
            margin-left: 55pc !important;
            margin-top: 12pc !important;
        } */

  input#phones {
    width: 20vw !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  /* .hero-images.sk img {
        width: 81% !important;
    }

    .hero-images.sk1 img {
        width: 74% !important;
    }

    .hero-images.sk2 img {
        width: 85% !important;
    } */

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .header-menu .main-menu li a {
    font-size: 15px !important;
  }

  .sticky-button {
    top: 58% !important;
    height: 20vh !important;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-bottom: 0;
}

p + p {
  margin-top: 10px;
}

a,
button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input {
  transition: all 0.3s linear;
  outline: 0;
}

a:focus,
button:focus,
img:focus,
input:focus {
  outline: 0;
}

a:focus {
  color: none;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #00aeef;
}

ul,
ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
}

span.meet-btn button {
  border: 2px solid #00aeef;
  background-color: #00aeef;
  color: #fff;
  border-radius: 4px;
  padding: 0 35px;
  font-weight: 700;
  font-size: 17px;
  margin-top: 20px;
  transition: all 0.3s ease 0;
  cursor: pointer;
  height: 46px;
}

.case-study-item {
  height: 100%;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

.section,
.main-wrapper {
  float: left;
  width: 100%;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fff !important;
}

@media only screen and (max-width: 1379px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff !important;
  }
}

.section-padding-02 {
  padding-top: 100px;
}

@media only screen and (max-width: 1379px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

.section-padding-03 {
  padding-top: 100px;
}

@media only screen and (max-width: 1379px) {
  .section-padding-03 {
    padding-top: 21px;
  }
}

.section-title {
  /* max-width: 690px; */
  margin: 0 auto;
  text-align: center;
  padding-bottom: 40px;
}

p.text-center.fs-4 {
  font-size: 20px !important;
}

.section-title .title {
  font-size: 48px;
  line-height: 54px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  text-align: left;
}

h2.title.discover {
  font-size: 48px;
  text-align: center;
}

@media only screen and (max-width: 1379px) {
  .section-title .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  #homeModal .modal-body img {
    margin-top: 67px;
  }
}

@media only screen and (min-width: 812px) and (max-width: 900px) {
  #homeModal .modal-body .btn-close {
    right: 14px !important;
    top: 30px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 13px !important;
    padding-bottom: 11px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 28px !important;
    padding-bottom: 26px !important;
  }

  .hero-images.sk img {
    width: 40vw !important;
  }

  .hero-images.sk1 img {
    width: 32vw !important;
  }

  .hero-images.sk2 img {
    width: 35.7vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }

  .sticky-button {
    height: 13vh !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 767px) {
  #homeModal .modal-body .btn-close {
    right: 21px !important;
    top: 71px !important;
    font-size: 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  #homeModal .modal-body .btn-close {
    right: 65px !important;
    top: 3px !important;
  }

  input#phone-foot {
    width: 256px !important;
  }

  .header-menu .main-menu li a {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 968px) and (max-width: 1024px) {
  #homeModal .modal-body .btn-close {
    right: 25px !important;
    top: 29px !important;
  }

  input#phone-foot {
    width: 258px !important;
  }

  .carousel-control-next-icon.solu {
    margin-left: -3rem !important;
  }

  .carousel-control-next-icon.solu,
  .carousel-control-prev-icon.solu {
    width: 2rem !important;
    height: 2rem !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }

  .sticky-button label {
    font-size: 18px !important;
  }

  .sticky-button {
    height: 20vh !important;
    top: 56% !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 54px !important;
    padding-bottom: 57px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 53px !important;
    padding-bottom: 58px !important;
  }

  .head-sec {
    width: 289px !important;
  }

  .hero-images.sk img {
    width: 54vw !important;
  }

  .hero-images.sk1 img {
    width: 50vw !important;
  }

  .hero-images.sk2 img {
    width: 50vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1680px) {
  #homeModal .modal-body img {
    margin-top: 60px;
  }

  #homeModal .modal-body .btn-close {
    right: 10px !important;
    top: 87px !important;
  }

  .section-padding-03 {
    padding-top: 57px !important;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2000px) {
  #homeModal .modal-body img {
    margin-top: 27px;
  }

  #homeModal .modal-body .btn-close {
    right: 21px !important;
    top: 71px !important;
  }
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.section-title .sub-title {
  font-size: 20px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #00aeef;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.map-section .section-title2 p,
.map-section .section-title2 .title {
  color: #fff;
}

.section-title2 {
  max-width: 690px;
  margin: 0 auto;
}

.section-title2 .title {
  font-size: 48px;
  line-height: 54px;
  font-family: Manrope, sans-serif;
  font-weight: 800;
  color: rgb(51, 51, 51);
}

.section-title2 .title span {
  color: #00aeef;
}

@media only screen and (max-width: 1379px) {
  .section-title2 .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title2 .title {
    font-size: 28px;
    line-height: 36px;
    margin: 29px 0px;
  }

  a.popup-video {
    margin-top: 20px !important;
  }

  .winner-award {
    margin-top: 24px !important;
  }

  .retail {
    width: 42% !important;
    margin-left: 116px !important;
  }

  .digital {
    width: 46% !important;
    margin-left: 107px !important;
  }

  .scan-n-play {
    width: 46% !important;
    margin-left: 120px !important;
  }

  .main-wrapper {
    overflow-x: hidden !important;
  }

  .instant-lotto {
    width: 77% !important;
    margin-left: 46px !important;
  }

  .paper-lotto {
    margin-left: 110px !important;
  }

  .image.lrs img {
    margin-left: 49px !important;
  }

  .LRS {
    margin-left: 154px !important;
  }

  .cashless {
    width: 283px !important;
    margin-left: 68px !important;
  }

  /* .sport-lotto {
      width: 100% !important;
      margin-left: 99px !important;
      margin: auto;
    } */

  .turnkey-solu {
    width: 77% !important;
    margin-left: 52px !important;
  }

  p.text.fs-5.LGS {
    font-size: 16px !important;
  }

  p.text.fs-5 {
    font-size: 16px !important;
  }

  h1#heading {
    margin-top: 65px !important;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    /* width: 76%; */
    /* text-wrap: nowrap; */
  }

  p.title.pro-sol {
    text-align: center !important;
    margin-left: 0px !important;
  }

  p.title {
    font-size: 23px !important;
  }

  .top-left {
    left: 0px;
    margin-left: 0px !important;
    font-size: 13px !important;
  }

  /* p.title.pro-sol {
        text-align: center !important;
    } */

  /* .justify-content-center {
        justify-content: flex-start !important;
    } */

  li#faq {
    margin-left: 83px;
  }

  .section.features-section-7.section-padding-03.faq {
    padding-top: 0px !important;
  }

  .section.faq-section.section-padding-02 {
    padding-top: 0px !important;
  }

  input#phonenum {
    width: 86vw !important;
  }

  .features-section-3
    .features-wrap-3
    .features-content-wrap
    .single-item
    .features-content
    p {
    font-size: 15px !important;
    text-align: center !important;
  }

  input#phones {
    /* width: 100% !important; */
    width: 343px !important;
  }

  .features-img-wrap img {
    margin-top: 25px !important;
  }

  p.text.fs-5.LGS {
    font-size: 16px !important;
    margin-bottom: -15px !important;
  }
}

.section-title2 .sub-title {
  font-size: 12px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #a1a1a1;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-title2 p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #4c4d56;
  padding: 0 50px;
  margin-top: 20px;
}

@media only screen and (min-width: 2500px) and (max-width: 3700px) {
  .head-sec {
    width: 289px !important;
  }

  input#phones {
    width: 8.3vw !important;
  }
}

@media only screen and (min-width: 3300px) and (max-width: 3790px) {
  .head-sec {
    width: 288px !important;
  }

  input#phones {
    width: 6.89vw !important;
  }
}

@media only screen and (min-width: 2350px) and (max-width: 2492px) {
  .head-sec {
    width: 289px !important;
  }

  input#phones {
    width: 8.3vw !important;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 2349px) {
  .head-sec {
    width: 289px !important;
  }

  input#phones {
    width: 11.5vw !important;
  }
}

@media only screen and (min-width: 2100px) and (max-width: 2199px) {
  .head-sec {
    width: 289px !important;
  }

  input#phones {
    width: 11.5vw !important;
  }
}

@media only screen and (min-width: 1999px) and (max-width: 2099px) {
  .head-sec {
    width: 289px !important;
  }

  input#phones {
    width: 12.5vw !important;
  }
}

@media only screen and (max-width: 991px) {
  .section-title2 p {
    padding: 0 15px;
  }
}

.section-title3 {
  max-width: 925px;
  margin: 0 auto;
}

.section-title3 .title {
  font-size: 48px;
  line-height: 72px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 1379px) {
  .section-title3 .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title3 .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.section-title3 .sub-title {
  font-size: 14px;
  height: 40px;
  line-height: 38px;
  padding: 0 40px;
  border-radius: 35px;
  display: inline-block;
  background: #ffeeeb;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #ff5538;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.separator-section {
  background: #000c29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  background-image: url("/assets/images/bg/testi-bg.jpg");
  margin-top: 20px;
}

.separator-section .separator-wrap .section-title {
  text-align: center;
}

.separator-section .separator-wrap .section-title .title {
  color: #fff;
}

.list-style-type1 {
  padding: 15px 0;
  line-height: 1.6;
  font-size: 1.05em;
}

.list-style-type1 li {
  display: flex;
}

.list-style-type1 li + li {
  margin-top: 10px;
}

.list-style-type1 li:before {
  display: block;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  color: #5149f3;
  font-weight: 900;
  padding-top: 2px;
}

.techmax-pagination .pagination .page-item {
  margin: 0 3px;
}

.techmax-pagination .pagination .page-item .page-link {
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 33px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #6c7381;
  border: 0;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
}

.techmax-pagination .pagination .page-item .page-link:focus {
  box-shadow: none;
}

.techmax-pagination .pagination .page-item .page-link:hover {
  background: #00aeef;
  color: #fff;
}

.techmax-pagination .pagination .page-item .page-link i {
  font-size: 12px;
}

.techmax-pagination .pagination .page-item.active .page-link {
  background: #00aeef;
  color: #fff;
}

.swiper-pagination .swiper-pagination-bullet {
  border-radius: 50%;
  background-color: #fff;
  width: 13px;
  height: 13px;
  transition: all 0.3s linear;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #5abaf7;
}

.slider-bullet .swiper-pagination {
  position: relative;
  margin-top: 30px;
}

.slider-bullet .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-repeat: 50%;
  background: #e1e1e1;
}

.slider-bullet
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00aeef;
}

.preloader-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
}

.preloader-main .preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader-main .preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00aeef;
  animation: preloader 1.3s linear infinite;
}

.preloader-main .preloader span:last-child {
  animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

label {
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ),
textarea {
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #415674;
  border-radius: 4px;
  background-color: #f1f1f1;
  margin-bottom: 0;
  padding: 10px 25px;
  max-width: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.3s linear;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):focus,
textarea:focus {
  outline: none;
  border-color: #00aeef;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

textarea.form-control.empty-class {
  background-color: #f1f1f1 !important;
}

select {
  appearance: none;
  /* background-image: url(../images/icons/select-arrow-down.png); */
  background-position: calc(100% - 25px) 50%;
  background-repeat: no-repeat;
  font-family: "Roboto", sans-serif;
  width: 100%;
  border: none;
  background-color: #f1f1f1;
  line-height: 24px;
  font-weight: 300;
  height: 50px;
  padding: 10px 44px 10px 21px;
  color: #4c4d56;
  border-radius: 0;
  max-width: 500px;
  border: 1px solid #ebebeb;
}

.btn-margin {
  margin-top: 40px;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.btn-wrap .btn {
  margin: 5px;
}

.btn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #00aeef;
  border-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  height: 40px;
  line-height: 20px;
  padding: 0 55px;
  border: 2px solid #00aeef;
  border-style: solid;
  box-shadow: none;
  transition: all 0.3s ease 0;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.btn::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  left: -20%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  background: #fff;
  transform: skewX(45deg) scale(0, 1);
  transition: all 0.5s ease 0;
}

.btn:focus,
.btn:focus:active {
  box-shadow: none;
}

.btn:hover {
  box-shadow: none;
  color: #00aeef;
}

.btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.btn-2 {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding: 0 45px;
}

.btn-3 {
  background: #ff5538;
  border: 2px solid #ff5538;
}

.btn-3:hover {
  color: #ff5538;
}

.btn-round {
  border-radius: 100px;
}

.btn-square {
  border-radius: 0;
}

[class*="btn-icon-"] i {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon-left {
  padding-left: 69px;
}

.btn-icon-left i {
  left: 0;
  right: auto;
}

.btn-icon-right {
  padding-right: 69px;
}

.btn-icon-right i {
  left: auto;
  right: 0;
}

.btn-primary {
  background-color: #00aeef;
  border-color: #00aeef;
  color: #fff;
}

.btn-primary:hover {
  background-color: #00aeef;
  border-color: #00aeef;
}

.btn-secondary {
  background-color: #000c29;
  border-color: #000c29;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #000c29;
  border-color: #000c29;
}

.btn-white {
  background-color: #fff;
  border-color: #fff;
  color: #090d2f;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.btn-white::after {
  background: #00aeef;
}

.btn-white:hover {
  color: #fff;
}

.transparent-header {
  position: absolute;
  left: 2%;
  top: 0;
  background: #fff;
  width: 95%;
  z-index: 999;
  right: 2.5%;
  border-radius: 42px;
}

@media only screen and (max-width: 991px) {
  .transparent-header {
    padding: 15px 0;
  }
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo a img {
  width: 150px;
}

@media only screen and (max-width: 1379px) {
  .header-logo a img {
    width: 130px;
  }
}

.header-menu .main-menu {
  display: flex;
}

.header-menu .main-menu li {
  position: relative;
  padding: 30px 18px;
}

.header-menu .main-menu .li_es {
  padding: 30px 10px !important;
}

@media only screen and (max-width: 1379px) {
  .header-menu .main-menu li {
    padding: 20px 15px;
  }
}

.header-menu .main-menu li a {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  color: #090d2f;
}

.header-menu .main-menu li.active-menu > a {
  color: #00aeef;
}

.header-menu .main-menu li.active-menu > .menu-icon::after {
  color: #00aeef;
}

.header-menu .main-menu li .menu-icon::after {
  content: "\f16c";
  font-family: "flaticon";
  font-size: 10px;
  color: #94a0b9;
  margin-left: 5px;
  transition: all 0.3s linear;
}

.header-menu .main-menu li:hover > .menu-icon::after {
  color: #00aeef;
}

.header-menu .main-menu li:hover > a {
  color: #00aeef;
}

.header-menu .main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-menu .main-menu li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 270px;
  background: #fff;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  border-radius: 30px;
  border-top: 3px solid #00aeef;
  opacity: 0;
  z-index: 99;
  visibility: hidden;
  transition: all 0.3s linear;
}

.header-menu .main-menu li .sub-menu li {
  padding: 0;
}

.header-menu .main-menu li .sub-menu li a {
  padding: 5px 10px 5px 25px;
  font-size: 15px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  position: relative;
  z-index: 1;
}

.header-menu .main-menu li .sub-menu li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #00aeef;
  width: 10px;
  height: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.header-menu .main-menu li .sub-menu li.active > a {
  color: #00aeef;
  padding-left: 35px;
}

.header-menu .main-menu li .sub-menu li.active > a::before {
  opacity: 1;
  visibility: visible;
  left: 15px;
}

.header-menu .main-menu li .sub-menu li:hover > a {
  color: #00aeef;
  padding-left: 35px;
}

.header-menu .main-menu li .sub-menu li:hover > a::before {
  opacity: 1;
  visibility: visible;
  left: 15px;
}

.header-meta {
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* Added Gap*/
  gap: 10px;
}

.header-info {
  align-items: end;
}

.header-info .info-icon i {
  font-size: 25px;
  color: #00aeef;
}

.header-info .info-text {
  margin-left: 10px;
}

.header-info .info-text span {
  display: block;
  font-size: 10px;
  line-height: 18px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: #ababab;
}

.header-info .info-text .number {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: #090d2f;
}

.header-search {
  margin-left: 25px;
}

.header-search .search-btn {
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  color: #333;
  border: 1px solid #ddd;
  transition: all 0.3s linear;
}

.header-search .search-btn:hover {
  color: #00aeef;
}

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  background: rgba(0, 0, 0, 0.9);
}

.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.search-wrap .search-inner .search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s linear;
}

.search-wrap .search-inner .search-close:hover {
  transform: rotate(90deg);
  color: #00aeef;
}

.search-wrap .search-inner .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.search-wrap .search-inner .search-cell .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .search-wrap .search-inner .search-cell .search-field-holder {
    width: 60%;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder {
    width: 80%;
  }
}

.search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: uppercase;
  background: transparent;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  border-bottom: 2px solid #898989;
  text-align: center;
  letter-spacing: 2px;
}

@media only screen and (max-width: 1379px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input {
    padding: 0 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input {
    font-size: 16px;
    padding: 0 15px;
  }
}

.search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 1379px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-webkit-input-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-webkit-input-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-webkit-input-placeholder {
    font-size: 14px;
  }
}

.search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input:-moz-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 1379px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-moz-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-moz-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-moz-placeholder {
    font-size: 14px;
  }
}

.search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input::-moz-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 1379px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-moz-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-moz-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input::-moz-placeholder {
    font-size: 14px;
  }
}

.search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 1379px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-ms-input-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-ms-input-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap
    .search-inner
    .search-cell
    .search-field-holder
    .main-search-input:-ms-input-placeholder {
    font-size: 14px;
  }
}

.header-cart {
  margin-left: 10px;
}

.header-cart .cart-btn {
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  background: none;
  color: #333;
  border: 1px solid #ddd;
  position: relative;
  transition: all 0.3s linear;
}

.header-cart .cart-btn:hover {
  color: #00aeef;
}

.header-cart .cart-btn .count {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #00aeef;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 400;
  display: inline-block;
  color: #fff;
  position: absolute;
  top: -5px;
  right: -10px;
}

.dropdown-cart {
  max-width: 370px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  border: 0;
  transform: translateX(-50%) !important;
  left: 50% !important;
  top: 63px !important;
}

@media only screen and (max-width: 1399px) {
  .dropdown-cart {
    left: 0 !important;
    transform: translateX(-85%) !important;
  }
}

@media only screen and (max-width: 991px) {
  .dropdown-cart {
    top: 56px !important;
  }
}

@media only screen and (max-width: 449px) {
  .dropdown-cart {
    left: 0 !important;
    transform: translateX(-72%) !important;
  }
}

.dropdown-cart .cart-items {
  height: 280px;
  overflow-y: auto;
}

.dropdown-cart .cart-items .single-cart-item {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-cart .cart-items .single-cart-item + .single-cart-item {
  margin-top: 30px;
}

.dropdown-cart .cart-items .single-cart-item .item-image img {
  border-radius: 50%;
}

.dropdown-cart .cart-items .single-cart-item .item-content {
  flex: 1;
  padding-right: 25px;
  padding-left: 20px;
}

.dropdown-cart .cart-items .single-cart-item .item-content .title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  line-height: 1.3;
}

.dropdown-cart .cart-items .single-cart-item .item-content .quantity {
  font-size: 14px;
  color: #00aeef;
  line-height: 1.75;
  display: block;
}

.dropdown-cart .cart-items .single-cart-item .btn-close {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 0;
  right: 5px;
}

.dropdown-cart .cart-total {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.dropdown-cart .cart-total .label {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.75;
  display: block;
  color: #000;
}

.dropdown-cart .cart-total .value {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.75;
  display: block;
  color: #00aeef;
}

.dropdown-cart .cart-btns {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.dropdown-cart .cart-btns .btn {
  height: 50px;
  line-height: 46px;
  font-size: 16px;
  padding: 0 35px;
  width: 48%;
}

.dropdown-cart .cart-btns .btn.btn-2 {
  background: #000c29;
  border: 2px solid transparent;
  transition: all 0.3s linear;
}

.dropdown-cart .cart-btns .btn.btn-2:hover {
  color: #000c29;
}

.header-section-2 .header-wrap .header-menu-2 {
  position: relative;
  padding-left: 20px;
}

@media only screen and (max-width: 1399px) {
  .header-section-2 .header-wrap .header-menu-2 {
    padding-left: 10px;
  }
}

.header-section-2 .header-wrap .header-menu-2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 44px;
}

.header-section-2 .header-wrap .header-menu-2 .main-menu > li {
  padding: 40px 25px;
}

@media only screen and (max-width: 1399px) {
  .header-section-2 .header-wrap .header-menu-2 .main-menu > li {
    padding: 40px 15px;
  }
}

.header-section-2 .header-wrap .header-meta .header-search {
  margin-left: 0;
}

.header-section-2 .header-wrap .header-meta .header-search .search-btn {
  border: none;
}

.header-section-2 .header-wrap .header-meta .header-cart {
  margin-left: 0;
}

.header-section-2 .header-wrap .header-meta .header-cart .cart-btn {
  border: none;
}

.header-section-2 .header-wrap .header-meta .header-cart .cart-btn .count {
  position: absolute;
  top: 0;
  right: -2px;
}

.header-section-2 .header-wrap .header-meta .header-info {
  position: relative;
  padding-left: 30px;
  margin-left: 30px;
}

@media only screen and (max-width: 1399px) {
  .header-section-2 .header-wrap .header-meta .header-info {
    padding-left: 20px;
    margin-left: 20px;
  }
}

.header-section-2 .header-wrap .header-meta .header-info::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 44px;
}

.header-section-2 .header-wrap .header-meta .header-btn-2 {
  /* margin-left: 30px */
  gap: 10px;
}

@media only screen and (max-width: 1399px) {
  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    /* margin-left: 20px */
    gap: 6px;
  }
}

.header-section-2 .header-wrap .header-meta .header-btn-2 .btn {
  height: 40px;
  line-height: 36px;
  padding: 0 30px;
}

.header-logo-3 .logo-black {
  display: none;
}

.transparent-header-3 {
  background: transparent;
  border-bottom: 1px solid #b5b5b5;
}

.transparent-header-3.sticky {
  background: #fff;
  border-bottom: 0;
}

.transparent-header-3.sticky .header-wrap .header-logo-3 .logo-black {
  display: block;
}

.transparent-header-3.sticky .header-wrap .header-logo-3 .logo-white {
  display: none;
}

.transparent-header-3.sticky .header-wrap .header-menu-3 .main-menu li a {
  color: #090d2f;
}

.transparent-header-3.sticky
  .header-wrap
  .header-menu-3
  .main-menu
  li
  .menu-icon::after {
  color: #94a0b9;
}

.transparent-header-3.sticky
  .header-wrap
  .header-meta
  .header-info
  .info-icon
  i {
  color: #ff5538;
}

.transparent-header-3.sticky
  .header-wrap
  .header-meta
  .header-info
  .info-text
  span {
  color: #ababab;
}

.transparent-header-3.sticky
  .header-wrap
  .header-meta
  .header-info
  .info-text
  .number {
  color: #090d2f;
}

.transparent-header-3.sticky
  .header-wrap
  .header-meta
  .header-search
  .search-btn {
  color: #333;
  border: 1px solid #ddd;
}

.transparent-header-3.sticky
  .header-wrap
  .header-meta
  .header-search
  .search-btn:hover {
  color: #ff5538;
}

.transparent-header-3.sticky .header-wrap .header-meta .header-cart .cart-btn {
  color: #333;
  border: 1px solid #ddd;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li a {
  color: #fff;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li.active-menu > a {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-menu-3
  .main-menu
  li.active-menu
  > .menu-icon::after {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li .menu-icon::after {
  color: #fff;
}

.header-section-3
  .header-wrap
  .header-menu-3
  .main-menu
  li:hover
  > .menu-icon::after {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li:hover > a {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li .sub-menu {
  border-top: 3px solid #ff5538;
}

.header-section-3 .header-wrap .header-menu-3 .main-menu li .sub-menu li a {
  color: #090d2f;
}

.header-section-3
  .header-wrap
  .header-menu-3
  .main-menu
  li
  .sub-menu
  li
  a::before {
  background: #ff5538;
}

.header-section-3
  .header-wrap
  .header-menu-3
  .main-menu
  li
  .sub-menu
  li.active
  > a {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-menu-3
  .main-menu
  li
  .sub-menu
  li:hover
  > a {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-meta .header-info .info-icon i {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-meta .header-info .info-text span {
  color: #fff;
}

.header-section-3 .header-wrap .header-meta .header-info .info-text .number {
  color: #fff;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-info
  .info-text
  .number
  a:hover {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-meta .header-search .search-btn {
  color: #fff;
  border: 1px solid #fff;
}

.header-section-3 .header-wrap .header-meta .header-search .search-btn:hover {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-search
  .search-wrap
  .search-inner
  .search-close:hover {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-search
  .search-wrap
  .search-inner
  .search-cell
  .search-field-holder
  .main-search-input:focus {
  border-color: #ff5538;
}

.header-section-3 .header-wrap .header-meta .header-cart .cart-btn {
  color: #fff;
  border: 1px solid #fff;
}

.header-section-3 .header-wrap .header-meta .header-cart .cart-btn:hover {
  color: #ff5538;
}

.header-section-3 .header-wrap .header-meta .header-cart .cart-btn .count {
  background: #ff5538;
  color: #fff;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-items
  .single-cart-item
  .item-image
  img {
  border-radius: 50%;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-items
  .single-cart-item
  .item-content
  .title
  a:hover {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-items
  .single-cart-item
  .item-content
  .quantity {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-total
  .value {
  color: #ff5538;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-btns
  .btn {
  height: 50px;
  line-height: 46px;
  font-size: 16px;
  padding: 0 35px;
  width: 48%;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-btns
  .btn.btn-2 {
  background: #000c29;
  border: 2px solid transparent;
  transition: all 0.3s linear;
}

.header-section-3
  .header-wrap
  .header-meta
  .header-cart
  .dropdown-cart
  .cart-btns
  .btn.btn-2:hover {
  color: #000c29;
}

.header-toggle {
  margin-left: 30px;
}

.header-toggle button {
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle button span {
  width: 25px;
  height: 2px;
  background: #00aeef;
  margin: 5px 0;
  display: block;
}

.header-toggle button span:last-child {
  margin-bottom: 0;
}

.header-toggle-3 button span {
  background: #ff5538;
}

.header-section-4 .header-wrap .header-menu {
  margin-left: auto;
}

.header-section-4 .header-wrap .header-meta-4 {
  margin-left: 15px;
}

.header-section-4 .header-wrap .header-meta-4 .header-search {
  position: relative;
  margin-right: 5px;
  padding-right: 5px;
}

.header-section-4 .header-wrap .header-meta-4 .header-search::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 30px;
}

.header-section-5 {
  background: rgba(0, 0, 0, 0.7);
}

.header-section-5 .header-wrap .header-logo .logo-black {
  display: none;
}

.header-section-5 .header-wrap .header-menu .main-menu li a {
  color: #fff;
}

.header-section-5 .header-wrap .header-menu .main-menu li.active-menu > a {
  color: #00aeef;
}

.header-section-5
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > .menu-icon::after {
  color: #00aeef;
}

.header-section-5 .header-wrap .header-menu .main-menu li .menu-icon::after {
  color: #fff;
}

.header-section-5
  .header-wrap
  .header-menu
  .main-menu
  li:hover
  > .menu-icon::after {
  color: #00aeef;
}

.header-section-5 .header-wrap .header-menu .main-menu li:hover > a {
  color: #00aeef;
}

.header-section-5 .header-wrap .header-menu .main-menu li .sub-menu {
  border-top: 3px solid #00aeef;
}

.header-section-5 .header-wrap .header-menu .main-menu li .sub-menu li a {
  color: #090d2f;
}

.header-section-5
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li
  a::before {
  background: #00aeef;
}

.header-section-5
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li.active
  > a {
  color: #00aeef;
}

.header-section-5
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li:hover
  > a {
  color: #00aeef;
}

.header-section-5 .header-wrap .header-meta .header-search .search-btn {
  color: #fff;
}

.header-section-5 .header-wrap .header-meta .header-cart .cart-btn {
  color: #fff;
}

.header-section-5.sticky {
  background: #fff;
}

.header-section-5.sticky .header-wrap .header-logo .logo-black {
  display: block;
}

.header-section-5.sticky .header-wrap .header-logo .logo-white {
  display: none;
}

.header-section-5.sticky .header-wrap .header-menu .main-menu li a {
  color: #090d2f;
}

.header-section-5.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > a {
  color: #00aeef;
}

.header-section-5.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > .menu-icon::after {
  color: #00aeef;
}

.header-section-5.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .menu-icon::after {
  color: #fff;
}

.header-section-5.sticky
  .header-wrap
  .header-menu
  .main-menu
  li:hover
  > .menu-icon::after {
  color: #00aeef;
}

.header-section-5.sticky .header-wrap .header-menu .main-menu li:hover > a {
  color: #00aeef;
}

.header-section-5.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .menu-icon::after {
  color: #94a0b9;
}

.header-section-5.sticky .header-wrap .header-meta .header-search .search-btn {
  color: #333;
}

.header-section-5.sticky .header-wrap .header-meta .header-cart .cart-btn {
  color: #333;
}

.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.07);
  animation: sticky 1s;
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.offcanvas {
  background: #000c29;
  width: 320px;
}

@media only screen and (max-width: 575px) {
  .offcanvas {
    width: 280px;
  }
}

.offcanvas-header .close-btn {
  background: none;
  border: 0;
  font-size: 18px;
  color: #fff;
  transition: all 0.3s linear;
}

.offcanvas-header .close-btn:hover {
  transform: rotate(90deg);
  color: #00aeef;
}

.offcanvas-logo a img {
  width: 130px;
}

.offcanvas-menu {
  padding: 30px 0;
}

.offcanvas-menu .main-menu li {
  position: relative;
}

.offcanvas-menu .main-menu li a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: #fff;
  padding: 5px 0;
  position: relative;
}

.offcanvas-menu .main-menu li .menu-expand {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.offcanvas-menu .main-menu li .menu-expand::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 2px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li .menu-expand::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  bottom: 0;
  background-color: #fff;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li:hover > a {
  color: #00aeef;
}

.offcanvas-menu .main-menu li.active > .menu-expand::after {
  height: 0;
}

.offcanvas-menu .main-menu li ul {
  padding-left: 6%;
  /* display: none; */
}

.tech-hero-section {
  height: 1120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 1;
}

.tech-hero-section .shape-1 {
  width: 417px;
  height: 417px;
  background: linear-gradient(150deg, #035cdd 0%, #4ecdea 100%);
  opacity: 0.9;
  border-radius: 50%;
  position: absolute;
  top: 115px;
  left: 50px;
  z-index: -1;
}

.tech-hero-section .hero-content {
  padding-top: 290px;
  max-width: 520px;
}

.tech-hero-section .hero-content .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}

.tech-hero-section .hero-content .title {
  font-size: 72px;
  line-height: 92px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}

.tech-hero-section .hero-content p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
}

.tech-hero-section .hero-content .hero-btn {
  margin-top: 40px;
}

@media only screen and (min-width: 3800px) and (max-width: 3840px) {
}

@media only screen and (max-width: 1399px) {
  .tech-hero-section {
    height: 920px;
  }
}

@media only screen and (max-width: 1379px) {
  /* .tech-hero-section {
        height: 920px
    } */

  /* .tech-hero-section-6 .hero-content2 {
        margin-top: -7px !important;
    } */

  /* 
    .tech-hero-section-6 .hero-content1 {
        margin-top: -18px !important;
        margin-left: 108px !important;
    } */

  .tech-hero-section .shape-1 {
    width: 350px;
    height: 350px;
    top: 115px;
    left: 170px;
  }

  .page-banner-section {
    min-height: 0px;
  }

  .tech-hero-section .hero-content {
    padding-top: 250px;
  }

  .tech-hero-section .hero-content .title {
    font-size: 24px !important;
    line-height: 72px;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 24px !important;
  }

  .tech-hero-section-6 .hero-content2 .title {
    font-size: 24px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: -24px;
    margin-left: 121px !important;
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section {
    height: 780px;
  }

  .tech-hero-section .hero-content {
    padding-top: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section .shape-1 {
    width: 250px;
    height: 250px;
    left: 30px;
  }

  .tech-hero-section .hero-content .title {
    font-size: 48px;
    line-height: 62px;
  }

  .tech-hero-section .hero-content .hero-btn .btn {
    padding: 0 45px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section .hero-content .sub-title {
    font-size: 14px;
    line-height: 26px;
  }

  .tech-hero-section .hero-content .title {
    font-size: 36px;
    line-height: 48px;
  }

  .tech-hero-section .hero-content p {
    font-size: 16px;
  }
}

.tech-hero-section-2 {
  height: 955px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.tech-hero-section-2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #00213a;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.tech-hero-section-2 .shape-1 {
  width: 578px;
  height: 315px;
  background-image: linear-gradient(290deg, #54d6eb 0%, #035cdd 100%);
  transform: skewY(15deg);
  position: absolute;
  left: 7.5%;
  top: 25%;
  z-index: -1;
}

.tech-hero-section-2 .shape-2 {
  width: 578px;
  height: 315px;
  background-image: linear-gradient(290deg, #54d6eb 0%, #035cdd 100%);
  transform: skewY(15deg);
  position: absolute;
  left: 3.5%;
  top: 50%;
  z-index: -1;
}

.tech-hero-section-2 .shape-3 {
  width: 578px;
  height: 315px;
  background: #fff;
  opacity: 0.2;
  transform: skewY(15deg);
  position: absolute;
  left: 12%;
  top: 40%;
  z-index: -1;
}

.tech-hero-section-2 .shape-4 {
  width: 578px;
  height: 315px;
  background: linear-gradient(90deg, #54d6eb 0%, #035cdd 100%);
  opacity: 0.8;
  transform: skewY(15deg);
  position: absolute;
  right: -10%;
  top: 76%;
  z-index: -1;
}

.tech-hero-section-2 .shape-5 {
  width: 358px;
  height: 195px;
  background: linear-gradient(280deg, #54d6eb 0%, #035cdd 100%);
  opacity: 0.8;
  transform: skewY(15deg);
  position: absolute;
  right: -6%;
  top: 67%;
  z-index: -1;
}

.tech-hero-section-2 .hero-content {
  max-width: 630px;
}

.tech-hero-section-2 .hero-content .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}

.tech-hero-section-2 .hero-content .title {
  font-size: 60px;
  line-height: 78px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}

.tech-hero-section-2 .hero-content .hero-btn {
  margin-top: 40px;
}

@media only screen and (max-width: 1379px) {
  .tech-hero-section-2 .shape-1 {
    width: 450px;
    height: 250px;
  }

  .tech-hero-section-2 .shape-2 {
    width: 450px;
    height: 250px;
    top: 46%;
  }

  .tech-hero-section-2 .shape-3 {
    width: 450px;
    height: 250px;
  }

  .tech-hero-section-2 .shape-4 {
    width: 450px;
    height: 250px;
    top: 81%;
  }

  .tech-hero-section-2 .shape-5 {
    width: 250px;
    height: 180px;
    top: 71%;
    right: -5%;
  }

  .tech-hero-section-2 .hero-content .title {
    font-size: 48px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section-2 {
    height: 760px;
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section-2 {
    height: 660px;
  }

  .tech-hero-section-2 .shape-1 {
    width: 350px;
    height: 190px;
  }

  .tech-hero-section-2 .shape-2 {
    width: 350px;
    height: 190px;
    top: 46%;
  }

  .tech-hero-section-2 .shape-3 {
    width: 350px;
    height: 190px;
    top: 37%;
  }

  .tech-hero-section-2 .shape-4 {
    width: 350px;
    height: 190px;
  }

  .tech-hero-section-2 .shape-5 {
    width: 220px;
    height: 150px;
    top: 71%;
    right: -5%;
  }

  .tech-hero-section-2 .hero-content .sub-title {
    font-size: 14px;
    line-height: 28px;
  }

  .tech-hero-section-2 .hero-content .title {
    font-size: 40px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section-2 {
    height: 660px;
  }

  .tech-hero-section-2 .shape-1 {
    width: 350px;
    height: 190px;
  }

  .tech-hero-section-2 .shape-2 {
    width: 350px;
    height: 190px;
    top: 46%;
  }

  .tech-hero-section-2 .shape-3 {
    width: 350px;
    height: 190px;
    top: 37%;
  }

  .tech-hero-section-2 .shape-4 {
    display: none;
  }

  .tech-hero-section-2 .shape-5 {
    display: none;
  }

  .tech-hero-section-2 .hero-content .title {
    font-size: 32px;
    line-height: 46px;
  }
}

.tech-hero-section-3 {
  padding-top: 240px;
  padding-bottom: 135px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.tech-hero-section-3 .hero-content {
  max-width: 580px;
}

.tech-hero-section-3 .hero-content .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}

.tech-hero-section-3 .hero-content .title {
  font-size: 72px;
  line-height: 92px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}

.tech-hero-section-3 .hero-content .btn {
  margin-top: 55px;
}

.tech-hero-section-3 .hero-content .hero-play-btn {
  margin-top: 80px;
}

.tech-hero-section-3 .hero-content .hero-play-btn .play-btn i {
  width: 70px;
  height: 70px;
  background: transparent;
  display: inline-block;
  line-height: 70px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}

.tech-hero-section-3 .hero-content .hero-play-btn .play-btn i:hover {
  color: #ff5538;
}

.tech-hero-section-3 .hero-content .hero-play-btn .play-btn span {
  font-size: 22px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-left: 10px;
}

.tech-hero-section-3 .hero-form {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  max-width: 460px;
}

.tech-hero-section-3 .hero-form .hero-form-wrap {
  padding: 45px;
  box-shadow: 0 0 40px 0 rgba(49, 48, 48, 0.81);
}

.tech-hero-section-3 .hero-form .hero-form-wrap .heading-wrap {
  margin-bottom: 5px;
}

.tech-hero-section-3 .hero-form .hero-form-wrap .heading-wrap .title {
  font-size: 30px;
  line-height: 36px;
  color: #3c424c;
}

.tech-hero-section-3 .hero-form .hero-form-wrap .single-form {
  margin-top: 20px;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ) {
  background: #f0f0f0;
  height: 55px;
  line-height: 55px;
  border: 0;
  padding-left: 15px;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3 .hero-form .hero-form-wrap .single-form textarea {
  height: 90px;
  background: #f0f0f0;
  border: 0;
  padding-left: 15px;
  resize: none;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3
  .hero-form
  .hero-form-wrap
  .single-form
  textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #686f7a;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.tech-hero-section-3 .hero-form .hero-form-wrap .form-btn {
  margin-top: 20px;
}

.tech-hero-section-3 .hero-form .hero-form-wrap .form-btn .btn {
  font-size: 16px;
  width: 100%;
  height: 50px;
  line-height: 46px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1379px) {
  .tech-hero-section-3 {
    padding-top: 210px;
    padding-bottom: 110px;
  }

  .tech-hero-section-3 .hero-content .title {
    font-size: 55px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section-3 {
    padding-top: 190px;
  }

  .tech-hero-section-3 .hero-content .title {
    font-size: 48px;
    line-height: 60px;
  }

  .tech-hero-section-3 .hero-form {
    max-width: 100%;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section-3 {
    padding-top: 170px;
    padding-bottom: 80px;
  }

  .tech-hero-section-3 .hero-content .sub-title {
    font-size: 15px;
    line-height: 28px;
  }

  .tech-hero-section-3 .hero-content .hero-play-btn .play-btn i {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .tech-hero-section-3 .hero-content .hero-play-btn .play-btn span {
    font-size: 18px;
  }

  .tech-hero-section-3 .hero-form .hero-form-wrap .heading-wrap .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section-3 .hero-content .title {
    font-size: 35px;
    line-height: 48px;
  }

  .tech-hero-section-3 .hero-form .hero-form-wrap .heading-wrap .title {
    font-size: 22px;
  }

  .tech-hero-section-3 .hero-form .hero-form-wrap .form-btn .btn {
    padding: 0 45px;
  }
}

.tech-hero-section-4 {
  padding-top: 85px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.tech-hero-section-4 .hero-content {
  padding-top: 155px;
  max-width: 550px;
}

.tech-hero-section-4 .hero-content .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 40px;
  line-height: 38px;
  background: #252239;
  padding: 0 25px;
  display: inline-block;
  border-radius: 35px;
  margin-bottom: 10px;
}

.tech-hero-section-4 .hero-content .title {
  font-size: 72px;
  line-height: 92px;
  color: #fff;
}

.tech-hero-section-4 .hero-content p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
}

.tech-hero-section-4 .hero-content .btn {
  margin-top: 50px;
}

.tech-hero-section-4 .hero-images {
  padding-top: 50px;
  text-align: center;
  position: relative;
}

.tech-hero-section-4 .hero-images .shape-1 {
  position: absolute;
  top: 75px;
  left: -15px;
  animation: rotate-style 50s linear infinite;
}

.tech-hero-section-4 .hero-images .images {
  position: relative;
  bottom: -30px;
}

@media only screen and (max-width: 1379px) {
  .tech-hero-section-4 .hero-content .title {
    font-size: 55px;
    line-height: 72px;
  }

  .tech-hero-section-4 .hero-images .images {
    padding: 0 35px;
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section-4 .hero-content {
    padding-top: 110px;
  }

  .tech-hero-section-4 .hero-content .sub-title {
    font-size: 12px;
    padding: 0 20px;
  }

  .tech-hero-section-4 .hero-content .title {
    font-size: 45px;
    line-height: 60px;
  }

  .tech-hero-section-4 .hero-images .images {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section-4 .hero-content {
    padding-top: 80px;
  }

  .tech-hero-section-4 .hero-images .shape-1 {
    left: 0;
  }

  .tech-hero-section-4 .hero-images .images {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section-4 .hero-content .title {
    font-size: 35px;
    line-height: 48px;
  }
}

.tech-hero-section-5 {
  height: 960px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tech-hero-section-5 .shape-1 {
  position: absolute;
  left: 0;
  top: 15px;
  z-index: -1;
}

.tech-hero-section-5 .shape-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.tech-hero-section-5 .hero-content {
  max-width: 880px;
  margin: 0 auto;
  padding-top: 60px;
}

.tech-hero-section-5 .hero-content .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 30px;
}

.tech-hero-section-5 .hero-content .title {
  font-size: 72px;
  line-height: 92px;
  color: #fff;
}

.tech-hero-section-5 .hero-content p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
}

.tech-hero-section-5 .hero-content .btn {
  margin-top: 45px;
}

@media only screen and (max-width: 1379px) {
  .tech-hero-section-5 {
    height: 860px;
  }

  .tech-hero-section-5 .shape-1 img {
    width: 80%;
  }

  .tech-hero-section-5 .shape-2 {
    right: -140px;
  }

  .tech-hero-section-5 .shape-2 img {
    width: 80%;
  }

  .tech-hero-section-5 .hero-content .title {
    font-size: 55px;
    line-height: 72px;
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section-5 {
    height: 760px;
  }

  .tech-hero-section-5 .hero-content .title {
    font-size: 45px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section-5 {
    height: 660px;
  }

  .tech-hero-section-5 .shape-1 img {
    width: 60%;
  }

  .tech-hero-section-5 .shape-2 {
    right: -280px;
  }

  .tech-hero-section-5 .shape-2 img {
    width: 60%;
  }

  .tech-hero-section-5 .hero-content .title {
    font-size: 40px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section-5 .hero-content .sub-title {
    font-size: 16px;
  }

  .tech-hero-section-5 .hero-content .title {
    font-size: 35px;
    line-height: 48px;
  }
}

.tech-hero-section-6 {
  /* margin-top: 85px; */
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  /* background-color: #000 */
  background-image: url("/assets/images/homebanner/master-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1379px) {
  /* .tech-hero-section-6 {
    margin-top: 66px;
  } */
}

.tech-hero-section-6 .hero-content {
  max-width: 620px;
  padding: 70px 0;
}

.tech-hero-section-6 .hero-content .sub-title {
  font-size: 22px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #00aeef;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 10px;
}

.tech-hero-section-6 .hero-content .title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  color: #fff;
}

.tech-hero-section-6 .hero-content p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
  padding-right: 35px;
}

.tech-hero-section-6 .hero-content .btn {
  margin-top: 15px;
}

.tech-hero-section-6 .hero-images {
  padding-top: 125px;
  text-align: right;
  position: relative;
  z-index: 1;
}

.tech-hero-section-6 .hero-images.sk {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tech-hero-section-6 .swiper-slide {
  overflow: hidden;
}

.tech-hero-section-6 .swiper-slide.slide1 .long-bg {
  position: relative;
}

.tech-hero-section-6 .swiper-slide.slide1 .long-bg:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 100%;
  bottom: 0;
  left: -50%;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: 0;
  background-size: cover;
}

.tech-hero-section-6 .swiper-slide.slide1 .play-btn {
  right: -55px;
  display: inline-block;
  position: relative;
}

.tech-hero-section-6 .swiper-slide.slide1 .play-btn a {
  width: 105px;
  height: 105px;
  line-height: 105px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #00aeef;
  color: #fff;
  transition: all 0.3s linear;
}

.tech-hero-section-6 .swiper-slide.slide1 .play-btn a::before {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 215px;
  height: 215px;
  border-radius: 50%;
  animation: zoombig 3.25s linear infinite;
  animation-delay: 0.8s;
}

.tech-hero-section-6 .swiper-slide.slide1 .play-btn a::after {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 215px;
  height: 215px;
  border-radius: 50%;
  animation: zoombig 3.25s linear infinite;
  animation-delay: 0;
}

.tech-hero-section-6 .swiper-slide.slide1 .play-btn a:hover {
  background: #fff;
  color: #00aeef;
  box-shadow: 0 0 98px 0 rgba(0, 0, 0, 0.07);
}

.tech-hero-section-6 .swiper-slide.video {
  display: contents;
}

.tech-hero-section-6 .swiper-slide.video video {
  width: 100%;
}

@media only screen and (max-width: 1379px) {
  .tech-hero-section-6 .hero-content {
    padding-top: 60px;
  }

  .tech-hero-section-6 .hero-images::before {
    width: 480px;
    height: 480px;
    transform: translate(-30px, 80px);
  }
}

@media only screen and (max-width: 991px) {
  .tech-hero-section-6 .hero-content {
    padding-top: 70px;
  }

  .tech-hero-section-6 .hero-content .sub-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .tech-hero-section-6 .hero-content .title {
    font-size: 30px;
    line-height: 45px;
  }

  .tech-hero-section-6 .hero-content p {
    font-size: 14px;
    padding-right: 0;
  }

  .tech-hero-section-6 .hero-images::before {
    width: 380px;
    height: 380px;
    transform: translate(-25px, 80px);
  }
}

@media only screen and (max-width: 767px) {
  .tech-hero-section-6 .hero-content p {
    font-size: 16px;
  }

  .tech-hero-section-6 .hero-images::before {
    width: 430px;
    height: 430px;
    transform: translate(5px, 80px);
  }

  .tech-hero-section-6 .hero-images .images {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 790px) {
  .tech-hero-section-6 .hero-images  {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .tech-hero-section-6 .hero-content .title {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 449px) {
  /* .tech-hero-section-6 .hero-images {
    padding-top: 90px;
  } */

  .tech-hero-section-6 .hero-images::before {
    width: 350px;
    height: 350px;
    transform: translate(-10px, 60px);
  }

  .tech-hero-section-6 .hero-images .images {
    padding: 0 15px;
  }
}

@keyframes animate-02 {
  0%,
  100% {
    border-radius: 50% 50% 50% 50% / 50% 35% 65% 50%;
  }

  34% {
    border-radius: 50% 50% 35% 65% / 50% 50% 50% 50%;
  }

  50% {
    border-radius: 50% 50% 50% 50% / 65% 50% 50% 35%;
  }

  67% {
    border-radius: 35% 65% 50% 50% / 50% 50% 50% 50%;
  }
}

@keyframes animate-03 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1.2);
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.36);
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border: 2px solid #fff;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

.service-section .service-wrap {
  margin-top: -130px;
  margin-bottom: -30px;
  position: relative;
  z-index: 5;
}

.service-section .service-wrap .service-item {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.09);
  padding: 50px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1399px) {
  .service-section .service-wrap .service-item {
    padding: 50px 25px;
  }
}

@media only screen and (max-width: 1379px) {
  .service-section .service-wrap .service-item {
    padding: 40px 20px;
  }
}

.service-section .service-wrap .service-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(148deg, #54d6eb 0%, #055fdd 100%);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;
  visibility: hidden;
}

.service-section .service-wrap .service-item .shape-1 {
  width: 235px;
  height: 235px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -80px;
  right: -80px;
  opacity: 0;
  transition: all 0.3s linear;
  visibility: hidden;
  z-index: -1;
}

.service-section .service-wrap .service-item .shape-2 {
  position: absolute;
  right: -135px;
  bottom: 55px;
  opacity: 0;
  transition: all 0.3s linear;
  visibility: hidden;
  z-index: -1;
}

.service-section .service-wrap .service-item .service-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 12px;
  transition: all 0.3s linear;
}

.service-section .service-wrap .service-item .service-content .title a {
  color: #231e32;
}

@media only screen and (max-width: 1379px) {
  .service-section .service-wrap .service-item .service-content .title {
    font-size: 20px;
  }
}

.service-section .service-wrap .service-item .service-content p {
  font-size: 14px;
  line-height: 26px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #4c4d56;
  margin-top: 20px;
  transition: all 0.3s linear;
}

.service-section .service-wrap .service-item:hover::before {
  opacity: 1;
  visibility: visible;
}

.service-section .service-wrap .service-item:hover .shape-1 {
  opacity: 1;
  visibility: visible;
}

.service-section .service-wrap .service-item:hover .shape-2 {
  opacity: 1;
  visibility: visible;
}

.service-section .service-wrap .service-item:hover .service-content .title a {
  color: #fff;
}

.service-section .service-wrap .service-item:hover .service-content p {
  color: #fff;
}

.service-section-2 {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1379px) {
  .service-section-2 {
    padding-bottom: 35px;
  }
}

.service-section-2 .service-wrap-2 .service-content-wrap-2 {
  padding-top: 60px;
  transform: translate(0);
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-img
  a {
  display: block;
  position: relative;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-img
  a
  img {
  width: 100%;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 30px 40px 40px 35px;
}

@media only screen and (max-width: 1399px) {
  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-item
    .service-content {
    padding: 30px 25px 35px;
  }
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-content
  .title {
  font-size: 24px;
  line-height: 30px;
  color: #231e32;
}

@media only screen and (max-width: 1399px) {
  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-item
    .service-content
    .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1379px) {
  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-item
    .service-content
    .title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-item
    .service-content
    .title {
    font-size: 20px;
  }
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-content
  .read-more {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  background: #00aeef;
  color: #fff;
  display: inline-block;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item:hover
  .service-img
  .shape-1 {
  opacity: 0.8;
  visibility: visible;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item:hover
  .service-img
  .shape-2 {
  opacity: 0.8;
  visibility: visible;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item:hover
  .service-img
  .service-img-text {
  opacity: 1;
  visibility: visible;
  transform: translateY(-45px);
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-2-active
  .swiper-button-prev {
  position: fixed;
  left: -50px;
  right: auto;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-2-active
  .swiper-button-next {
  position: fixed;
  right: -50px;
  left: auto;
}

.service-2-active .swiper-pagination {
  position: relative;
  margin-top: 60px;
}

.service-2-active .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-repeat: 50%;
  background: #e1e1e1;
}

.service-2-active
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00aeef;
}

.service-section-3 {
  background: #fbfaff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.service-section-3 .service-wrap-3 .service-slider-wrap {
  margin-top: 70px;
}

@media only screen and (max-width: 991px) {
  .service-section-3 .service-wrap-3 .service-slider-wrap {
    margin-top: 50px;
  }
}

.service-section-3 .service-wrap-3 .service-slider-wrap .service-item {
  background: #fff;
  padding: 55px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 1379px) {
  .service-section-3 .service-wrap-3 .service-slider-wrap .service-item {
    padding: 40px;
  }
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 30px;
}

@media only screen and (max-width: 1379px) {
  .service-section-3
    .service-wrap-3
    .service-slider-wrap
    .service-item
    .service-content
    .title {
    font-size: 20px;
  }
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .title
  a {
  color: #231e32;
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .title
  a:hover {
  color: #ff5538;
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .read-more {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #ff5538;
  margin-top: 15px;
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .read-more
  i {
  position: relative;
  top: 2px;
  transform: translateX(10px);
  transition: all 0.3s linear;
}

.service-section-3
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .read-more:hover
  i {
  transform: translateX(15px);
}

.service-3-active.slider-bullet .swiper-pagination {
  margin-top: 50px;
}

.service-3-active.slider-bullet
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ff5538;
}

.service-section-4 .service-wrap-4 .service-left .title {
  font-size: 42px;
  line-height: 60px;
  font-weight: 800;
  color: #333;
  padding-right: 10px;
}

@media only screen and (max-width: 1399px) {
  .service-section-4 .service-wrap-4 .service-left .title {
    font-size: 40px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1379px) {
  .service-section-4 .service-wrap-4 .service-left .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .service-section-4 .service-wrap-4 .service-left .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.service-section-4 .service-wrap-4 .service-left .title span {
  color: #ff5538;
}

.service-section-4 .service-wrap-4 .service-left .service-list {
  margin-top: 50px;
}

.service-section-4 .service-wrap-4 .service-left .service-list li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.service-section-4 .service-wrap-4 .service-left .service-list li:last-child {
  margin-bottom: 0;
}

.service-section-4 .service-wrap-4 .service-left .service-list li a {
  font-size: 20px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 575px) {
  .service-section-4 .service-wrap-4 .service-left .service-list li a {
    font-size: 18px;
  }
}

.service-section-4 .service-wrap-4 .service-left .service-list li a:hover {
  color: #ff5538;
}

.service-section-4 .service-wrap-4 .service-left .service-list li a:hover i {
  transform: translateX(20px);
}

.service-section-4 .service-wrap-4 .service-left .service-list li a svg {
  transform: translateX(15px);
  transition: all 0.3s linear;
}

/* .service-section-4 .service-wrap-4 .service-right {
        padding-left: 40px
    } */

@media only screen and (max-width: 1379px) {
  .service-section-4 .service-wrap-4 .service-right {
    padding-top: 30px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1379px) {
  .service-section-4 .service-wrap-4 .service-right .service-item {
    margin-top: 30px;
  }
}

.service-section-4 .service-wrap-4 .service-right .service-item .service-img {
  border-radius: 10px;
  overflow: hidden;
}

.service-section-4 .service-wrap-4 .service-right .service-item .service-img a {
  display: block;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-img
  a:hover
  img {
  transform: scale(1.1);
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-img
  a
  img {
  width: 90%;
  transition: all 0.3s linear;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 30px;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .title
  a {
  color: #231e32;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .title
  a:hover {
  color: #ff5538;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .read-more {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #ff5538;
  margin-top: 20px;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .read-more:hover
  svg {
  transform: translateX(15px);
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .read-more
  svg {
  position: relative;
  top: 2px;
  transform: translateX(10px);
  transition: all 0.3s linear;
}

.service-section-5 .service-wrap {
  margin-top: 0;
  margin-bottom: -190px;
}

.service-section-5 .service-wrap .about-service-item {
  padding: 50px;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 70px;
}

@media only screen and (max-width: 1379px) {
  .service-section-5 .service-wrap .about-service-item {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .service-section-5 .service-wrap .about-service-item {
    padding: 40px 22px;
  }
}

.service-section-5 .service-wrap .about-service-item .service-icon {
  height: 135px;
  width: 135px;
  background: #e9f1fc;
  line-height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 25px;
}

.service-section-6 {
  background: #fff;
}

.service-section-6 .service-wrap-3 .service-slider-wrap {
  margin-top: -140px;
  position: relative;
  z-index: 1;
}

.service-section-6 .service-wrap-3 .service-slider-wrap .service-item {
  background: #fff;
  padding: 40px 50px 35px 150px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  background-repeat: no-repeat;
  background-position: center left;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1399px) {
  .service-section-6 .service-wrap-3 .service-slider-wrap .service-item {
    padding: 40px 30px 35px 120px;
  }
}

@media only screen and (max-width: 1379px) {
  .service-section-6 .service-wrap-3 .service-slider-wrap .service-item {
    padding: 40px 30px 35px 65px;
  }
}

@media only screen and (max-width: 767px) {
  .service-section-6 .service-wrap-3 .service-slider-wrap .service-item {
    padding: 40px 23px 35px;
  }
}

@media only screen and (max-width: 575px) {
  .service-section-6 .service-wrap-3 .service-slider-wrap .service-item {
    padding: 40px 30px 35px 70px;
  }
}

.service-section-6
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .title {
  margin-top: 0;
}

.service-section-6
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .title
  a:hover {
  color: #00aeef;
}

.service-section-6
  .service-wrap-3
  .service-slider-wrap
  .service-item
  .service-content
  .read-more {
  color: #00aeef;
}

.service-6-active.slider-bullet .swiper-pagination {
  margin-top: 50px;
}

.service-6-active.slider-bullet
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00aeef;
}

.about-section {
  overflow: hidden;
}

.about-section .about-wrap .about-thumb-wrap {
  position: relative;
  padding-right: 40px;
}

.about-section .about-wrap .about-thumb-wrap .about-thumb-small {
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
}

.about-section .about-wrap .about-thumb-wrap .about-thumb-small img {
  border-radius: 5px;
}

.about-section .about-wrap .about-thumb-wrap .about-thumb-big {
  text-align: right;
  z-index: 1;
  transform: translateY(135px) translateX(35px);
}

.about-section .about-wrap .about-thumb-wrap .about-thumb-big img {
  border-radius: 5px;
}

.about-section .about-wrap .about-thumb-wrap .about-thumb-shape {
  position: absolute;
  top: 235px;
  left: 65px;
  z-index: -1;
}

.about-section .about-wrap .about-thumb-wrap .play-btn {
  position: absolute;
  right: -55px;
  bottom: 8px;
}

.about-section .about-wrap .about-thumb-wrap .play-btn a {
  width: 105px;
  height: 105px;
  line-height: 105px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #00aeef;
  color: #fff;
  transition: all 0.3s linear;
}

.about-section .about-wrap .about-thumb-wrap .play-btn a::before {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 215px;
  height: 215px;
  border-radius: 50%;
  animation: zoombig 3.25s linear infinite;
  animation-delay: 0.8s;
}

.about-section .about-wrap .about-thumb-wrap .play-btn a::after {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 215px;
  height: 215px;
  border-radius: 50%;
  animation: zoombig 3.25s linear infinite;
  animation-delay: 0;
}

.about-section .about-wrap .about-thumb-wrap .play-btn a:hover {
  background: #fff;
  color: #00aeef;
  box-shadow: 0 0 98px 0 rgba(0, 0, 0, 0.07);
}

.about-section .about-wrap .about-content {
  padding-left: 120px;
}

@media only screen and (max-width: 1399px) {
  .about-section .about-wrap .about-content {
    padding-left: 80px;
  }
}

.about-section .about-wrap .about-content p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 25px;
}

.about-section .about-wrap .about-content .about-author-list {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.about-section .about-wrap .about-content .about-author-list .about-author {
  background: #fff;
  box-shadow: 0 0 62px 0 rgba(0, 0, 0, 0.08);
  padding: 35px 30px 35px 45px;
  border-radius: 5px;
  margin-right: 40px;
  margin-top: 25px;
  position: relative;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-author::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: #00aeef;
  height: 3px;
  width: calc(100% - 30px);
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-author
  .name {
  font-size: 16px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #090d2f;
  margin-top: 10px;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-author
  .designation {
  font-size: 12px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #4c4d56;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-author
  .author-img {
  position: absolute;
  left: -25px;
  top: 35px;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-author
  .author-img
  img {
  border-radius: 50%;
}

.about-section .about-wrap .about-content .about-author-list .about-list {
  margin-top: 25px;
}

.about-section .about-wrap .about-content .about-author-list .about-list ul li {
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #090d2f;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-list
  ul
  li
  + li {
  margin-top: 15px;
}

.about-section
  .about-wrap
  .about-content
  .about-author-list
  .about-list
  ul
  li
  i {
  color: #00aeef;
  margin-right: 5px;
}

@media only screen and (max-width: 1379px) {
  .about-section .about-wrap .about-content {
    padding-left: 64px;
  }

  .about-section .about-wrap .about-content .about-author-list {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 991px) {
  .about-section .about-wrap .about-thumb-wrap .play-btn {
    right: -40px;
  }

  .about-section .about-wrap .about-thumb-wrap .play-btn a {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }

  .about-section .about-wrap .about-thumb-wrap .play-btn a::before {
    width: 180px;
    height: 180px;
  }

  .about-section .about-wrap .about-thumb-wrap .play-btn a::after {
    width: 180px;
    height: 180px;
  }

  .about-section .about-wrap .about-content {
    padding-left: 0;
    margin-top: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .about-section {
    padding-bottom: 0;
  }

  .about-section .about-wrap .about-thumb-wrap {
    padding-right: 0;
  }

  .about-section .about-wrap .about-thumb-wrap .about-thumb-small {
    position: inherit;
    text-align: center;
  }

  .about-section .about-wrap .about-thumb-wrap .about-thumb-big {
    transform: translateY(30px) translateX(0px);
    text-align: center;
  }

  .about-section .about-wrap .about-thumb-wrap .about-thumb-shape {
    display: none;
  }

  .about-section .about-wrap .about-thumb-wrap .play-btn {
    bottom: -65px;
    right: 130px;
  }

  .about-section .about-wrap .about-content {
    padding-left: 0;
    margin-top: 130px;
  }

  .about-section .about-wrap .about-content .about-author-list {
    display: block;
  }

  .about-section .about-wrap .about-content .about-author-list .about-author {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }

  40% {
    opacity: 0.5;
    border-width: 3px;
  }

  65% {
    border-width: 2px;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

.about-section-2 {
  background: #fafbff;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}

.about-section-2 .about-wrap-2 .about-img {
  text-align: center;
  position: relative;
  margin-top: 35px;
}

.about-section-2 .about-wrap-2 .about-img img {
  border-radius: 10px;
}

.about-section-2 .about-wrap-2 .about-img .about-img-text {
  text-align: left;
  background: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
  position: absolute;
  left: 0;
  top: -35px;
  padding: 90px 60px 65px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.about-section-2 .about-wrap-2 .about-img .about-img-text .number {
  font-size: 120px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #fff;
  display: block;
}

.about-section-2 .about-wrap-2 .about-img .about-img-text .text {
  font-size: 24px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
  display: block;
  margin-top: 55px;
}

.about-section-2 .about-wrap-2 .about-content {
  padding-left: 65px;
  padding-right: 20px;
}

.about-section-2 .about-wrap-2 .about-content .section-title2 .title {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
}

.about-section-2 .about-wrap-2 .about-content .about-list {
  padding-top: 40px;
}

.about-section-2 .about-wrap-2 .about-content .about-list ul li {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

.about-section-2 .about-wrap-2 .about-content .about-list ul li .about-icon {
  color: #5149f3;
  margin-right: 8px;
}

.about-section-2 .about-wrap-2 .about-content .about-list ul li + li {
  margin-top: 15px;
}

.about-section-2 .about-wrap-2 .about-content .about-btn {
  margin-top: 60px;
}

@media only screen and (max-width: 1379px) {
  .about-section-2 .about-wrap-2 .about-img .about-img-text {
    padding: 75px 55px 55px;
  }

  .about-section-2 .about-wrap-2 .about-img .about-img-text .number {
    font-size: 95px;
  }

  .about-section-2 .about-wrap-2 .about-img .about-img-text .text {
    font-size: 22px;
    margin-top: 40px;
  }

  .about-section-2 .about-wrap-2 .about-content {
    padding-left: 20px;
    padding-right: 0;
    margin-top: 60px;
  }

  .about-section-2 .about-wrap-2 .about-content .section-title2 .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 991px) {
  .about-section-2 .about-wrap-2 .about-content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-2 .about-wrap-2 .about-content .section-title2 .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.about-section-3 .about-wrap .about-content-wrap {
  margin-top: 70px;
}

.about-section-3 .about-wrap .about-content-wrap .about-thumb-wrap {
  position: relative;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-middle {
  position: relative;
  top: 75px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-middle
  img {
  border-radius: 10px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-bottom {
  position: absolute;
  right: 95px;
  bottom: -45px;
  z-index: 1;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-bottom
  img {
  border-radius: 10px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-small {
  position: absolute;
  top: 0;
  right: 0;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-small
  img {
  border-radius: 10px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-shape {
  text-align: right;
  position: relative;
  top: -65px;
  right: 5px;
  z-index: -1;
}

.about-section-3 .about-wrap .about-content-wrap .about-content {
  padding-left: 115px;
}

.about-section-3 .about-wrap .about-content-wrap .about-content .experience {
  display: flex;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-content
  .experience
  .number {
  font-size: 180px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #ff5538;
  margin-right: 10px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .about-content
  .experience
  span {
  font-size: 18px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #333;
  margin-top: 5px;
}

.about-section-3 .about-wrap .about-content-wrap .about-content .title {
  font-size: 24px;
  line-height: 36px;
  color: #333;
  margin-top: 40px;
}

.about-section-3 .about-wrap .about-content-wrap .about-content p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 35px;
}

.about-section-3
  .about-wrap
  .about-content-wrap
  .skill-bar-wrap
  .skill-item
  .bar-inner
  .progress-line {
  background: #ff5538;
}

@media only screen and (max-width: 1379px) {
  .about-section-3 .about-wrap .about-content-wrap .about-content {
    padding-left: 50px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-content
    .experience
    .number {
    font-size: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .about-section-3 .about-wrap .about-content-wrap .about-thumb-wrap {
    position: relative;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-bottom {
    right: 140px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-small {
    right: 62px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-shape {
    right: 45px;
  }

  .about-section-3 .about-wrap .about-content-wrap .about-content {
    padding-left: 0;
    margin-top: 140px;
  }
}

@media only screen and (max-width: 575px) {
  .about-section-3 .about-wrap .about-content-wrap {
    margin-top: 50px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-middle {
    text-align: center;
    top: 0;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-middle
    img {
    width: 100%;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-bottom {
    display: none;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-small {
    display: none;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-thumb-wrap
    .about-thumb-shape {
    display: none;
  }

  .about-section-3 .about-wrap .about-content-wrap .about-content {
    margin-top: 90px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-content
    .experience
    .number {
    font-size: 120px;
  }

  .about-section-3
    .about-wrap
    .about-content-wrap
    .about-content
    .experience
    span {
    font-size: 16px;
    margin-top: 0;
  }
}

.about-section-4 {
  padding-bottom: 270px;
}

.about-section-4 .about-wrap .section-title {
  max-width: 760px;
}

.about-section-4 .about-wrap .about-content-wrap {
  margin-top: 65px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-middle {
  top: 0;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-bottom {
  bottom: -170px;
  right: 0;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-small {
  top: 35px;
  right: 14px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-thumb-wrap
  .about-thumb-shape {
  top: -170px;
  right: 60px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-list {
  margin-top: 25px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-list
  ul
  li {
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  display: flex;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-list
  ul
  li
  + li {
  margin-top: 15px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-list
  ul
  li
  i {
  color: #00aeef;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-author {
  border-top: 1px solid #ebebeb;
  display: flex;
  margin-top: 45px;
  padding-top: 30px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-author
  .author-text {
  margin-right: 30px;
}

.about-section-4
  .about-wrap
  .about-content-wrap
  .about-content
  .about-author-list
  .about-author
  .author-text
  .name {
  color: #333;
}

@media only screen and (max-width: 1379px) {
  .about-section-4 {
    padding-bottom: 205px;
  }

  .about-section-4 .about-wrap .about-content-wrap {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .about-section-4 {
    padding-bottom: 80px;
  }

  .about-section-4 .about-wrap .about-content-wrap {
    margin-top: 50px;
  }

  .about-section-4 .about-wrap .about-content-wrap .about-content {
    margin-top: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-4 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .about-section-4 .about-wrap .about-content-wrap .about-content {
    margin-top: 0;
  }
}

.about-section-5 {
  padding-bottom: 200px;
  background-color: #00aeef;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.about-section-5 .about-wrap .about-left {
  padding-right: 105px;
}

.about-section-5 .about-wrap .about-left .section-title .sub-title {
  color: #fff;
}

.about-section-5 .about-wrap .about-left .section-title .title {
  color: #fff;
}

.about-section-5 .about-wrap .about-right {
  padding-right: 140px;
}

.about-section-5 .about-wrap .about-right p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
}

.about-section-5 .about-wrap .about-right .about-author {
  display: flex;
  margin-top: 50px;
}

.about-section-5 .about-wrap .about-right .about-author .author-text {
  margin-left: 40px;
}

.about-section-5 .about-wrap .about-right .about-author .author-text .name {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.about-section-5
  .about-wrap
  .about-right
  .about-author
  .author-text
  .designation {
  font-size: 12px;
  line-height: 18px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1379px) {
  .about-section-5 .about-wrap .about-left {
    padding-right: 0;
  }

  .about-section-5 .about-wrap .about-right {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .about-section-5 .about-wrap .about-right {
    margin-top: 35px;
  }
}

.about-section-6 {
  padding-bottom: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.about-section-6 .about-wrap .about-left {
  max-width: 460px;
}

.about-section-6 .about-wrap .about-right {
  margin-top: 35px;
}

.about-section-6 .about-wrap .about-right p {
  font-size: 16px;
  line-height: 30px;
}

@media only screen and (max-width: 991px) {
  .about-section-6 .about-wrap .about-right {
    margin-top: 65px;
  }
}

.about-section-6 .about-wrap .about-right .about-experience-award .single-item {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 40px;
}

.about-section-6
  .about-wrap
  .about-right
  .about-experience-award
  .single-item:last-child {
  margin-top: 65px;
}

.about-section-6
  .about-wrap
  .about-right
  .about-experience-award
  .single-item
  .number {
  font-size: 120px;
  line-height: 30px;
  font-weight: 800;
  color: #00aeef;
}

@media only screen and (max-width: 1379px) {
  .about-section-6
    .about-wrap
    .about-right
    .about-experience-award
    .single-item
    .number {
    font-size: 90px;
  }
}

.about-section-6
  .about-wrap
  .about-right
  .about-experience-award
  .single-item
  span {
  font-size: 24px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #333;
  margin-left: 25px;
}

@media only screen and (max-width: 1379px) {
  .about-section-6
    .about-wrap
    .about-right
    .about-experience-award
    .single-item
    span {
    font-size: 20px;
    line-height: 28px;
  }
}

.features-section {
  background: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  z-index: 5;
}

.features-section .features-thumb {
  position: absolute;
  left: 70px;
  width: 45%;
  height: 100%;
}

.features-section .features-thumb .shape-1 {
  position: absolute;
  left: 150px;
  top: 85px;
  padding: 0 20px;
  z-index: -1;
  animation: rotate-style 50s linear infinite;
}

.features-section .features-wrap {
  padding-top: 140px;
  padding-bottom: 145px;
}

.features-section .features-wrap .features-content-wrap {
  padding-left: 110px;
}

.features-section .features-wrap .features-content-wrap .section-title {
  margin-left: 0;
}

.features-section .features-wrap .features-content-wrap .features-content {
  padding-top: 20px;
}

.features-section .features-wrap .features-content-wrap .features-content ul {
  display: flex;
  flex-wrap: wrap;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  ul
  .features-item {
  display: flex;
  width: 50%;
  margin-top: 35px;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  ul
  .features-item
  .features-text {
  flex: 1;
  margin-left: 35px;
  max-width: 220px;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  ul
  .features-item
  .features-text
  .title {
  font-size: 22px;
  line-height: 30px;
  color: #231e32;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  ul
  .features-item
  .features-text
  p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  .features-link
  p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #4c4d56;
  margin-top: 40px;
}

.features-section
  .features-wrap
  .features-content-wrap
  .features-content
  .features-link
  p
  a {
  color: #00aeef;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 1399px) {
  .features-section .features-thumb {
    left: 25px;
  }
}

@media only screen and (max-width: 1399px) and (max-width: 1399px) {
  .features-section .features-thumb .shape-1 {
    top: 205px;
  }
}

@media only screen and (max-width: 1399px) {
  .features-section .features-wrap .features-content-wrap {
    padding-left: 90px;
  }

  .features-section
    .features-wrap
    .features-content-wrap
    .features-content
    ul
    .features-item
    .features-text {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1379px) {
  .features-section .features-thumb {
    position: relative;
    left: 0;
    width: 100%;
    justify-content: center;
  }

  .features-section .features-thumb .image {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .features-section .features-thumb .shape-1 {
    left: 275px;
    top: 150px;
  }

  .features-section .features-wrap {
    padding-top: 70px;
    padding-bottom: 100px;
  }

  .features-section .features-wrap .features-content-wrap {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .features-section .features-thumb .image {
    padding-top: 100px;
  }

  .features-section .features-thumb .shape-1 {
    left: 225px;
    top: 150px;
  }

  .features-section .features-thumb .shape-1 img {
    width: 520px;
  }

  .features-section .features-wrap {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .features-section .features-thumb .image {
    padding-top: 80px;
  }

  .features-section .features-thumb .shape-1 {
    left: 160px;
    top: 140px;
  }

  .features-section .features-thumb .shape-1 img {
    width: 390px;
  }

  .features-section .features-wrap {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .features-section .features-thumb .image {
    padding-left: 20px;
    padding-right: 20px;
  }

  .features-section .features-thumb .shape-1 {
    left: 110px;
    top: 115px;
    padding: 0 20px;
  }

  .features-section .features-wrap .features-content-wrap .features-content {
    padding-top: 20px;
  }

  .features-section
    .features-wrap
    .features-content-wrap
    .features-content
    ul
    .features-item {
    width: 100%;
  }

  .features-section
    .features-wrap
    .features-content-wrap
    .features-content
    ul
    .features-item
    .features-text
    .title {
    font-size: 20px;
    line-height: 30px;
    color: #231e32;
  }
}

@keyframes rotate-style {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.features-section-2 .features-wrap-2 {
  margin-top: -10px;
}

.features-section-2 .features-wrap-2 .features-item-wrap {
  border: 3px solid #e1e1e1;
  border-radius: 10px;
  padding: 70px 55px;
  position: relative;
  display: flex;
  margin-top: 60px;
}

.features-section-2 .features-wrap-2 .features-item-wrap::before {
  content: "";
  background: #e1e1e1;
  width: 80%;
  margin: auto;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}

.features-section-2 .features-wrap-2 .features-item-wrap .features-item-box {
  border-right: 1px solid #e1e1e1;
  padding-right: 60px;
  margin-right: 50px;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box.features-box-3 {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item {
  display: flex;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item.features-2,
.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item.features-4,
.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item.features-6 {
  margin-top: 60px;
  padding-top: 60px;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content {
  flex: 1;
  margin-left: 14px;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content
  .title {
  font-size: 22px;
  line-height: 30px;
  color: #231e32;
}

.features-section-2
  .features-wrap-2
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

@media only screen and (max-width: 1379px) {
  .features-section-2 .features-wrap-2 .features-item-wrap {
    padding: 60px 40px;
  }

  .features-section-2 .features-wrap-2 .features-item-wrap .features-item-box {
    padding-right: 20px;
    margin-right: 20px;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content {
    margin-left: 8px;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content
    .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .features-section-2 .features-wrap-2 .features-item-wrap {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .features-section-2 .features-wrap-2 .features-item-wrap::before {
    display: none;
  }

  .features-section-2 .features-wrap-2 .features-item-wrap .features-item-box {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item {
    display: flex;
    margin-top: 30px;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item.features-1 {
    margin-top: 0;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item.features-2,
  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item.features-4,
  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item.features-6 {
    margin-top: 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .features-section-2 .features-wrap-2 .features-item-wrap {
    padding: 40px;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item {
    display: block;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content {
    margin-top: 10px;
    margin-left: 0;
  }

  .features-section-2
    .features-wrap-2
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content
    .title {
    font-size: 20px;
  }
}

.features-section-3 .features-wrap-3 .features-content-wrap {
  padding-top: 20px;
}

.features-section-3 .features-wrap-3 .features-content-wrap .single-item {
  border: 1px solid #ebebeb;
  padding: 45px;
  padding-bottom: 65px;
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .features-section-3 .features-wrap-3 .features-content-wrap .single-item {
    padding: 35px;
  }
}

.features-section-3
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-icon
  img {
  height: 50px;
}

.features-section-3
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-content
  .title {
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 600;
  text-align: center;
}

.features-section-3
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-content
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .features-section-4 .features-wrap-3 .features-content-wrap .single-item {
    padding: 28px;
  }
}

.features-section-4
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-icon {
  position: relative;
}

.features-section-4
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-icon::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 15px;
  width: 50px;
  height: 50px;
  background: #e5eefc;
  border-radius: 50%;
  z-index: -1;
}

.features-section-4
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-content
  .title {
  margin-top: 45px;
}

@media only screen and (max-width: 767px) {
  .features-section-4
    .features-wrap-3
    .features-content-wrap
    .single-item
    .features-content
    .title {
    font-size: 20px;
  }
}

.features-section-5 .features-wrap-5 {
  margin-bottom: -30px;
  margin-top: -85px;
  position: relative;
  z-index: 1;
}

.features-section-5 .features-wrap-5 .features-item {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  padding: 40px 50px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1379px) {
  .features-section-5 .features-wrap-5 .features-item {
    padding: 35px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .features-section-5 .features-wrap-5 .features-item {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .features-section-5 .features-wrap-5 .features-item {
    text-align: center;
  }
}

.features-section-5 .features-wrap-5 .features-item .features-text {
  flex: 1;
  margin-left: 25px;
}

@media only screen and (max-width: 1379px) {
  .features-section-5 .features-wrap-5 .features-item .features-text {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .features-section-5 .features-wrap-5 .features-item .features-text {
    margin-left: 0;
    margin-top: 10px;
  }
}

.features-section-5 .features-wrap-5 .features-item .features-text .title {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .features-section-5 .features-wrap-5 .features-item .features-text .title {
    font-size: 20px;
  }
}

.features-section-6 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.features-section-6 .features-wrap-3 .section-title2 {
  margin: 0;
}

@media only screen and (max-width: 991px) {
  .features-section-6 .features-wrap-3 .section-title2 {
    text-align: center;
    margin: 0 auto;
  }
}

.features-section-6 .features-wrap-3 .features-6-btn {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .features-section-6 .features-wrap-3 .features-6-btn {
    text-align: center;
  }
}

.features-section-6 .features-wrap-3 .features-6-btn .btn {
  padding: 0 65px;
  margin-top: 20px;
}

.features-section-6 .features-wrap-3 .features-content-wrap {
  padding-top: 40px;
}

.features-section-6 .features-wrap-3 .features-content-wrap .single-item {
  display: flex;
  padding-bottom: 45px;
  background: #fff;
}

@media only screen and (max-width: 1399px) {
  .features-section-6 .features-wrap-3 .features-content-wrap .single-item {
    padding: 40px;
  }
}

@media only screen and (max-width: 1379px) {
  .features-section-6 .features-wrap-3 .features-content-wrap .single-item {
    flex-direction: column;
    gap: 20px;
  }
}

.features-section-6
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-icon::before {
  z-index: 0;
}

.features-section-6
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-icon
  img {
  position: relative;
  z-index: 1;
}

.features-section-6
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-content {
  flex: 1;
  margin-left: 30px;
}

@media only screen and (max-width: 1379px) {
  .features-section-6
    .features-wrap-3
    .features-content-wrap
    .single-item
    .features-content {
    margin-left: 0;
  }
}

.features-section-6
  .features-wrap-3
  .features-content-wrap
  .single-item
  .features-content
  .title {
  margin-top: 0;
}

@media only screen and (max-width: 1399px) {
  .features-section-6
    .features-wrap-3
    .features-content-wrap
    .single-item
    .features-content
    .title {
    font-size: 20px;
  }
}

.features-section-7 .features-wrap .features-img-wrap {
  display: flex;
}

.features-section-7 .features-wrap .features-img-wrap .features-img img {
  width: 100%;
}

.features-section-7 .features-wrap .features-content {
  padding-left: 112px;
}

.features-section-7
  .features-wrap
  .features-content
  .section-title
  .title
  span {
  color: #00aeef;
}

.features-section-7 .features-wrap .features-content p {
  font-size: 16px !important;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  /* margin-top: 30px; */
  padding-bottom: 20px;
  text-align: justify;
}

.features-section-7 .features-wrap .features-content .features-item-wrap {
  padding-top: 20px;
  display: flex;
}

.features-section-7 .features-wrap .features-content .features-item-wrap .col {
  position: relative;
  padding-right: 20px;
  margin-right: 5px;
}

.features-section-7
  .features-wrap
  .features-content
  .features-item-wrap
  .col:last-child {
  padding-right: 0;
  margin-right: 0;
}

.features-section-7
  .features-wrap
  .features-content
  .features-item-wrap
  .col:last-child::before {
  display: none;
}

.features-section-7
  .features-wrap
  .features-content
  .features-item-wrap
  .col::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 110px;
  width: 1px;
  background: #ededed;
  transform: translateY(40px);
}

.features-section-7
  .features-wrap
  .features-content
  .features-item-wrap
  .col
  .features-item {
  margin-top: 30px;
}

.features-section-7
  .features-wrap
  .features-content
  .features-item-wrap
  .col
  .features-item
  .title {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}

@media only screen and (max-width: 1399px) {
  .features-section-7
    .features-wrap
    .features-content
    .features-item-wrap
    .col
    .features-item
    .title {
    font-size: 17px;
  }
}

.feature-video-section .video-iframe-wrap {
  position: relative;
}

.feature-video-section .video-iframe-wrap:before {
  width: 100%;
  content: "";
  padding-top: 56.25%;
  display: block;
}

.feature-video-section .video-iframe-wrap iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1379px) {
  .features-section-7 .features-wrap .features-content {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .features-section-7 .features-wrap .features-content {
    padding-left: 0;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .features-section-7 .features-wrap .features-img-wrap {
    flex-direction: column;
    text-align: center;
  }

  .features-section-7
    .features-wrap
    .features-img-wrap
    .features-img:first-child {
    padding-right: 0;
  }

  .features-section-7
    .features-wrap
    .features-img-wrap
    .features-img:last-child {
    padding-left: 0;
  }

  .features-section-7 .features-wrap .features-content .features-item-wrap {
    flex-direction: column;
  }

  .features-section-7
    .features-wrap
    .features-content
    .features-item-wrap
    .col {
    padding-right: 0;
    margin-right: 0;
  }

  .features-section-7
    .features-wrap
    .features-content
    .features-item-wrap
    .col::before {
    display: none;
  }
}

.features-custom-1 .features-wrap-custom-1 {
  margin-top: -10px;
}

.features-custom-1 .features-wrap-custom-1 .features-item-wrap {
  border: 3px solid #e1e1e1;
  border-radius: 10px;
  padding: 55px 70px;
  position: relative;
  margin-top: 60px;
}

@media only screen and (max-width: 1379px) {
  .features-custom-1 .features-wrap-custom-1 .features-item-wrap {
    margin-top: 30px;
  }
}

.features-custom-1 .features-wrap-custom-1 .features-item-wrap > .row + .row {
  border-top: 1px solid #e1e1e1;
}

.features-custom-1 .features-wrap-custom-1 .features-item-wrap > .row > div {
  border-left: 1px solid #e1e1e1;
  padding: 40px;
}

.features-custom-1
  .features-wrap-custom-1
  .features-item-wrap
  > .row
  > div:first-child {
  border-left: none;
}

.features-custom-1
  .features-wrap-custom-1
  .features-item-wrap
  .features-item-box
  .features-item {
  display: flex;
}

.features-custom-1
  .features-wrap-custom-1
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content {
  flex: 1;
  margin-left: 14px;
}

.features-custom-1
  .features-wrap-custom-1
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content
  .title {
  font-size: 22px;
  line-height: 30px;
  color: #231e32;
}

.features-custom-1
  .features-wrap-custom-1
  .features-item-wrap
  .features-item-box
  .features-item
  .features-content
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

@media only screen and (max-width: 1379px) {
  .features-custom-1 .features-wrap-custom-1 .features-item-wrap {
    padding: 15px 30px;
  }

  .features-custom-1 .features-wrap-custom-1 .features-item-wrap > .row > div {
    padding: 20px;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content {
    margin-left: 8px;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content
    .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .features-custom-1 .features-wrap-custom-1 .features-item-wrap {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .features-custom-1 .features-wrap-custom-1 .features-item-wrap > .row + .row {
    border-top: none;
  }

  .features-custom-1 .features-wrap-custom-1 .features-item-wrap > .row > div {
    border-left: none;
    padding: 5px 20px;
  }

  .features-custom-1 .features-wrap-custom-1 .features-item-wrap::before {
    display: none;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item {
    display: flex;
    margin-top: 30px;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item.features-1 {
    margin-top: 0;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item.features-2,
  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item.features-4,
  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item.features-6 {
    margin-top: 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .features-custom-1 .features-wrap-custom-1 .features-item-wrap {
    padding-top: 0;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item {
    display: block;
    margin-top: 20px;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content {
    margin-top: 5px;
    margin-left: 0;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content
    .title {
    font-size: 20px;
  }

  .features-custom-1
    .features-wrap-custom-1
    .features-item-wrap
    .features-item-box
    .features-item
    .features-content
    p {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.game-list-wrap .game-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.game-list-wrap .game-list .list-item {
  padding: 10px;
  flex: 0 0 20%;
}

@media only screen and (max-width: 1379px) {
  .game-list-wrap .game-list .list-item {
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 991px) {
  .game-list-wrap .game-list .list-item {
    flex: 0 0 33.33%;
  }
}

@media only screen and (max-width: 575px) {
  .game-list-wrap .game-list .list-item {
    flex: 0 0 50%;
  }
}

.counter-section {
  padding-top: 100px;
  padding-bottom: 140px;
  background: #000c29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 1379px) {
  .counter-section {
    padding-top: 70px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .counter-section {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-section {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .counter-section .counter-wrap > .row > div[class^="col"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.counter-section .counter-wrap .counter-item {
  margin-top: 30px;
}

@media only screen and (max-width: 1379px) {
  .counter-section .counter-wrap .counter-item {
    margin-top: 0;
  }
}

.counter-section .counter-wrap .counter-item .counter-icon {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1379px) {
  .counter-section .counter-wrap .counter-item .counter-icon {
    margin-bottom: 15px;
  }
}

.counter-section .counter-wrap .counter-item .counter-text span {
  font-size: 50px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #fff;
}

@media only screen and (max-width: 1379px) {
  .counter-section .counter-wrap .counter-item .counter-text span {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-section .counter-wrap .counter-item .counter-text span {
    font-size: 30px;
  }
}

.counter-section .counter-wrap .counter-item .counter-text p {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
}

.counter-section-2 {
  background-color: #fff;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1379px) {
  .counter-section-2 {
    padding-bottom: 40px;
  }
}

.counter-section-2 .counter-wrap .counter-item {
  display: flex;
}

.counter-section-2 .counter-wrap .counter-item .counter-text {
  flex: 1;
  margin-left: 15px;
}

.counter-section-3 {
  padding-top: 130px;
  padding-bottom: 130px;
  background-image: url("../images/bg/counter-bg-3.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 1379px) {
  .counter-section-3 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.counter-section-3 .experience-content {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .counter-section-3 .experience-content {
    display: block;
  }
}

.counter-section-3 .experience-content .experience {
  margin-top: 20px;
}

.counter-section-3 .experience-content .experience .number {
  font-size: 150px;
  line-height: 30px;
  color: #00aeef;
}

@media only screen and (max-width: 1399px) {
  .counter-section-3 .experience-content .experience .number {
    font-size: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-section-3 .experience-content .experience .number {
    font-size: 90px;
  }
}

.counter-section-3 .experience-content .experience span {
  font-size: 24px;
  line-height: 38px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #333;
  display: block;
  margin-top: 60px;
}

@media only screen and (max-width: 1399px) {
  .counter-section-3 .experience-content .experience span {
    font-size: 20px;
    margin-top: 35px;
  }
}

.counter-section-3 .experience-content .experience-text {
  padding-left: 70px;
  padding-right: 20px;
}

@media only screen and (max-width: 1379px) {
  .counter-section-3 .experience-content .experience-text {
    padding-left: 18px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .counter-section-3 .experience-content .experience-text {
    padding-left: 0;
    margin-top: 20px;
  }
}

.counter-section-3 .experience-content .experience-text .title {
  font-size: 36px;
  line-height: 48px;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .counter-section-3 .experience-content .experience-text .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.counter-section-3 .experience-content .experience-text .title span {
  color: #00aeef;
}

@media only screen and (max-width: 991px) {
  .counter-section-3 .service-content {
    margin-top: 40px;
  }
}

.counter-section-3 .service-content .service-list li {
  border-bottom: 1px solid #00aeef;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.counter-section-3 .service-content .service-list li:last-child {
  margin-bottom: 0;
}

.counter-section-3 .service-content .service-list li a {
  font-size: 20px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 575px) {
  .counter-section-3 .service-content .service-list li a {
    font-size: 18px;
  }
}

.counter-section-3 .service-content .service-list li a:hover {
  color: #00aeef;
}

.counter-section-3 .service-content .service-list li a:hover svg {
  transform: translateX(20px);
}

.counter-section-3 .service-content .service-list li a svg {
  transform: translateX(15px);
  transition: all 0.3s linear;
}

.counter-section-3 .counter-wrap {
  padding-top: 50px;
}

.counter-section-3 .counter-wrap .counter-item {
  background: #fff;
  padding: 30px 15px;
  padding-bottom: 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 1379px) {
  .counter-section-3 .counter-wrap .counter-item {
    flex-direction: column;
    text-align: center;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 1379px) {
  .counter-section-3 .counter-wrap .counter-item .counter-text {
    margin-left: 0;
  }
}

.counter-section-3 .counter-wrap .counter-item .counter-text span {
  color: #333;
}

.counter-section-3 .counter-wrap .counter-item .counter-text p {
  color: #4c4d56;
}

.skill-section {
  position: relative;
  z-index: 1;
}

.skill-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* background: #f2f2f2 url(../images/bg/skill-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  width: 44%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .skill-section::before {
    display: none;
  }
}

.skill-section .skill-wrap .skill-content-wrap .skill-content-left {
  padding-top: 20px;
}

.skill-section
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .experience
  .number {
  font-size: 150px;
  line-height: 30px;
  color: #00aeef;
}

@media only screen and (max-width: 1379px) {
  .skill-section
    .skill-wrap
    .skill-content-wrap
    .skill-content-left
    .experience
    .number {
    font-size: 110px;
  }
}

@media only screen and (max-width: 575px) {
  .skill-section
    .skill-wrap
    .skill-content-wrap
    .skill-content-left
    .experience
    .number {
    font-size: 90px;
  }
}

.skill-section
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .experience
  span {
  font-size: 24px;
  line-height: 38px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #090d2f;
  display: block;
  margin-top: 60px;
}

@media only screen and (max-width: 1379px) {
  .skill-section
    .skill-wrap
    .skill-content-wrap
    .skill-content-left
    .experience
    span {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-section
    .skill-wrap
    .skill-content-wrap
    .skill-content-left
    .experience
    span {
    font-size: 20px;
  }
}

.skill-section .skill-wrap .skill-content-wrap .skill-content-left .title {
  font-size: 40px;
  line-height: 54px;
  color: #333;
  margin-top: 40px;
}

@media only screen and (max-width: 1379px) {
  .skill-section .skill-wrap .skill-content-wrap .skill-content-left .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-section .skill-wrap .skill-content-wrap .skill-content-left .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.skill-section .skill-wrap .skill-content-wrap .skill-content-left .title span {
  color: #00aeef;
}

.skill-section .skill-wrap .skill-img-wrap {
  padding-top: 70px;
}

.skill-section .skill-wrap .skill-img-wrap .skill-img-item {
  margin-top: 30px;
}

.skill-section .skill-wrap .skill-img-wrap .skill-img-item .skill-img {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear;
  z-index: 1;
}

.skill-section .skill-wrap .skill-img-wrap .skill-img-item .skill-img .image {
  display: block;
  position: relative;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #ffffff00 0%, #010f31 100%);
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 0;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .image
  img {
  border-radius: 5px;
  width: 100%;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text {
  position: absolute;
  bottom: 45px;
  left: 30px;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .title {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .skill-section
    .skill-wrap
    .skill-img-wrap
    .skill-img-item
    .skill-img
    .skill-img-text
    .title {
    font-size: 22px;
  }
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .title
  a:hover {
  color: #00aeef;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
  transition: all 0.3s linear;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn
  i {
  position: relative;
  top: 1px;
  margin-left: 4px;
  transition: all 0.3s linear;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn:hover {
  color: #fff;
}

.skill-section
  .skill-wrap
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn:hover
  i {
  color: #fff;
}

.skill-section .skill-wrap .skill-img-wrap .skill-img-item .skill-img:hover {
  transform: translateY(-5px) translate3d(0, -5px, 0);
}

.skill-content-right {
  padding-left: 150px;
}

@media only screen and (max-width: 1379px) {
  .skill-content-right {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .skill-content-right {
    padding-left: 0;
    padding-top: 50px;
  }
}

.skill-content-right .title {
  font-size: 30px;
  line-height: 48px;
}

@media only screen and (max-width: 1379px) {
  .skill-content-right .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-content-right .title {
    font-size: 26px;
  }
}

.skill-bar-wrap .skill-item {
  margin-top: 30px;
}

.skill-bar-wrap .skill-item .skill-header {
  position: relative;
}

.skill-bar-wrap .skill-item .skill-header .skill-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #120e1d;
}

.skill-bar-wrap .skill-item .skill-header .skill-percentage {
  position: absolute;
  top: 0;
  right: 0;
}

.skill-bar-wrap .skill-item .skill-header .skill-percentage span {
  font-size: 14px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #120e1d;
}

.skill-bar-wrap .skill-item .bar-inner {
  width: 100%;
  height: 10px;
  border: 1px solid #d7d7d7;
  margin-top: 5px;
  position: relative;
}

.skill-bar-wrap .skill-item .bar-inner .progress-line {
  height: 6px;
  position: absolute;
  width: 0;
  top: 1px;
  left: 3px;
  background: #00aeef;
  transition: 3s;
  transition-delay: 1s;
}

.skill-section-2 {
  padding-top: 350px;
  padding-bottom: 145px;
}

@media only screen and (max-width: 1379px) {
  .skill-section-2 {
    padding-top: 275px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .skill-section-2 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-section-2 {
    padding-top: 255px;
    padding-bottom: 60px;
  }
}

.skill-section-2::before {
  background: #f2f2f2 url(../images/bg/skill-bg-2.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%;
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .skill-img {
  position: relative;
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .skill-img
  img {
  width: 100%;
  border-radius: 10px;
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .skill-img
  .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .skill-img
  .play-btn
  a {
  width: 105px;
  height: 105px;
  line-height: 105px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #00aeef;
  color: #fff;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 767px) {
  .skill-section-3
    .skill-wrap
    .skill-content-wrap
    .skill-content-left
    .skill-img
    .play-btn
    a {
    width: 85px;
    height: 85px;
    line-height: 85px;
  }
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-left
  .skill-img
  .play-btn
  a:hover {
  background: #fff;
  color: #00aeef;
}

.skill-section-3 .skill-wrap .skill-content-wrap .skill-content-right {
  padding-left: 120px;
  padding-right: 90px;
}

@media only screen and (max-width: 1379px) {
  .skill-section-3 .skill-wrap .skill-content-wrap .skill-content-right {
    padding-left: 40px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .skill-section-3 .skill-wrap .skill-content-wrap .skill-content-right {
    padding-left: 0;
  }
}

.skill-section-3
  .skill-wrap
  .skill-content-wrap
  .skill-content-right
  .section-title2
  .title {
  font-size: 40px;
  line-height: 54px;
  font-weight: 700;
}

@media only screen and (max-width: 1379px) {
  .skill-section-3
    .skill-wrap
    .skill-content-wrap
    .skill-content-right
    .section-title2
    .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .skill-section-3
    .skill-wrap
    .skill-content-wrap
    .skill-content-right
    .section-title2
    .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.skill-section-4::before {
  background: #f2f2f2 url(../images/bg/skill-bg-2.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%;
}

.skill-section-5::before {
  display: none;
}

.skill-section-5 .skill-img-wrap {
  padding-top: 0;
  margin-top: -140px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1379px) {
  .skill-section-5 .skill-img-wrap {
    margin-top: -150px;
  }
}

.skill-section-5 .skill-img-wrap .skill-img-item {
  margin-top: 0;
  margin-bottom: 30px;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear;
  z-index: 1;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img .image {
  display: block;
  position: relative;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img .image::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 174, 239, 0) 0%, #000 100%);
  width: 100%;
  height: 36%;
  opacity: 0.85;
  z-index: 0;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img .image img {
  border-radius: 5px;
  width: 100%;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img .skill-img-text {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .skill-section-5
    .skill-img-wrap
    .skill-img-item
    .skill-img
    .skill-img-text
    .title {
    font-size: 22px;
  }
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .title
  a:hover {
  color: #00aeef;
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
  transition: all 0.3s linear;
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn
  i {
  position: relative;
  top: 1px;
  margin-left: 4px;
  transition: all 0.3s linear;
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn:hover {
  color: #fff;
}

.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .skill-btn:hover
  i {
  color: #fff;
}

.skill-section-5 .skill-img-wrap .skill-img-item .skill-img:hover {
  transform: translateY(-5px) translate3d(0, -5px, 0);
}

.testimonial-section {
  background-image: url(../images/bg/testi-bg.jpg) !important;
  background: #000c29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section .testimonial-wrap .section-title {
    text-align: center;
  }
}

.testimonial-section .testimonial-wrap .section-title .title {
  color: #fff;
}

.testimonial-section .testimonial-wrap .testimonial-slider-wrap {
  padding-left: 10px;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section .testimonial-wrap .testimonial-slider-wrap {
    padding-left: 0;
    padding-top: 50px;
  }
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial {
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  position: relative;
  margin: 18px 0;
}

@media only screen and (max-width: 991px) {
  .testimonial-section
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 35px;
  }
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleY(1.1) scaleX(0.85);
  border-radius: 5px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleY(1.05) scaleX(0.92);
  border-radius: 5px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  max-width: 285px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .title {
  font-size: 22px;
  line-height: 30px;
  color: #00aeef;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  text-decoration: underline;
  color: #000c29;
  margin-top: 15px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap
  .testimonial-author {
  display: flex;
  align-items: center;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap
  .testimonial-author
  .author-img
  img {
  border-radius: 50%;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap
  .testimonial-author
  .author-text {
  flex: 1;
  margin-left: 20px;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap
  .testimonial-author
  .author-text
  .name {
  font-size: 16px;
  line-height: 20px;
  color: #231e32;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author-wrap
  .testimonial-author
  .author-text
  .designation {
  font-size: 12px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  display: block;
  color: #00aeef;
}

.testimonial-active .testimonial-arrow,
.testimonial-4-active .testimonial-arrow {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  transform: translateY(-30%);
  border: 1px solid #00aeef;
  box-shadow: 0 5px 57px 0 rgba(0, 0, 0, 0.15);
  color: #00aeef;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.testimonial-active .testimonial-arrow.swiper-button-prev,
.testimonial-4-active .testimonial-arrow.swiper-button-prev {
  left: 0;
}

.testimonial-active .testimonial-arrow.swiper-button-prev::after,
.testimonial-4-active .testimonial-arrow.swiper-button-prev::after {
  content: "\f16d";
  font-family: flaticon !important;
  font-size: 18px;
}

.testimonial-active .testimonial-arrow.swiper-button-next,
.testimonial-4-active .testimonial-arrow.swiper-button-next {
  right: 0;
}

.testimonial-active .testimonial-arrow.swiper-button-next::after,
.testimonial-4-active .testimonial-arrow.swiper-button-next::after {
  content: "\f16e";
  font-family: flaticon !important;
  font-size: 18px;
}

.testimonial-active .testimonial-arrow:hover,
.testimonial-4-active .testimonial-arrow:hover {
  color: #fff;
  background: #00aeef;
}

.testimonial-active:hover .testimonial-arrow,
.testimonial-4-active:hover .testimonial-arrow {
  opacity: 1;
  visibility: visible;
}

.testimonial-active:hover .testimonial-arrow.swiper-button-prev,
.testimonial-4-active:hover .testimonial-arrow.swiper-button-prev {
  left: 10px;
}

.testimonial-active:hover .testimonial-arrow.swiper-button-next,
.testimonial-4-active:hover .testimonial-arrow.swiper-button-next {
  right: 10px;
}

.testimonial-section-2 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-2 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-2 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-2 {
    padding-bottom: 40px;
  }
}

.testimonial-section-2 .testimonial-2-wrap .testimonial-slider-wrap {
  max-width: 950px;
  margin: 0 auto;
  margin-top: 60px;
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .testimonial-section-2
    .testimonial-2-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    flex-direction: column;
    gap: 50px;
  }
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-image
  img {
  border-radius: 10px;
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  flex: 1;
  margin-left: 90px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-2
    .testimonial-2-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-2
    .testimonial-2-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content {
    margin-left: 30px;
  }
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content::before {
  content: "";
  position: absolute;
  left: -30px;
  top: -30px;
  background-image: url(../images/testimonial/quote.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 30px;
  line-height: 48px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #000c29;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-2
    .testimonial-2-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-2
    .testimonial-2-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    p {
    font-size: 25px;
    line-height: 44px;
  }
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .name {
  font-size: 16px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  display: block;
  color: #231e32;
  margin-top: 30px;
}

.testimonial-section-2
  .testimonial-2-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .designation {
  font-size: 12px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  display: block;
  color: #5149f3;
}

.testimonial-2-active .swiper-pagination {
  margin-top: 50px;
}

.testimonial-section-3 {
  background: #fbfaff;
}

.testimonial-section-3 .testimonial-wrap-3 {
  margin-bottom: -20px;
}

.testimonial-section-3 .testimonial-wrap-3 .testimonial-slider-wrap {
  max-width: 1070px;
  margin: 0 auto;
  padding-top: 35px;
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial {
  display: flex;
  background: #fff;
  padding: 60px 110px 60px 75px;
  border-radius: 10px;
  margin: 15px 0;
  position: relative;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 60px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial {
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 45px;
  }
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -7px;
  background: #ff5538;
  transform: translateX(-50%);
  width: calc(100% - 45px);
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-img {
    text-align: center;
  }
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-img
  img {
  border-radius: 50%;
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  flex: 1;
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content {
    margin-left: 0;
  }
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 30px;
  line-height: 44px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    p {
    font-size: 26px;
  }
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author {
  display: flex;
  margin-top: 30px;
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .name {
  font-size: 16px;
  line-height: 20px;
  color: #231e32;
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .designation {
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: #ff5538;
}

.testimonial-section-3
  .testimonial-wrap-3
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .testimonial-quote {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .testimonial-section-3
    .testimonial-wrap-3
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    .testimonial-author
    .testimonial-quote {
    margin-left: 24px;
  }
}

.testimonial-3-active.slider-bullet .swiper-pagination {
  margin-top: 50px;
}

.testimonial-3-active.slider-bullet
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ff5538;
}

.testimonial-section-4 {
  padding-bottom: 0;
}

.testimonial-section-4 .testimonial-2-wrap {
  margin-bottom: -20px;
}

.testimonial-section-5 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 2500px) and (max-width: 2560px) {
  .top-left {
    margin-left: 39pc !important;
  }

  .section.Brand-section {
    height: 26vh !important;
  }

  .sticky-button {
    top: 27% !important;
    height: 12vh !important;
  }

  .sticky-button label {
    font-size: 16px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .hero-images.sk img {
    width: 20vw !important;
  }

  .hero-images.sk1 img {
    width: 20vw !important;
  }

  .hero-images.sk2 img {
    width: 20vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .section-padding-03 {
    padding-top: 23px !important;
  }
}

@media only screen and (min-width: 3800px) and (max-width: 3840px) {
  .section.Brand-section {
    height: 20vh !important;
  }

  .top-left {
    margin-left: 79pc !important;
  }

  #phone-foot {
    width: 337px !important;
  }

  .sticky-button {
    top: 17% !important;
    height: 8vh !important;
  }

  .sticky-button label {
    font-size: 16px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .hero-images.sk img {
    width: 15vw !important;
  }

  .hero-images.sk1 img {
    width: 15vw !important;
  }

  .hero-images.sk2 img {
    width: 15vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .section-padding-03 {
    padding-top: 23px !important;
  }
}

@media only screen and (min-width: 4000px) and (max-width: 4100px) {
  .section.Brand-section {
    height: 19vh !important;
  }

  .top-left {
    margin-left: 87pc !important;
  }
}

@media only screen and (min-width: 5000px) and (max-width: 5150px) {
  .section.Brand-section {
    /* height: 15vh !important; */
  }

  .top-left {
    margin-left: 119pc !important;
  }
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-5 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-5 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 950px) and (max-width: 995px) {
  .modal-dialog.download {
    top: 187px !important;
    position: fixed !important;
    float: right !important;
    right: 361px !important;
    margin-bottom: -10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-5 {
    padding-bottom: 60px;
  }
}

.testimonial-section-6 {
  padding-top: 100px;
  overflow: hidden;
}

@media only screen and (max-width: 1399px) {
  .testimonial-section-6 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-6 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.testimonial-section-6 .testimonial-wrap {
  margin-bottom: -80px;
}

@media only screen and (max-width: 1399px) {
  .testimonial-section-6 .testimonial-wrap {
    margin-bottom: 0;
  }
}

.testimonial-section-6 .testimonial-wrap .testimonial-img {
  padding-left: 25px;
  padding-right: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img img {
    width: 80%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 999px) {
  /* .modal-dialog.contact {
        margin-left: 39pc !important;
        margin-top: 3pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
    font-size: 14px;
} */

  input#phones {
    width: 26vw !important;
  }

  .head-sec {
    padding: 2px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 25px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 300px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 32% !important;
    height: 16vh !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 970px) {
  .images img {
    max-width: 37vw !important;
  }

  h2.title.discover {
    font-size: 35px !important;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 17px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 10px !important;
  }

  .section.Brand-section {
    /* height: 53vh !important; */
  }

  .footer-logo img {
    width: 15vw !important;
  }

  .widget-info ul li .info-text {
    font-size: 13px !important;
  }

  .widget-link .link li a {
    font-size: 13px !important;
  }

  #solutions_page label {
    font-size: 13px !important;
  }

  .top-left {
    margin-left: 119px !important;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img img {
    width: 100%;
  }
}

.testimonial-section-6 .testimonial-wrap .testimonial-img::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 430px;
  height: 430px;
  background: linear-gradient(-90deg, #54d6eb 0%, #035cdd 100%);
  border-radius: 50%;
  transform: translate(80px, 80px);
  z-index: -1;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::before {
    transform: translate(235px, 80px);
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::before {
    transform: translate(110px, 80px);
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::before {
    width: 310px;
    height: 310px;
    transform: translate(90px, 80px);
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::before {
    transform: translate(50px, 80px);
  }
}

.testimonial-section-6 .testimonial-wrap .testimonial-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 430px;
  height: 430px;
  background: linear-gradient(-90deg, #54d6eb 0%, #035cdd 100%);
  opacity: 0.4;
  border-radius: 50%;
  transform: translate(80px, 80px) scale(1.23);
  z-index: -1;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::after {
    transform: translate(235px, 80px) scale(1.23);
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::after {
    transform: translate(110px, 80px) scale(1.23);
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::after {
    width: 310px;
    height: 310px;
    transform: translate(90px, 80px) scale(1.23);
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::after {
    transform: translate(50px, 80px) scale(1.23);
  }
}

.testimonial-section-6 .testimonial-wrap .testimonial-content-wrap {
  padding-top: 35px;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-content-wrap {
    padding-top: 60px;
  }
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .section-title
  .title
  span {
  color: #00aeef;
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .testimonial-slider-wrap {
  background: #fff;
  box-shadow: 0 0 111px 0 rgba(0, 0, 0, 0.15);
  padding: 35px 42px 50px;
  border-bottom: 7px solid #00aeef;
  border-radius: 10px;
  margin-top: 50px;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-6
    .testimonial-wrap
    .testimonial-content-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-6
    .testimonial-wrap
    .testimonial-content-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    padding: 0;
  }
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 26px;
  line-height: 44px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 30px;
}

@media only screen and (max-width: 1399px) {
  .testimonial-section-6
    .testimonial-wrap
    .testimonial-content-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content
    p {
    font-size: 24px;
  }
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author {
  margin-top: 50px;
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .name {
  font-size: 16px;
  line-height: 20px;
  color: #231e32;
}

.testimonial-section-6
  .testimonial-wrap
  .testimonial-content-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .designation {
  font-size: 12px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
}

.testimonial-section-7 .testimonial-wrap {
  background-position: 100% 150%;
  background-size: contain;
  background-repeat: no-repeat;
}

.testimonial-section-7 .testimonial-wrap .testimonial-slider-wrap {
  padding-top: 20px;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7 .testimonial-wrap .testimonial-slider-wrap {
    padding-top: 0;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin: 50px 5px 10px;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  padding: 50px 85px 60px 50px;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content {
    padding: 50px 40px;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-quote {
  height: 75px;
  width: 75px;
  line-height: 75px;
  font-size: 45px;
  text-align: center;
  border-radius: 50%;
  color: #00aeef;
  background: #ccdef7;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 18px;
  line-height: 32px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #000c29;
  margin-top: 10px;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-thumb
  img {
  border-radius: 50%;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text {
  margin-left: 25px;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .name {
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #231e32;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .designation {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-testimonial {
  background: linear-gradient(130deg, #54d6eb 0%, #035cdd 100%);
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .swiper-slide.swiper-slide-active
    .single-testimonial {
    background: #fff;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-testimonial
  .testimonial-content
  .testimonial-quote {
  color: #fff;
  background: #6fd4ee;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .swiper-slide.swiper-slide-active
    .single-testimonial
    .testimonial-content
    .testimonial-quote {
    background: #ccdef7;
    color: #00aeef;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-testimonial
  .testimonial-content
  p {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .swiper-slide.swiper-slide-active
    .single-testimonial
    .testimonial-content
    p {
    color: #000c29;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .name {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .swiper-slide.swiper-slide-active
    .single-testimonial
    .testimonial-content
    .testimonial-author
    .author-text
    .name {
    color: #000c29;
  }
}

.testimonial-section-7
  .testimonial-wrap
  .testimonial-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .designation {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .testimonial-section-7
    .testimonial-wrap
    .testimonial-slider-wrap
    .swiper-slide.swiper-slide-active
    .single-testimonial
    .testimonial-content
    .testimonial-author
    .author-text
    .designation {
    color: #00aeef;
  }
}

.testimonial-section-8 {
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-8 .testimonial-wrap .section-title {
    text-align: center;
  }
}

.testimonial-section-8 .testimonial-wrap .testimonial-slider-wrap {
  max-width: 640px;
  margin-left: auto;
}

@media only screen and (max-width: 1379px) {
  .testimonial-section-8 .testimonial-wrap .testimonial-slider-wrap {
    margin: 0 auto;
    padding-top: 50px;
  }
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .testimonial-section-8
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial {
    flex-direction: column;
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-section-8
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-quote
    img {
    width: 120px;
  }
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  flex: 1;
  padding-left: 50px;
}

@media only screen and (min-width: 590px) and (max-width: 605px) {
  .section.Brand-section {
    /* height: 51vh !important; */
  }

  .head-sec {
    width: 373px !important;
    margin-left: 0px !important;
  }

  .modal-dialog.contact {
    right: 77px !important;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-section-8
    .testimonial-wrap
    .testimonial-slider-wrap
    .single-testimonial
    .testimonial-content {
    padding-left: 0;
  }
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  font-size: 20px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #000c29;
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-img
  img {
  border-radius: 50%;
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text {
  flex: 1;
  margin-left: 20px;
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .name {
  font-size: 16px;
  line-height: 20px;
  color: #231e32;
}

.testimonial-section-8
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  .testimonial-author
  .author-text
  .designation {
  font-size: 12px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  display: block;
  color: #00aeef;
}

.testimonial-inner-1 .testimonial-wrap-3 .testimonial-slider-wrap {
  padding-top: 0;
}

.testimonial-inner-2 .testimonial-2-wrap .testimonial-slider-wrap {
  margin-top: 0;
}

.case-study-section {
  overflow: hidden;
}

.case-study-wrap {
  margin-top: -10px;
}

.case-study-slider-wrap {
  margin-top: 90px;
}

@media only screen and (max-width: 991px) {
  .case-study-slider-wrap {
    margin-top: 60px;
  }
}

.case-study-slider-wrap .single-case-study {
  display: flex;
  align-items: flex-end;
  max-width: 1070px;
  height: 550px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.case-study-slider-wrap .single-case-study::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #ffffff00 0%, #010f31 100%);
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
}

.case-study-slider-wrap .single-case-study::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .case-study-slider-wrap .single-case-study {
    height: 450px;
  }
}

.case-study-slider-wrap .single-case-study .case-study-content {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 90px;
  gap: 50px;
  display: none;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .case-study-slider-wrap .single-case-study .case-study-content {
    padding: 40px;
  }

  /* input#phone-foot {
        width: 333px !important;
    } */
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  input#phone-foot {
    width: 333px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .case-study-slider-wrap .single-case-study .case-study-content {
    flex-direction: column;
    gap: 30px;
    padding: 50px;
  }
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-title {
  flex-shrink: 0;
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-title
  .title {
  font-size: 30px;
  line-height: 30px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .case-study-slider-wrap
    .single-case-study
    .case-study-content
    .case-study-title
    .title {
    font-size: 22px;
  }
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-title
  span {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-family: 400;
  color: #00aeef;
}

@media only screen and (max-width: 767px) {
  .case-study-slider-wrap
    .single-case-study
    .case-study-content
    .case-study-title
    span {
    font-size: 18px;
  }
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-text {
  flex-grow: 1;
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-text
  p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-family: 400;
  color: #fff;
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-link {
  text-align: right;
  flex-grow: 1;
}

@media only screen and (max-width: 1399px) {
  .case-study-slider-wrap
    .single-case-study
    .case-study-content
    .case-study-link {
    text-align: left;
  }
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-link
  a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background: #00aeef;
  color: #fff;
  transition: all 0.3s linear;
}

.case-study-slider-wrap
  .single-case-study
  .case-study-content
  .case-study-link
  a:hover {
  background: #fff;
  color: #00aeef;
}

.case-study-slider-wrap
  .swiper-slide.swiper-slide-active
  .single-case-study
  .case-study-content {
  display: flex;
}

.case-study-btn {
  margin-top: 60px;
}

.case-study-btn .btn {
  font-size: 14px;
  padding: 0 35px;
  height: 50px;
  line-height: 46px;
}

.case-study-active .case-study-arrow {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0 5px 57px 0 rgba(0, 0, 0, 0.15);
  color: #00aeef;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 991px) {
  .case-study-active .case-study-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.case-study-active .case-study-arrow.swiper-button-prev {
  left: 20%;
}

.case-study-active .case-study-arrow.swiper-button-prev::after {
  content: "\f16d";
  font-family: flaticon !important;
  font-size: 20px;
}

.case-study-active .case-study-arrow.swiper-button-next {
  right: 20%;
}

.case-study-active .case-study-arrow.swiper-button-next::after {
  content: "\f16e";
  font-family: flaticon !important;
  font-size: 20px;
}

.case-study-active .case-study-arrow:hover {
  color: #fff;
  background: #00aeef;
}

.case-study-active:hover .case-study-arrow {
  opacity: 1;
  visibility: visible;
}

.case-study-active:hover .case-study-arrow.swiper-button-prev {
  left: 23%;
}

@media only screen and (max-width: 1379px) {
  .case-study-active:hover .case-study-arrow.swiper-button-prev {
    left: 22%;
  }
}

@media only screen and (max-width: 991px) {
  .case-study-active:hover .case-study-arrow.swiper-button-prev {
    left: 30px;
  }
}

.case-study-active:hover .case-study-arrow.swiper-button-next {
  right: 23%;
}

@media only screen and (max-width: 1379px) {
  .case-study-active:hover .case-study-arrow.swiper-button-next {
    right: 22%;
  }
}

@media only screen and (max-width: 991px) {
  .case-study-active:hover .case-study-arrow.swiper-button-next {
    right: 30px;
  }
}

.case-study-section-2 {
  background: #fbfbfc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.case-study-section-2 .case-study-wrap .case-study-content-wrap {
  padding-top: 35px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  > .row
  > div[class^="col"] {
  display: flex;
}

@media only screen and (max-width: 1379px) {
  .case-study-section-2 .case-study-wrap .case-study-content-wrap {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .case-study-section-2 .case-study-wrap .case-study-content-wrap {
    padding-top: 10px;
  }
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item {
  background: #fff;
  box-shadow: 0 0 131px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin-top: 30px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-img {
  padding: 30px;
}

@media only screen and (max-width: 1379px) {
  .case-study-section-2
    .case-study-wrap
    .case-study-content-wrap
    .case-study-item
    .case-study-img {
    padding: 25px;
  }
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-img
  a {
  display: block;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-img
  a
  img {
  width: 100%;
  border-radius: 10px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content {
  padding: 0 30px 35px;
  margin-top: -10px;
}

@media only screen and (max-width: 1379px) {
  .case-study-section-2
    .case-study-wrap
    .case-study-content-wrap
    .case-study-item
    .case-study-content {
    padding: 0 25px 30px;
  }
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content
  .title {
  font-size: 24px;
  line-height: 30px;
  color: #3e3e3e;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content
  span {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
}

.cta-section .cta-wrap {
  background: #00aeef;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 5px;
  padding: 75px 125px 80px 110px;
  margin-bottom: -110px;
  position: relative;
  z-index: 5;
}

.cta-section .cta-wrap .cta-left .title {
  font-size: 36px;
  line-height: 44px;
  color: #fff;
}

.cta-section .cta-wrap .cta-right {
  text-align: right;
}

.cta-section .cta-wrap .cta-right .number {
  font-size: 22px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #fff;
}

.cta-section .cta-wrap .cta-right .btn {
  padding: 0 35px;
  margin-left: 20px;
}

@media only screen and (max-width: 1379px) {
  .cta-section .cta-wrap {
    padding: 80px 55px;
  }

  .cta-section .cta-wrap .cta-left .title {
    font-size: 30px;
  }

  .cta-section .cta-wrap .cta-right .btn {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section .cta-wrap .cta-left {
    text-align: center;
  }

  .cta-section .cta-wrap .cta-right {
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .cta-section .cta-wrap .cta-left .title {
    font-size: 30px;
  }

  .cta-section .cta-wrap .cta-right .number {
    display: block;
    margin-bottom: 10px;
  }

  .cta-section .cta-wrap .cta-right .btn {
    margin-left: 0;
  }
}

.cta-section-2 {
  background-image: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 240px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .cta-section-2 {
    padding-top: 220px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section-2 .cta-wrap-2 .cta-content {
    text-align: center;
  }
}

.cta-section-2 .cta-wrap-2 .cta-content .title {
  font-size: 36px;
  line-height: 48px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .cta-section-2 .cta-wrap-2 .cta-content .title {
    font-size: 30px;
  }
}

.cta-section-2 .cta-wrap-2 .cta-btn {
  text-align: right;
}

@media only screen and (max-width: 1379px) {
  .cta-section-2 .cta-wrap-2 .cta-btn {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section-2 .cta-wrap-2 .cta-btn {
    margin-top: 35px;
  }
}

.cta-section-2 .cta-wrap-2 .cta-btn .btn {
  padding: 0 45px;
  margin-right: 15px;
}

@media only screen and (max-width: 1379px) {
  .cta-section-2 .cta-wrap-2 .cta-btn .btn {
    padding: 0 35px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section-2 .cta-wrap-2 .cta-btn .btn {
    margin-top: 15px;
  }
}

.cta-section-2 .cta-wrap-2 .cta-btn .btn-white {
  color: #00aeef;
  margin-right: 0;
}

.cta-section-2 .cta-wrap-2 .cta-btn .btn-white:hover {
  color: #fff;
}

.cta-section-3 {
  background: #0b0820;
  padding-bottom: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 1379px) {
  .cta-section-3 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section-3 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section-3 {
    padding-bottom: 60px;
  }
}

.cta-section-3 .cta-wrap-3 {
  margin-top: -10px;
}

.cta-section-3 .cta-wrap-3 .cta-content .title {
  font-size: 60px;
  line-height: 72px;
  color: #fff;
}

@media only screen and (max-width: 1399px) {
  .cta-section-3 .cta-wrap-3 .cta-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 1379px) {
  .cta-section-3 .cta-wrap-3 .cta-content .title {
    font-size: 45px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section-3 .cta-wrap-3 .cta-content .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.cta-section-3 .cta-wrap-3 .cta-content p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .cta-section-3 .cta-wrap-3 .cta-content p {
    font-size: 15px;
    margin-top: 25px;
  }
}

.cta-section-3 .cta-wrap-3 .cta-content p a {
  font-weight: 700;
  color: #00aeef;
  text-decoration: underline;
}

.cta-section-4 {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background: #09091d;
  padding-bottom: 100px;
}

@media only screen and (max-width: 991px) {
  .cta-section-4 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section-4 {
    padding-bottom: 60px;
  }
}

.cta-section-4 .cta-wrap .cta-left {
  display: flex;
  padding-right: 65px;
}

@media only screen and (max-width: 1399px) {
  .cta-section-4 .cta-wrap .cta-left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .cta-section-4 .cta-wrap .cta-left {
    display: block;
    text-align: center;
  }
}

.cta-section-4 .cta-wrap .cta-left .title {
  font-size: 36px;
  line-height: 44px;
  color: #fff;
  margin-left: 40px;
}

@media only screen and (max-width: 1379px) {
  .cta-section-4 .cta-wrap .cta-left .title {
    font-size: 32px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-section-4 .cta-wrap .cta-left .title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .cta-section-4 .cta-wrap .cta-left .title {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-section-4 .cta-wrap .cta-right {
    margin-top: 40px;
  }
}

.cta-section-4 .cta-wrap .cta-right .title {
  font-size: 22px;
  color: #fff;
}

.cta-section-4 .cta-wrap .cta-right .btn {
  margin-top: 10px;
}

.blog-section .blog-wrap {
  margin-top: -10px;
}

.blog-section .blog-wrap .blog-content-wrap {
  padding-top: 35px;
}

.single-blog {
  background: #fff;
  box-shadow: 0 0 98px 0 rgba(0, 0, 0, 0.07);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 30px;
}

.single-blog .blog-img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.single-blog .blog-img a {
  display: block;
}

.single-blog .blog-img a img {
  width: 100%;
  transition: all 0.3s linear;
}

.single-blog .blog-img .top-meta {
  position: absolute;
  top: 35px;
  left: 30px;
}

.single-blog .blog-img .top-meta .date {
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-image: linear-gradient(120deg, #54d6eb 0%, #035cdd 100%);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  display: inline-block;
  text-align: center;
  line-height: 12px;
  padding: 10px 0;
}

.single-blog .blog-img .top-meta .date span {
  font-size: 28px;
  line-height: 18px;
  font-weight: 500;
  line-height: 30px;
  display: block;
}

.single-blog .blog-content {
  padding: 40px;
}

@media only screen and (max-width: 991px) {
  .single-blog .blog-content {
    padding: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-content {
    padding: 35px 24px;
  }
}

.single-blog .blog-content .blog-meta span {
  font-size: 14px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #999;
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-meta span {
    margin-right: 12px;
  }
}

.single-blog .blog-content .blog-meta span i {
  color: #00aeef;
  margin-right: 5px;
}

.single-blog .blog-content .title {
  font-size: 22px;
  line-height: 30px;
  color: #333;
  padding-right: 15px;
  margin-top: 15px;
}

.single-blog .blog-content p {
  font-size: 14px;
  line-height: 26px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 10px;
}

.single-blog .blog-btn {
  border-top: 1px solid #ebebeb;
  padding: 15px 40px;
}

.single-blog .blog-btn .blog-btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #00aeef;
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link i {
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link:hover i {
  transform: translateX(5px);
}

.single-blog:hover .blog-img a img {
  transform: scale(1.1);
}

.blog-section-2 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 1379px) {
  .blog-section-2 {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .blog-section-2 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-section-2 {
    padding-bottom: 60px;
  }
}

.blog-section-2 .blog-wrap .section-title .sub-title {
  color: #ff5538;
}

.blog-section-2
  .blog-wrap
  .blog-content-wrap
  .single-blog
  .blog-img
  .top-meta
  .date {
  background: #ff5538;
}

.blog-section-2
  .blog-wrap
  .blog-content-wrap
  .single-blog
  .blog-content
  .blog-meta
  span
  a:hover {
  color: #ff5538;
}

.blog-section-2
  .blog-wrap
  .blog-content-wrap
  .single-blog
  .blog-content
  .blog-meta
  span
  i {
  color: #ff5538;
}

.blog-section-2
  .blog-wrap
  .blog-content-wrap
  .single-blog
  .blog-content
  .title
  a:hover {
  color: #ff5538;
}

.blog-section-2
  .blog-wrap
  .blog-content-wrap
  .single-blog
  .blog-btn
  .blog-btn-link {
  color: #ff5538;
}

.blog-section-3 .blog-content-wrap .single-blog {
  position: relative;
}

.blog-section-3 .blog-content-wrap .single-blog .blog-img {
  border-radius: 10px;
}

.blog-section-3 .blog-content-wrap .single-blog .blog-content {
  background: none;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 1379px) {
  .blog-section-3 .blog-content-wrap .single-blog .blog-content {
    padding: 35px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-section-3 .blog-content-wrap .single-blog .blog-content {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-section-3 .blog-content-wrap .single-blog .blog-content {
    padding: 35px 25px;
  }
}

.blog-section-3 .blog-content-wrap .single-blog .blog-content .blog-meta span {
  color: #fff;
}

.blog-section-3 .blog-content-wrap .single-blog .blog-content .title {
  color: #fff;
}

.blog-grid-wrap {
  margin-top: -30px;
}

.blog-grid-wrap .pagination {
  margin-top: 70px;
}

.brand-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: #717171;
  margin-top: -7px;
}

@media only screen and (max-width: 575px) {
  .brand-title {
    font-size: 20px;
  }
}

.brand-title span {
  color: #00aeef;
  font-weight: 700;
}

.brand-active {
  max-width: 1070px;
  width: 100%;
  margin: 45px auto 0;
}

.brand-active .swiper-container {
  padding-bottom: 50px;
}

.brand-active .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-repeat: 50%;
  background: #e1e1e1;
}

.brand-active
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00aeef;
}

.Brand-section-2 .brand-wrapper-2 {
  background: #fff;
  box-shadow: 0 0 144px 0 rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  margin-bottom: -150px;
  z-index: 1;
}

.Brand-section-3 .brand-wrapper {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 120px;
}

@media only screen and (max-width: 1379px) {
  .Brand-section-3 .brand-wrapper {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .Brand-section-3 .brand-wrapper {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .Brand-section-3 .brand-wrapper {
    padding-bottom: 60px;
  }
}

.Brand-section-3 .brand-wrapper .brand-title span {
  color: #ff5538;
}

.Brand-section-4 {
  background: #fbfbfc;
}

.team-section .team-wrap .team-content-wrap {
  padding-top: 90px;
}

@media only screen and (max-width: 1379px) {
  .team-section .team-wrap .team-content-wrap {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .team-section .team-wrap .team-content-wrap {
    padding-top: 50px;
  }
}

.team-section .team-wrap .team-content-wrap .single-team .team-img a {
  display: block;
  position: relative;
}

.team-section .team-wrap .team-content-wrap .single-team .team-img a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 430px;
  background-color: #0071dc;
  clip-path: polygon(0 34%, 0% 100%, 100% 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.team-section .team-wrap .team-content-wrap .single-team .team-img a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 480px;
  background-color: #0071dc;
  clip-path: polygon(0 34%, 0% 100%, 100% 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.team-section .team-wrap .team-content-wrap .single-team .team-img a img {
  border-radius: 5px;
  width: 100%;
}

.team-section .team-wrap .team-content-wrap .single-team .team-content {
  background: #fff;
  padding: 30px;
  margin-top: -70px;
  position: relative;
  width: 290px;
  z-index: 1;
}

@media only screen and (max-width: 1379px) {
  .team-section .team-wrap .team-content-wrap .single-team .team-content {
    width: 230px;
  }
}

@media only screen and (max-width: 991px) {
  .team-section .team-wrap .team-content-wrap .single-team .team-content {
    width: 270px;
  }
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team
  .team-content
  .team-social {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-25px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team
  .team-content
  .team-social
  .social
  li {
  display: inline-block;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team
  .team-content
  .team-social
  .social
  li
  + li {
  margin-left: 10px;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team
  .team-content
  .team-social
  .social
  li
  a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear;
}

.team-section .team-wrap .team-content-wrap .single-team .team-content .name {
  font-size: 22px;
  line-height: 28px;
  color: #231e32;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team
  .team-content
  .designation {
  font-size: 16px;
  line-height: 28px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team:hover
  .team-img
  a::before {
  opacity: 1;
  visibility: visible;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team:hover
  .team-img
  a::after {
  opacity: 0.35;
  visibility: visible;
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team:hover
  .team-content
  .team-social {
  opacity: 1;
  visibility: visible;
  transform: translateY(-55px);
}

.team-section
  .team-wrap
  .team-content-wrap
  .single-team:hover
  .team-content
  .team-social
  .social
  li
  a:hover {
  transform: translateY(-5px);
}

.team-active .swiper-pagination {
  position: relative;
  margin-top: 30px;
}

.team-active .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-repeat: 50%;
  background: #e1e1e1;
}

.team-active
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #00aeef;
}

.our-tem-section .our-team-wrap {
  margin-top: -30px;
}

.our-tem-section .our-team-wrap .team-content-wrap {
  padding-top: 0;
}

.our-tem-section .our-team-wrap .team-content-wrap .single-team {
  margin-top: 30px;
}

.our-tem-section .our-team-wrap .team-content-wrap .single-team .team-content {
  width: 260px;
}

@media only screen and (max-width: 1399px) {
  .our-tem-section
    .our-team-wrap
    .team-content-wrap
    .single-team
    .team-content {
    margin-top: -60px;
    width: 230px;
  }
}

@media only screen and (max-width: 991px) {
  .our-tem-section
    .our-team-wrap
    .team-content-wrap
    .single-team
    .team-content {
    width: 270px;
    margin-top: -70px;
  }
}

.video-section {
  padding-top: 200px;
  padding-bottom: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("/assets/images/bg/ice-london-booth.webp");
}

@media only screen and (max-width: 1379px) {
  .video-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.video-section .video-wrap .video-content .title {
  font-size: 48px;
  line-height: 60px;
  color: #fff;
}

@media only screen and (max-width: 1379px) {
  .video-section .video-wrap .video-content .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .video-section .video-wrap .video-content .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.video-section .video-wrap .video-content .video-play {
  margin-top: 40px;
}

.video-section .video-wrap .video-content .video-play a i {
  width: 100px;
  height: 100px;
  background: #00aeef;
  display: inline-block;
  line-height: 100px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 991px) {
  .video-section .video-wrap .video-content .video-play a i {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

.video-section .video-wrap .video-content .video-play a i:hover {
  background: #fff;
  color: #00aeef;
}

.video-section .video-wrap .video-content .video-play a span {
  font-size: 22px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #fff;
  display: block;
  margin-top: 15px;
  width: 126px !important;
  margin-left: -11px;
}

@media only screen and (max-width: 767px) {
  .video-section .video-wrap .video-content .video-play a span {
    font-size: 20px;
  }
}

.pricing-section {
  background: #fafbff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.pricing-section .pricing-wrap .pricing-content-wrap {
  padding-top: 30px;
}

.pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
  background: #fff;
  border-top: 7px solid #00aeef;
  box-shadow: 0 0 144px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 45px 55px 55px;
  margin-top: 30px;
}

@media only screen and (max-width: 1399px) {
  .pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
    padding: 45px 30px 50px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-badge
  .title {
  font-size: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #333;
}

@media only screen and (max-width: 575px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-badge
    .title {
    font-size: 24px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-badge
  .recommend {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  background: #ffd166;
  font-size: 12px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 20px;
  border-radius: 35px;
  color: #fff;
  position: relative;
  top: -5px;
  margin-left: 15px;
}

@media only screen and (max-width: 575px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-badge
    .recommend {
    padding: 0 18px;
    margin-left: 10px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  .price {
  font-size: 72px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  display: inline-block;
  color: #00aeef;
}

@media only screen and (max-width: 991px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-price
    .price {
    font-size: 55px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  .currency {
  font-size: 24px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #00aeef;
  display: inline-block;
  position: relative;
  top: -20px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  p {
  font-size: 20px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #b4b4b4;
  margin-left: 25px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content {
  padding-top: 55px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-list
  li {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #666;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-list
  li
  i {
  color: #5149f3;
  margin-right: 20px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-list
  li
  + li {
  margin-top: 10px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn {
  padding-top: 40px;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn
  .btn {
  height: 50px;
  line-height: 45px;
  padding: 0 35px;
  margin-right: 20px;
}

@media only screen and (max-width: 1399px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-content
    .pricing-btn
    .btn {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-content
    .pricing-btn
    .btn {
    padding: 0 30px;
    font-size: 16px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn
  .pricing-contact {
  font-size: 18px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #00aeef;
  text-decoration: underline;
}

@media only screen and (max-width: 575px) {
  .pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-content
    .pricing-btn
    .pricing-contact {
    margin-top: 15px;
    font-size: 16px;
  }
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn
  .pricing-contact:hover {
  color: #5149f3;
}

.pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active {
  border-top: 0;
  background: linear-gradient(-25deg, #54d6eb 0%, #035cdd 100%);
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-badge
  .title {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  .price {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  .currency {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  p {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-list
  li {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-list
  li
  i {
  color: #fff;
}

.pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-btn
  .pricing-contact {
  color: #5149f3;
}

.pricing-section-2 {
  background: #fff;
}

.contact-section {
  background: #ff5538;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.contact-section .contact-wrap .contact-info {
  max-width: 410px;
}

.contact-section .contact-wrap .contact-info .info-title {
  font-size: 40px;
  line-height: 54px;
  color: #fff;
}

@media only screen and (max-width: 1379px) {
  .contact-section .contact-wrap .contact-info .info-title {
    font-size: 34px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-section .contact-wrap .contact-info .info-title {
    font-size: 28px;
    line-height: 40px;
  }
}

.contact-section .contact-wrap .contact-info ul {
  padding-top: 15px;
}

.contact-section .contact-wrap .contact-info ul li {
  border-top: 1px solid #fff;
  margin-top: 15px;
  padding-top: 15px;
}

.contact-section .contact-wrap .contact-info ul li:first-child {
  border-top: 0;
}

.contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-icon
  i {
  font-size: 50px;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .contact-section
    .contact-wrap
    .contact-info
    ul
    li
    .contact-info-item
    .contact-info-icon
    i {
    font-size: 42px;
  }
}

.contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text {
  margin-left: 20px;
}

.contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text
  .title {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text
  p {
  font-size: 15px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
}

.contact-section .contact-wrap .contact-form {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 110px;
}

@media only screen and (max-width: 1379px) {
  .contact-section .contact-wrap .contact-form {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-section .contact-wrap .contact-form {
    margin-left: 0;
    margin-top: 60px;
  }
}

.contact-section .contact-wrap .contact-form .contact-form-wrap {
  padding: 60px;
}

@media only screen and (max-width: 1379px) {
  .contact-section .contact-wrap .contact-form .contact-form-wrap {
    padding: 40px;
  }
}

.contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap {
  margin-bottom: 30px;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #ff5538;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .title {
  font-size: 42px;
  line-height: 54px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 1379px) {
  .contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .heading-wrap
    .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .heading-wrap
    .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.contact-section .contact-wrap .contact-form .contact-form-wrap .single-form {
  margin-top: 25px;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ) {
  height: 55px;
  line-height: 55px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea {
  height: 135px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
  resize: none;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn {
  margin-top: 25px;
}

.contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
  font-size: 16px;
  width: 100%;
  height: 55px;
  line-height: 50px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .form-btn
    .btn {
    padding: 0 45px;
  }
}

.contact-info-section .contact-info-wrap {
  margin-top: -10px;
}

@media only screen and (max-width: 1379px) {
  .contact-info-section .contact-info-wrap {
    margin-top: -30px;
  }
}

.contact-info-section .contact-info-wrap .single-contact-info {
  background: #fff;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.11);
  padding: 55px;
  border-radius: 10px;
  margin-top: 70px;
}

@media only screen and (max-width: 1379px) {
  .contact-info-section .contact-info-wrap .single-contact-info {
    padding: 40px;
  }
}

.contact-info-section .contact-info-wrap .single-contact-info .info-icon {
  width: 100px;
  height: 100px;
  line-height: 103px;
  background: #00aeef;
  color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -115px;
}

@media only screen and (max-width: 1379px) {
  .contact-info-section .contact-info-wrap .single-contact-info .info-icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin-top: -80px;
  }
}

.contact-info-section .contact-info-wrap .single-contact-info .info-icon i {
  font-size: 40px;
}

@media only screen and (max-width: 1379px) {
  .contact-info-section .contact-info-wrap .single-contact-info .info-icon i {
    font-size: 30px;
  }
}

.contact-info-section
  .contact-info-wrap
  .single-contact-info
  .info-content
  .title {
  font-size: 18px;
  line-height: 36px;
  color: #333;
  margin-top: 15px;
}

.contact-info-section .contact-info-wrap .single-contact-info .info-content p {
  font-size: 22px;
  line-height: 36px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #00aeef;
  text-decoration: underline;
  text-decoration-color: #7faeef;
}

@media only screen and (max-width: 1379px) {
  .contact-info-section
    .contact-info-wrap
    .single-contact-info
    .info-content
    p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-info-section
    .contact-info-wrap
    .single-contact-info
    .info-content
    p {
    font-size: 18px;
  }
}

.contact-form-section .contact-wrap {
  margin-top: -10px;
}

.contact-form-section .contact-wrap .form-title .title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  color: #333;
}

@media only screen and (max-width: 991px) {
  .contact-form-section .contact-wrap .form-title .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-form-section .contact-wrap .form-title .title {
    font-size: 28px;
  }
}

.contact-form-section .contact-wrap .contact-form-wrap {
  padding-top: 30px;
}

.contact-form-section .contact-wrap .contact-form-wrap .single-form {
  margin-top: 20px;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  .form-control:focus {
  outline: none;
  border-color: #00aeef;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  textarea.form-control {
  height: 135px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  padding-top: 15px;
  resize: none;
}

.contact-form-section
  .contact-wrap
  .contact-form-wrap
  .single-form
  textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.contact-form-section .contact-wrap .contact-form-wrap .form-btn .btn {
  height: 45px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 30px;
  margin-top: 35px;
}

.contact-map-section .contact-map-wrap {
  margin-bottom: -7px;
}

.contact-map-section .contact-map-wrap iframe {
  height: 550px;
  width: 100%;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.contact-section-2
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .sub-title {
  color: #00aeef;
}

.page-banner-section {
  padding-top: 80px;
  /* min-height: 300px; */
  display: flex;
  /* align-items: center; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: none;
}

.page-banner-section.solutions {
  background: linear-gradient(
    90deg,
    rgba(238, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(238, 233, 233, 1) 100%
  );
}

@media only screen and (max-width: 991px) {
  .page-banner-section {
    min-height: 0px;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }

  /* .carousel-inner {
        height: 442px !important;
    } */

  .tech-hero-section-6 .hero-content2 {
    margin-top: -24px;
    margin-left: 50px !important;
  }

  .section.Brand-section {
    /* height: 30vh; */
  }
}

@media only screen and (min-width: 550px) and (max-width: 610px) {
  /* img {
        max-width: 100vw !important;
    } */
  .carousel-control-prev-icon.solu {
    margin-right: -3rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  .carousel-control-next-icon.solu {
    margin-left: -5rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-left: 133px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: -24px;
    margin-left: 135px !important;
  }

  #solutions_page img {
    max-width: 26vw !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 45px !important;
    margin-left: 90px !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 766px) {
  div.language_es {
    height: 62vh !important;
    text-align: justify !important;
    padding: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 770px) {
  /* .modal-dialog.contact {
        margin-left: 22pc !important;
        margin-top: 11pc !important;
        width: 308px !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */
  .header-menu .main-menu li a {
    font-size: 13px !important;
  }

  .modal-dialog.download {
    top: 187px !important;
    position: fixed !important;
    float: right !important;
    right: 240px !important;
    margin-bottom: -10px !important;
  }

  #phone-foot {
    width: 334px !important;
  }

  input#phones {
    width: 43.6vw !important;
  }

  .head-sec {
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
    width: 366px !important;
  }

  .head-sec p span {
    font-size: 20px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 10px !important;
  }

  button.btn-close.download {
    margin-top: -10px;
    margin-left: -19px;
  }

  .modal-body.download {
    padding: 0px 0px 10px 0px !important;
  }

  .images img {
    max-width: 349px !important;
  }

  #phones {
    width: 41vw !important;
  }

  .sticky-button label {
    top: 84% !important;
    margin-right: -2px !important;
  }

  .sticky-button {
    height: 15vh !important;
  }

  .carousel-control-prev-icon.solu {
    margin-right: -3rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  .carousel-control-next-icon.solu {
    margin-left: -5rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  h2.title.discover {
    font-size: 35px !important;
    text-align: center;
  }

  /* .brand-active {
        margin-left: 126px !important;
    }

    .brand-wrapper.text-center .carousel-indicators {
        margin-right: 312px !important;
    } */

  .sticky-button {
    padding: 23px 37px 23px 18px !important;
  }

  .content.lotto h2 {
    font-size: 27px !important;
  }

  .content.bingo h2 {
    font-size: 27px !important;
  }

  .content.instant h2 {
    font-size: 27px !important;
  }

  .content.slots h2 {
    font-size: 27px !important;
  }

  .content.sport-lotto h2 {
    font-size: 27px !important;
  }

  .game-images img {
    max-width: 70vw;
    width: 34vw !important;
  }

  .slogan h1 {
    font-size: 39px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -3px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 6px !important;
  }

  .footer-logo img {
    max-width: 134px !important;
  }

  .widget-info ul li .info-text {
    font-size: 12px !important;
  }

  .widget-info ul li .info-icon i {
    font-size: 15px;
  }

  .widget-info ul li .info-icon svg {
    font-size: 15px;
  }

  .image.lrs img {
    max-width: 42vw !important;
    margin-top: 5px !important;
    width: 33vw !important;
  }

  .LRS {
    width: 31% !important;
  }

  .title.pro-sol {
    font-size: 15px !important;
    margin-left: 81px !important;
  }

  .image.sol img {
    max-width: 47vw !important;
    width: 42vw !important;
  }

  .retail {
    width: 74% !important;
  }

  #winner-award {
    max-width: 30vw !important;
    width: 21vw !important;
  }

  .digital {
    width: 83% !important;
  }

  .scan-n-play {
    width: 74% !important;
  }

  .paper-lotto {
    width: 82% !important;
  }

  .instant-lotto {
    width: 100% !important;
    font-size: 16px !important;
  }

  .cashless {
    width: 260px !important;
    font-size: 15px !important;
  }

  /* sport-lotto {
      width: 86% !important;
    } */

  .turnkey-sol {
    width: 100% !important;
    font-size: 20px !important;
  }

  .title.aos-init.aos-animate {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  div.language_es {
    height: 65vh !important;
    text-align: justify !important;
    padding: 0px;
  }
}

@media only screen and (max-width: 767px) {
  /* .page-banner-section {
        min-height: 200px
    } */

  /* .page-banner-section {
    padding-top: 67px;
  } */

  .page-banner-section {
    min-height: auto;
    height: auto;
    padding-top: 69px;
  }

  label#media {
    width: 73vw !important;
    font-size: 21px !important;
  }

  label#interview {
    width: 73vw !important;
    font-size: 21px !important;
  }
}

.page-banner-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #0e2641;
  opacity: 0.3;
  width: 100%;
  height: auto;
  z-index: -1;
}

.page-banner-section .shape-2 {
  width: 545px;
  height: 545px;
  border: 3px solid #00aeef;
  opacity: 0.9;
  border-radius: 50%;
  position: absolute;
  left: -10%;
  bottom: -22%;
}

@media only screen and (max-width: 1379px) {
  .page-banner-section .shape-2 {
    width: 420px;
    height: 420px;
  }
}

@media only screen and (max-width: 991px) {
  .page-banner-section .shape-2 {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-section .shape-2 {
    left: -25%;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner-section .shape-2 {
    display: none;
  }
}

.page-banner-section .shape-2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
  width: 100%;
  height: 100%;
  border-radius: 98% 26% 73% 67%;
  transform: scale(0.8);
}

.page-banner .title {
  font-size: 60px;
  line-height: 1.25;
  font-family: "Manrope", sans-serif;
  display: inline-block;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 1;
}

.page-banner .title::before {
  content: "";
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-image: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
  opacity: 0.9;
  position: absolute;
  left: -25px;
  top: -20px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .page-banner .title::before {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 1379px) {
  .page-banner .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .title {
    font-size: 25px;
  }
}

.page-banner .breadcrumb {
  margin-bottom: 0;
  margin-top: 10px;
}

.page-banner .breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner .breadcrumb .breadcrumb-item {
    font-size: 16px;
  }
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 8px;
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  font-family: flaticon !important;
  font-size: 16px;
  padding-right: 5px;
  color: #fff;
}

.blog-standard-wrap {
  margin-top: -70px;
}

.blog-standard-wrap .blog-post-wrap .pagination {
  margin-top: 55px;
}

.single-blog-post {
  margin-top: 70px;
}

.blog-sidebar {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 2px 19px;
  margin-top: 70px;
  margin-left: 65px;
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 1379px) {
  .blog-sidebar {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .blog-sidebar {
    margin-left: 0;
  }
}

.blog-sidebar .sidebar-widget-1 {
  margin-top: 0;
}

.sidebar-widget .search-form {
  position: relative;
}

.sidebar-widget
  .search-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ) {
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  border: 0;
  padding-right: 35px;
  background: #f8fafb;
  color: #415674;
  font-weight: 700;
  outline: none;
  overflow: hidden;
}

.sidebar-widget
  .search-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget
  .search-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget
  .search-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget
  .search-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget .search-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  color: #111;
  transition: all 0.3s linear;
}

.sidebar-widget .search-form button:hover {
  background: #00aeef;
  color: #fff;
}

.sidebar-widget .widget-title {
  margin-bottom: 25px;
}

.sidebar-widget .widget-title .title {
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  display: inline-block;
}

.sidebar-widget .recent-posts ul li {
  margin-top: 30px;
}

.sidebar-widget .recent-posts ul li:first-child {
  margin-top: 0;
}

.sidebar-widget .recent-posts ul li .post-link {
  display: flex;
  align-items: center;
}

.sidebar-widget .recent-posts ul li .post-link .post-thumb {
  margin-right: 20px;
}

.sidebar-widget .recent-posts ul li .post-link .post-thumb img {
  min-width: 70px;
  height: 70px;
  border-radius: 10px;
  image-rendering: crisp-edges;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .title {
  font-size: 14px;
  line-height: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  transition: all 0.3s linear;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .post-meta {
  font-size: 12px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #838383;
  display: inline-block;
  margin-top: 5px;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .post-meta i {
  color: #00aeef;
  margin-right: 5px;
}

.sidebar-widget .recent-posts ul li .post-link:hover .post-text .title {
  color: #00aeef;
}

.sidebar-widget .category .cate-item {
  margin-top: 15px;
}

.sidebar-widget .category .cate-item:first-child {
  margin-top: 0;
}

.sidebar-widget .category .cate-item a {
  display: flex;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #29303c;
  transition: all 0.3s linear;
}

.sidebar-widget .category .cate-item a i {
  font-size: 10px;
  line-height: 24px;
  color: #00aeef;
  margin-right: 5px;
}

.sidebar-widget .category .cate-item a .post-count {
  margin-left: auto;
  align-items: flex-start;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background: #e4f2f8;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #00aeef;
  transition: all 0.3s linear;
}

.sidebar-widget .category .cate-item a:hover {
  color: #00aeef;
}

.sidebar-widget .category .cate-item a:hover .post-count {
  background: #00aeef;
  color: #fff;
}

.sidebar-widget .sidebar-tag li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 4px;
}

.sidebar-widget .sidebar-tag li a {
  display: inline-block;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 18px;
  background: #f9f9f9;
  transition: all 0.3s linear;
}

.sidebar-widget .sidebar-tag li a:hover {
  background-color: #00aeef;
  color: #fff;
}

.blog-details-section .blog-details-wrap {
  margin-top: -70px;
}

.blog-details-post .single-blog {
  margin-top: 70px;
  background: none;
  box-shadow: none;
}

.blog-details-post .single-blog .blog-content {
  padding-left: 0;
}

.blog-details-post .single-blog .blog-content .title {
  font-size: 24px;
  color: #333;
}

.blog-details-post .single-blog .blog-content p {
  font-size: 14px;
  line-height: 30px;
}

.blog-details-post .blog-details-content .blog-quote {
  background-color: none;
  position: relative;
}

.blog-details-post .blog-details-content .blog-quote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #00aeef;
  width: 2px;
  height: 90px;
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote::after {
    display: none;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote {
  padding-left: 60px;
  padding-right: 30px;
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote .blockquote {
    padding-right: 0;
    margin-left: 0;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote::before {
  content: "\f158";
  font-family: flaticon !important;
  font-size: 40px;
  line-height: 1;
  margin-right: 5px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #d4e4fa;
}

.blog-details-post
  .blog-details-content
  .blog-quote
  .blockquote:not(:first-child) {
  margin-top: 25px;
}

.blog-details-post
  .blog-details-content
  .blog-quote
  .blockquote:not(:last-child) {
  margin-bottom: 25px;
}

.blog-details-post .blog-details-content .blog-quote .blockquote p {
  display: inline;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  color: #333;
  line-height: 32px;
  font-weight: 600;
  margin-top: 0;
}

.blog-details-post .blog-details-content .blog-inner-img {
  padding-top: 15px;
}

.blog-details-post .blog-details-content .blog-inner-img .image {
  margin-top: 40px;
}

.blog-details-post .blog-details-content .blog-inner-img .image img {
  border-radius: 10px;
  width: 100%;
}

.blog-details-post .blog-details-content .blog-details-text p {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 50px;
}

.blog-details-post .blog-details-content .blog-details-tag-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e5;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-tag {
  padding-top: 20px;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-tag
  .sidebar-widget {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-tag
  .sidebar-widget
  .label {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  margin-right: 10px;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-tag
  .sidebar-widget
  .sidebar-tag
  li {
  margin-bottom: 0;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li {
  display: inline-block;
  margin-right: 10px;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li:last-child {
  margin-right: 0;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a {
  display: inline-block;
  font-size: 13px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a.share-twitter {
  background: #1da1f2;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a.share-facebook {
  background: #4867aa;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a.share-pinterest {
  background: #bd081b;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a.share-linkedin {
  background: #007bb6;
}

.blog-details-post
  .blog-details-content
  .blog-details-tag-share
  .blog-details-share
  li
  a:hover {
  opacity: 0.9;
}

.blog-details-post .blog-details-content .techmax-post-pagination {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 30px;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1379px) {
  .blog-details-post .blog-details-content .techmax-post-pagination {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .techmax-post-pagination {
    padding: 0 10px;
  }
}

.blog-details-post .blog-details-content .techmax-post-pagination::before {
  position: absolute;
  content: "";
  width: 1px;
  background-color: #e1e1e1;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-post .blog-details-content .techmax-post-pagination::before {
    width: auto;
    height: 1px;
    left: 30px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .previous-post,
.blog-details-post .blog-details-content .techmax-post-pagination .next-post {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .blog-details-post
    .blog-details-content
    .techmax-post-pagination
    .previous-post,
  .blog-details-post .blog-details-content .techmax-post-pagination .next-post {
    width: 100%;
  }
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination.single::before {
  display: none;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination.single
  .previous-post,
.blog-details-post
  .blog-details-content
  .techmax-post-pagination.single
  .next-post {
  width: 100%;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

@media only screen and (max-width: 1379px) {
  .blog-details-post
    .blog-details-content
    .techmax-post-pagination
    .blog-pagination-post {
    padding: 20px 0;
  }
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-thumb {
  flex-shrink: 0;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-thumb
  a
  img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-thumb
  a
  i {
  font-size: 14px;
  color: #00aeef;
  padding: 0 10px;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-content {
  flex-grow: 1;
  padding: 0 20px;
}

@media only screen and (max-width: 1379px) {
  .blog-details-post
    .blog-details-content
    .techmax-post-pagination
    .blog-pagination-post
    .post-content {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post
    .blog-details-content
    .techmax-post-pagination
    .blog-pagination-post
    .post-content {
    padding: 0 13px;
  }
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-content
  .title {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #333;
}

@media only screen and (max-width: 1379px) {
  .blog-details-post
    .blog-details-content
    .techmax-post-pagination
    .blog-pagination-post
    .post-content
    .title {
    font-size: 15px;
  }
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-content
  .date {
  font-size: 12px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #838383;
}

.blog-details-post
  .blog-details-content
  .techmax-post-pagination
  .blog-pagination-post
  .post-content
  .date
  i {
  color: #00aeef;
  margin-right: 6px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-box {
  margin-top: 50px;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-title {
  font-size: 20px;
  line-height: 30px;
  color: #333;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #e1e1e1;
}

@media only screen and (max-width: 575px) {
  .blog-details-post
    .blog-details-content
    .comment-wrap
    .comment-box
    .comment-items
    li
    .single-comment {
    display: block;
  }
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-author {
  flex-shrink: 0;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-author
  img {
  width: 75px;
  border-radius: 50%;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-content {
  flex-grow: 1;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .blog-details-post
    .blog-details-content
    .comment-wrap
    .comment-box
    .comment-items
    li
    .single-comment
    .comment-content {
    padding-left: 0;
    padding-top: 25px;
  }
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-content
  .name {
  font-size: 16px;
  font-weight: 700;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-content
  p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  margin-top: 5px;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-content
  .meta {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .single-comment
  .comment-content
  .meta
  .date {
  margin-right: 30px;
  color: #00aeef;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-box
  .comment-items
  li
  .comment-reply {
  padding-left: 105px;
}

@media only screen and (max-width: 767px) {
  .blog-details-post
    .blog-details-content
    .comment-wrap
    .comment-box
    .comment-items
    li
    .comment-reply {
    padding-left: 0;
  }
}

.blog-details-post .blog-details-content .comment-wrap .comment-form {
  margin-top: 50px;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-title {
  font-size: 20px;
  line-height: 30px;
  color: #333;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form p {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #686f7a;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap {
  padding-top: 10px;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form {
  margin-top: 20px;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  .form-control:focus {
  outline: none;
  border-color: #00aeef;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  textarea.form-control {
  height: 135px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  resize: none;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .single-form
  textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.blog-details-post
  .blog-details-content
  .comment-wrap
  .comment-form
  .comment-form-wrap
  .form-btn
  .btn {
  height: 40px;
  line-height: 36px;
  margin-top: 25px;
  font-size: 16px;
  padding: 0 40px;
}

.subscribe-section .subscribe-wrap {
  background: linear-gradient(-60deg, #54d6eb 0%, #035cdd 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 115px 50px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: -210px;
}

@media only screen and (max-width: 1379px) {
  .subscribe-section .subscribe-wrap {
    padding: 90px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .subscribe-section .subscribe-wrap {
    padding: 50px;
  }
}

.subscribe-section .subscribe-wrap .subscribe-title {
  margin-bottom: 40px;
}

.subscribe-section .subscribe-wrap .subscribe-title .title {
  font-size: 36px;
  line-height: 40px;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .subscribe-section .subscribe-wrap .subscribe-title .title {
    font-size: 30px;
  }
}

.subscribe-section .subscribe-wrap .subscribe-title p {
  font-size: 20px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .subscribe-section .subscribe-wrap .subscribe-title p {
    font-size: 18px;
  }
}

.subscribe-section .subscribe-wrap form {
  position: relative;
}

.subscribe-section
  .subscribe-wrap
  form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ) {
  height: 60px;
  line-height: 60px;
  border: 0;
  padding-right: 170px;
}

.subscribe-section
  .subscribe-wrap
  form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.subscribe-section
  .subscribe-wrap
  form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.subscribe-section
  .subscribe-wrap
  form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.subscribe-section
  .subscribe-wrap
  form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    ) {
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    ) {
    padding-right: 20px;
  }

  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    )::-webkit-input-placeholder {
    font-size: 16px;
  }

  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    ):-moz-placeholder {
    font-size: 16px;
  }

  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    )::-moz-placeholder {
    font-size: 16px;
  }

  .subscribe-section
    .subscribe-wrap
    form
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
      [type="file"]
    ):-ms-input-placeholder {
    font-size: 16px;
  }
}

.subscribe-section .subscribe-wrap form button {
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: #00aeef;
  color: #fff;
  padding: 0 40px;
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .subscribe-section .subscribe-wrap form button {
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .subscribe-section .subscribe-wrap form button {
    position: relative;
    width: 100%;
    display: block;
    margin-top: 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.faq-section .faq-wrap .faq-left {
  display: flex;
}

.faq-section .faq-wrap .faq-left .title {
  font-size: 48px;
  line-height: 60px;
  max-width: 250px;
  color: #333;
  margin-left: 40px;
}

@media only screen and (max-width: 1379px) {
  .faq-section .faq-wrap .faq-left .title {
    font-size: 40px;
    line-height: 55px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-section .faq-wrap .faq-left .title {
    font-size: 30px;
    line-height: 40px;
  }
}

.faq-section .faq-wrap .faq-accordion {
  padding-left: 85px;
}

@media only screen and (max-width: 1379px) {
  .faq-section .faq-wrap .faq-accordion {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .faq-section .faq-wrap .faq-accordion {
    margin-top: 50px;
  }
}

.faq-section .faq-wrap .faq-accordion .accordion-item {
  border: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.faq-section .faq-wrap .faq-accordion .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item:first-of-type
  .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.faq-section .faq-wrap .faq-accordion .accordion-item .accordion-header {
  padding-top: 20px;
  padding-bottom: 30px;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header.header-one {
  padding-top: 0;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  padding: 0;
  font-size: 22px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  color: #333;
  font-weight: 700;
  padding-right: 45px;
  position: relative;
  transition: all 0.3s linear;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button::after {
  /* display: none; */
  color: #00aeef;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .number {
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 50%;
  margin-right: 20px;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .faq-button {
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
  color: #00aeef;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  transition: all 0.3s linear;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .faq-button
  i {
  font-size: 15px;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  border: 0;
  box-shadow: none;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  color: #00aeef;
  background: #fff;
  box-shadow: none;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)
  .faq-button {
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
  color: #00aeef;
  text-align: center;
  transform: rotate(-90deg);
  transition: all 0.3s linear;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)
  .faq-button
  i {
  font-size: 15px;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button:hover {
  color: #00aeef;
}

/* .faq-section .faq-wrap .faq-accordion .accordion-item .accordion-body {
  padding: 0;
  padding-bottom: 20px;
  padding-right: 50px;
} */
.faq-section .faq-wrap .faq-accordion .accordion-item .accordion-body {
  padding: 0;
  padding-bottom: 20px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 30px;
  color: #4c4d56;
}

.faq-section .faq-wrap .faq-accordion .accordion-item .accordion-body p {
  font-size: 16px;
  line-height: 30px;
  color: #4c4d56;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item:hover
  .accordion-button
  .faq-button {
  transform: rotate(-90deg);
}

.overview-section .overview-wrap .overview-img .image {
  animation: round-01 5s linear infinite;
}

.overview-section .overview-wrap .overview-content {
  padding-left: 52px;
  padding-top: 30px;
}

@media only screen and (max-width: 1379px) {
  .overview-section .overview-wrap .overview-content {
    padding-left: 50px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 991px) {
  .overview-section .overview-wrap .overview-content {
    padding-left: 0;
    /* margin-top: 60px */
  }
}

.overview-section .overview-wrap .overview-content .section-title .title {
  font-size: 48px;
  line-height: 60px;
}

@media only screen and (max-width: 1379px) {
  .overview-section .overview-wrap .overview-content .section-title .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .overview-section .overview-wrap .overview-content .section-title .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.overview-section .overview-wrap .overview-content .overview-list {
  padding-top: 40px;
}

.overview-section .overview-wrap .overview-content .overview-list ul li {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
}

.overview-section
  .overview-wrap
  .overview-content
  .overview-list
  ul
  li
  .overview-icon {
  color: #5149f3;
  margin-right: 8px;
}

.overview-section .overview-wrap .overview-content .overview-list ul li + li {
  margin-top: 15px;
}

.overview-section .overview-wrap .overview-content .btn {
  margin-top: 50px;
}

@keyframes round-01 {
  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

.overview-section-2 .overview-wrap .overview-content {
  padding-right: 50px;
  padding-top: 65px;
}

@media only screen and (max-width: 1399px) {
  .overview-section-2 .overview-wrap .overview-content {
    padding-top: 50px;
    padding-right: 30px;
  }
}

.overview-section-2 .overview-wrap .overview-content .section-title .title {
  font-size: 48px;
  line-height: 60px;
}

.overview-section-2 .overview-wrap .overview-content .text {
  font-size: 16px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
  margin-top: 40px;
  padding-right: 115px;
}

.overview-section-2 .overview-wrap .overview-content .counter-wrap {
  display: flex;
  padding-top: 50px;
}

.overview-section-2
  .overview-wrap
  .overview-content
  .counter-wrap
  .counter-item:last-child {
  margin-left: 95px;
}

.overview-section-2
  .overview-wrap
  .overview-content
  .counter-wrap
  .counter-item
  span {
  font-size: 50px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #00aeef;
}

.overview-section-2
  .overview-wrap
  .overview-content
  .counter-wrap
  .counter-item
  p {
  font-size: 14px;
  line-height: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #4c4d56;
}

.overview-section-2 .overview-wrap .overview-img {
  padding: 0 25px;
  position: relative;
  z-index: 1;
}

.overview-section-2 .overview-wrap .overview-img .shape {
  position: absolute;
  z-index: -1;
  animation: round-01 5s linear infinite;
}

@media only screen and (max-width: 1379px) {
  .overview-section-2 .overview-wrap .overview-content {
    padding-top: 0;
    padding-right: 50px;
  }

  .overview-section-2 .overview-wrap .overview-content .section-title .title {
    font-size: 36px;
    line-height: 48px;
  }

  .overview-section-2 .overview-wrap .overview-content .text {
    padding-right: 0;
  }

  .overview-section-2 .overview-wrap .overview-content .counter-wrap {
    padding-top: 40px;
  }

  .overview-section-2 .overview-wrap .overview-img {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 991px) {
  .overview-section-2 .overview-wrap .overview-img {
    padding: 0 40px;
    margin-top: 60px;
  }

  .overview-section-2 .overview-wrap .overview-img .shape {
    left: 90px;
    top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .overview-section-2 .overview-wrap .overview-content .section-title .title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .overview-section-2 .overview-wrap .overview-img {
    padding: 0 20px;
  }
}

.login-register-section .login-register-wrap {
  margin-top: -50px;
}

.login-register-section .login-register-wrap .section-title .title {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
  margin-top: -8px;
}

.login-register-section .login-register-wrap .login-register-box {
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  margin-top: 50px;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form {
  margin-top: 20px;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-control:focus {
  outline: none;
  border-color: #00aeef;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  textarea.form-control {
  height: 135px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  padding-top: 15px;
  resize: none;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-check-input {
  margin-right: 8px;
  margin-top: 5px;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-check-input:focus {
  box-shadow: none;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .single-form
  .form-check-label {
  display: inline;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0;
  text-transform: none;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .form-btn {
  margin-top: 30px;
}

.login-register-section
  .login-register-wrap
  .login-register-box
  .login-register-form
  .form-btn
  .btn {
  height: 50px;
  line-height: 45px;
  width: 100%;
}

.sidebar-wrap {
  border-left: 1px solid #eee;
  height: 100%;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrap {
    border-left: none;
    height: auto;
    padding-top: 0;
  }
}

.widget {
  margin-bottom: 40px;
  color: #4c4d56;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget .widget-title {
  border-bottom: 1px solid #eee;
  padding-left: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .widget .widget-title {
    padding-left: 0;
  }
}

.footer-widget-wrap {
  padding-top: 190px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 1379px) {
  .footer-widget-wrap {
    padding-top: 150px;
    padding-bottom: 60px;
  }
}

.footer-widget {
  margin-top: 30px;
}

.footer-widget.footer-widget-info {
  background: #1f2428;
  padding: 40px 30px;
  border-radius: 5px;
}

.footer-widget.footer-widget-1 {
  padding-left: 30px;
}

@media only screen and (max-width: 1379px) {
  .footer-widget.footer-widget-1 {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-widget.footer-widget-1 {
    padding-left: 0;
  }
}

.widget-info {
  padding-top: 20px;
}

.widget-info ul li {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.widget-info ul li .info-icon i {
  display: inline-block;
  color: #00aeef;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}

.widget-info ul li .info-icon svg {
  display: inline-block;
  color: #00aeef;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}

.widget-info ul li .info-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #c4c5c7;
}

.footer-widget .footer-widget-title {
  font-size: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #fff;
}

.widget-link {
  padding-top: 22px;
}

.widget-link .link li {
  margin-top: 7px;
}

.widget-link .link li a {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #c4c5c7;
  transition: all 0.3s linear;
}

.widget-link .link li a:hover {
  color: #00aeef;
  padding-left: 10px;
}

.footer-widget-wrap-2 {
  padding-top: 80px;
}

@media only screen and (max-width: 1379px) {
  .footer-widget-wrap-2 {
    padding-top: 60px;
  }
}

.widget-info-2 ul li .info-icon i {
  color: #5149f3;
}

.widget-info-2 ul li .info-text {
  color: #666;
}

.widget-info-2 ul li .info-text a:hover {
  color: #5149f3;
}

.footer-widget-2 .footer-widget-title {
  color: #5149f3;
}

.footer-widget-2 .widget-link .link li a {
  color: #666;
}

.footer-widget-2 .widget-link .link li a:hover {
  color: #5149f3;
  padding-left: 10px;
}

.footer-widget-wrap-3 {
  padding-top: 80px;
}

.widget-info-3 ul li .info-icon i {
  color: #ff5538;
}

.widget-info-3 ul li .info-text {
  color: #666;
}

.widget-info-3 ul li .info-text a:hover {
  color: #ff5538;
}

.footer-widget-3 .footer-widget-title {
  color: #ff5538;
}

.footer-widget-3 .widget-link .link li a {
  color: #666;
}

.footer-widget-3 .widget-link .link li a:hover {
  color: #ff5538;
  padding-left: 10px;
}

.footer-widget-wrap-3 {
  padding-top: 290px;
}

@media only screen and (max-width: 767px) {
  .footer-widget-wrap-3 {
    padding-top: 270px;
  }
}

.footer-section {
  /* background-image: url(../images/bg/footer-bg.jpeg) !important; */
  /* background: #000c29; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  position: relative;
  width: 100%;
  overflow: hidden;
}
.footer-section-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.footer-logo {
  max-width: 170px;
}

.footer-copyright-area {
  border-top: 1px solid #1a253f;
  padding-top: 15px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-copyright-wrap .row {
    flex-direction: column-reverse;
  }

  .footer-copyright-wrap .row > div + div {
    margin-bottom: 15px;
  }
}

.copyright-text {
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    text-align: center;
  }
}

.copyright-text p {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #c4c5c7;
}

.copyright-social {
  margin-top: 15px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .copyright-social {
    text-align: center;
  }
}

.copyright-social .social li {
  display: inline-block;
  margin-right: 10px;
}

.copyright-social .social li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background: #15203b;
  font-size: 18px;
  color: #fff;
  transition: all 0.3s linear;
}

.copyright-social .social li a:hover {
  color: #00aeef;
}

/* .footer-section-2 {
  background: #f8f8fb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.footer-copyright-2 {
  border-top: 1px solid #c6c6c9;
}

.footer-copyright-2 .copyright-text p {
  color: #666;
}

.footer-copyright-2 .copyright-text p a {
  font-weight: 700;
  color: #5149f3;
}

.footer-copyright-2 .copyright-social .social li a {
  background: #5149f3;
  color: #fff;
  transition: all 0.3s linear;
}

.footer-copyright-2 .copyright-social .social li a:hover {
  background: #00aeef;
  color: #fff;
}

/* .footer-section-3 {
  background: #f8f8fb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.footer-copyright-3 {
  border-top: 1px solid #c6c6c9;
}

.footer-copyright-3 .copyright-text p {
  color: #666;
}

.footer-copyright-3 .copyright-text p a {
  font-weight: 700;
  color: #ff5538;
}

.footer-copyright-3 .copyright-social .social li a {
  background: #ff5538;
  color: #fff;
  transition: all 0.3s linear;
}

.footer-copyright-3 .copyright-social .social li a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 93, 224, 0.2);
  opacity: 0;
  visibility: hidden;
  transform: translateX(130%);
  z-index: 99;
  transition: all 200ms linear;
}

.progress-wrap::after {
  position: absolute;
  content: "\f108";
  font-family: flaticon !important;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
  color: #00aeef;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  transform: rotate(90deg);
  cursor: pointer;
  display: block;
  z-index: 1;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #00aeef;
  stroke-width: 4;
  box-sizing: border-box;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.progress-wrap-3 {
  box-shadow: inset 0 0 0 2px rgba(255, 85, 56, 0.2);
}

.progress-wrap-3::after {
  color: #ff5538;
}

.progress-wrap-3 svg.progress-circle path {
  stroke: #ff5538;
}

.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .title
  a:hover,
.service-section-4
  .service-wrap-4
  .service-right
  .service-item
  .service-content
  .read-more {
  color: #00aeef;
}

.page-banner-section .shape-2.sh2-rt {
  left: auto;
  right: -10%;
}

.page-banner-section::before {
  opacity: 0.12;
}

.faq-section .faq-wrap .faq-accordion .accordion-item .accordion-header {
  padding: 0;
}

.faq-section
  .faq-wrap
  .faq-accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  padding-top: 20px;
  padding-bottom: 30px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content
  .title {
  font-size: 20px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content
  span {
  font-size: 16px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-img {
  padding: 15px;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-img
  a
  img {
  border: 1px solid #efefef;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item
  .case-study-content {
  padding: 0 15px 49px;
  margin-top: 0;
}

.case-study-section-2 {
  background-color: transparent;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  .case-study-item {
  margin-top: 0;
}

.contact-form-section .contact-wrap .form-title .title {
  font-size: 26px;
  line-height: 32px;
}

.contact-form-section .contact-wrap .contact-form-wrap {
  padding-top: 15px;
}

.features-section-3 .features-wrap-3 .features-content-wrap .single-item {
  padding: 15px;
  padding-bottom: 35px;
  flex: 1;
}

.custom-contact-list-info {
  margin-bottom: 60px;
  margin-top: 50px;
}

.custom-contact-list-info li {
  margin-top: 25px;
}

.custom-contact-list-info li .info-icon {
  margin-right: 10px;
}

.custom-contact-list-info li .info-text {
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .custom-contact-list-info {
    text-align: center;
    margin-top: 0;
  }

  .custom-contact-list-info li .d-flex {
    justify-content: center;
  }

  .custom-contact-list-info li:first-child {
    margin-top: 0;
  }
}

.icon-download {
  position: relative;
}

.icon-download:after {
  content: "";
  background: url(../images/downloads/download-icon.png) no-repeat center top;
  position: absolute;
  bottom: 20px;
  right: 25px;
  display: block;
  width: 30px;
  height: 43px;
  background-size: 100%;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content {
  max-width: none;
}

.testimonial-section
  .testimonial-wrap
  .testimonial-slider-wrap
  .single-testimonial
  .testimonial-content
  p {
  text-decoration: none;
}

.testimonial-active .testimonial-arrow.swiper-button-prev::after,
.testimonial-active .testimonial-arrow.swiper-button-next::after {
  color: #00aeef;
}

.testimonial-active .testimonial-arrow.swiper-button-prev:hover::after,
.testimonial-active .testimonial-arrow.swiper-button-next:hover::after {
  color: #fff;
}

select.form-control.form-select {
  appearance: auto;
}

.form-control {
  border-radius: 4px !important;
}

.blog-section .single-blog .blog-content {
  padding: 20px;
}

.blog-section .single-blog .blog-content .title {
  margin: 0;
}

.blog-section .single-blog .blog-btn {
  padding: 15px 20px;
}

ol.custom-list {
  list-style: decimal;
  padding-left: 25px;
}

.section-title2 .sub-title {
  font-size: 20px;
  color: #8d8d8d;
}

.section-title2 h3.title {
  font-size: 40px;
  line-height: 46px;
}

.features-section-6
  .features-wrap-3
  .features-content-wrap.feature-association
  .single-item {
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .features-section-6
    .features-wrap-3
    .features-content-wrap.feature-association
    .single-item {
    flex-direction: column;
    text-align: center;
  }

  div.language_es {
    height: 27vh !important;
    text-align: justify !important;
    padding: 0px;
  }
}

.section-gallery .skill-img-item .skill-gal-list {
  display: none;
}

.section-gallery.skill-section-5 .skill-img-wrap {
  margin-top: 0;
}

.section-gallery.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text {
  bottom: 12px;
}

.section-gallery.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .image::before {
  opacity: 0.9;
  height: 46%;
}

.section-gallery.skill-section-5
  .skill-img-wrap
  .skill-img-item
  .skill-img
  .skill-img-text
  .title {
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0 2px 4px #000;
}

.tech-hero-section-6 .hero-content .sub-title {
  font-weight: 400;
  font-size: 18px;
  text-transform: none;
  margin-top: 5px;
}

.tech-hero-section-6 .hero-images.sk::before {
  background-repeat: no-repeat;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-content {
  justify-content: center;
  padding: 25px;
  border-radius: 20px;
}

.service-section-2
  .service-wrap-2
  .service-content-wrap-2
  .service-item
  .service-content
  .read-more {
  margin-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1379px) {
  .testimonial-section-6 .testimonial-wrap .testimonial-img::before {
    transform: translate(75px, 80px);
  }

  .testimonial-section-6 .testimonial-wrap .testimonial-img::after {
    transform: translate(75px, 80px) scale(1.23);
  }
}

.error {
  color: red;
  font-size: 0.9em;
}

.success {
  color: green;
  font-size: 1.9em;
  font-weight: 600;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-2-active
    .swiper-button-prev {
    left: -10px;
  }

  div.language_es {
    height: 27vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-2-active
    .swiper-button-next {
    right: -10px;
  }

  .features-wrap.it-solutions {
    text-align: center;
  }

  .footer-widget-about {
    text-align: center;
  }

  .footer-widget {
    margin-top: 30px;
    margin-left: 23px !important;
  }

  .widget-info ul li {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 10px;
    justify-content: center;
  }

  input#phone-foot {
    width: 343px !important;
  }

  .modal-content.download {
    /* width: 75% !important;
    margin-left: 65px !important;
    margin-top: 24px !important; */
  }
}

.preloader-main .preloader {
  transform: translate(-50%, -50%);
}

.c404 {
  font-size: 175px;
  font-weight: 600;
  color: #00aeef;
  line-height: 1;
  letter-spacing: 0;
}

.c404 .c0 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fbc213;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 -30px;
}

@media only screen and (max-width: 768px) {
  .c404 {
    font-size: 120px;
  }

  div.language_es {
    height: 32vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .c404 .c0 {
    width: 100px;
    height: 100px;
    margin: 0 -20px;
  }
}

#homeModal .modal-body {
  padding: 0;
  position: relative;
}

#homeModal .modal-body .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  color: #f0d13c;
  font-size: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  z-index: 3;
  font-weight: 550;
}

#homeModal .modal-content {
  overflow: hidden;
  border: 0;
  border-radius: 8px;
  background-color: transparent;
}

.modal .btn-close:hover {
  opacity: 1;
}

.policy-wrap h3.title {
  font-size: 22px;
  margin: 25px 0 5px;
}

.progress-wrap {
  display: none;
}

.case-study-section-2 .case-study-wrap .case-study-content-wrap > .row {
  justify-content: center;
}

.case-study-section-2
  .case-study-wrap
  .case-study-content-wrap
  > .row
  > div[class^="col"] {
  justify-content: center;
  margin-bottom: 15px;
}

select:invalid {
  color: #9a9aa3;
}

p.text {
  text-align: justify;
  font-size: 16px !important;
  line-height: 30px;
  /* font-family: "Manrope", sans-serif; */
  font-weight: 400;
  color: #4c4d56;
  /* margin-top: 30px; */
  /* padding-bottom: 20px; */
  text-align: justify;
}

.links {
  border: 1px solid transparent;
  padding: 20px;
  border-radius: 13px;
  margin-top: 14px;
  text-align: center;
  font-size: 16px;
  color: #49aeef;
  box-shadow: #000 0 10px 20px, #000 0 6px 6px;
}

@media only screen and (min-width: 300px) and (max-width: 320px) {
  .head-sec {
    width: 303px !important;
    margin-left: 0px !important;
    padding: 0px !important;
  }

  div.language_es {
    height: 39vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .head-sec p span {
    font-size: 21px !important;
  }

  button.btn-close.download {
    margin-top: 3px !important;
    margin-left: -7px !important;
  }

  /* .tech-hero-section-6 .hero-content {
        /* margin-top: -66px; 
    margin-top: -246px !important;
}

    */
  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    /* margin-top: 72px; */
    margin-top: -259px !important;
  }
}

@media only screen and (min-width: 390px) and (max-width: 395px) {
  .retail {
    width: 48% !important;
    margin-left: 86px !important;
  }

  div.language_es {
    height: 31vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  input#phone-foot {
    width: 319px !important;
  }

  .head-sec.download {
    width: 351px !important;
  }

  .modal-dialog.contact {
    margin-left: 0px !important;
  }

  .modal-content.download {
    /* width: 93% !important;
    margin-left: 16px !important;
    margin-top: 24px !important; */
  }

  .head-sec {
    width: 356px !important;
  }

  .section.Brand-section {
    height: 85vh !important;
  }

  input#phones {
    width: 325px !important;
  }

  input#phonenum {
    width: 94vw !important;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }

  .digital {
    width: 53% !important;
    margin-left: 82px !important;
  }

  .scan-n-play {
    width: 47% !important;
    margin-left: 101px !important;
  }

  .paper-lotto {
    margin-left: 79px !important;
    width: 180px !important;
  }

  .LRS {
    margin-left: 138px !important;
  }

  .instant-lotto {
    width: 89% !important;
    margin-left: 20px !important;
  }

  .cashless {
    width: 297px !important;
    margin-left: 29px !important;
  }

  .top-left {
    left: 0px;
    margin-left: 0px !important;
    font-size: 12px !important;
  }

  /* .sport-lotto {
      width: 59% !important;
      margin-left: 71px !important;
    } */

  .turnkey-solu {
    width: 88% !important;
    margin-left: 28px !important;
  }

  input#phone-foot {
    width: 318px !important;
  }

  h2.title.abour-recogn {
    text-align: left !important;
  }
}

@media only screen and (min-width: 383px) and (max-width: 389px) {
  .retail {
    width: 48% !important;
    margin-left: 86px !important;
  }

  div.language_es {
    height: 27vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .modal-dialog.contact {
    margin-left: 0px !important;
  }

  .modal-content.download {
    /* width: 93% !important; */
    /* margin-left: 16px !important; */
    /* margin-top: 24px !important; */
  }

  .head-sec {
    width: 341px !important;
  }

  .section.Brand-section {
    height: 85vh !important;
  }

  input#phones {
    width: 322px !important;
  }

  input#phonenum {
    width: 94vw !important;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }

  .digital {
    width: 53% !important;
    margin-left: 82px !important;
  }

  .scan-n-play {
    width: 47% !important;
    margin-left: 101px !important;
  }

  .paper-lotto {
    margin-left: 79px !important;
    width: 180px !important;
  }

  .LRS {
    margin-left: 138px !important;
  }

  .instant-lotto {
    width: 89% !important;
    margin-left: 20px !important;
  }

  .cashless {
    width: 297px !important;
    margin-left: 29px !important;
  }

  .top-left {
    left: 0px;
    margin-left: 0px !important;
    font-size: 12px !important;
  }

  /* .sport-lotto {
      width: 59% !important;
      margin-left: 71px !important;
    } */

  .turnkey-solu {
    width: 88% !important;
    margin-left: 28px !important;
  }

  input#phone-foot {
    width: 311px !important;
  }

  h2.title.abour-recogn {
    text-align: left !important;
  }
}

@media only screen and (min-width: 371px) and (max-width: 382px) {
  .retail {
    width: 48% !important;
    margin-left: 86px !important;
  }

  div.language_es {
    height: 32vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .modal-dialog.contact {
    margin-left: -75px !important;
  }

  /* .modal-content.download {
        width: 93% !important;
        margin-left: 16px !important;
        margin-top: 24px !important;
    } */
  input#phones {
    width: 300px !important;
  }

  .head-sec {
    width: 331px !important;
  }

  .head-sec.download {
    width: 270px !important;
  }

  .section.Brand-section {
    height: 85vh !important;
  }

  /* input#phones {
        width: 88vw !important;
    } */

  input#phonenum {
    width: 94vw !important;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }

  .digital {
    width: 53% !important;
    margin-left: 82px !important;
  }

  .scan-n-play {
    width: 47% !important;
    margin-left: 101px !important;
  }

  .paper-lotto {
    margin-left: 79px !important;
    width: 180px !important;
  }

  .LRS {
    margin-left: 138px !important;
  }

  .instant-lotto {
    width: 89% !important;
    margin-left: 20px !important;
  }

  .cashless {
    width: 297px !important;
    margin-left: 29px !important;
  }

  .top-left {
    left: 0px;
    margin-left: 0px !important;
    font-size: 12px !important;
  }

  /* .sport-lotto {
      width: 59% !important;
      margin-left: 71px !important;
    } */

  .turnkey-solu {
    width: 88% !important;
    margin-left: 28px !important;
  }

  input#phone-foot {
    width: 238px !important;
  }

  h2.title.abour-recogn {
    text-align: left !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 370px) {
  .modal-dialog.download {
    top: 0px !important;
    position: fixed !important;
    float: right !important;
    right: -1px !important;
    margin-top: 65px !important;
  }

  div.language_es {
    height: 34vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  .head-sec.download {
    width: 257px !important;
  }

  .retail {
    width: 48% !important;
    margin-left: 86px !important;
  }

  .modal-dialog.contact {
    margin-left: -75px !important;
  }

  /* .modal-content.download {
        width: 93% !important;
        margin-left: 16px !important;
        margin-top: 24px !important;
    } */
  input#phones {
    width: 288px !important;
  }

  .head-sec {
    width: 319px !important;
  }

  .section.Brand-section {
    height: 85vh !important;
  }

  /* input#phones {
        width: 88vw !important;
    } */

  input#phonenum {
    width: 94vw !important;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }

  .digital {
    width: 53% !important;
    margin-left: 82px !important;
  }

  .scan-n-play {
    width: 47% !important;
    margin-left: 101px !important;
  }

  .paper-lotto {
    margin-left: 79px !important;
    width: 180px !important;
  }

  .LRS {
    margin-left: 138px !important;
  }

  .instant-lotto {
    width: 89% !important;
    margin-left: 20px !important;
  }

  .cashless {
    width: 297px !important;
    margin-left: 29px !important;
  }

  .top-left {
    left: 0px;
    margin-left: 0px !important;
    font-size: 12px !important;
  }

  /* .sport-lotto {
      width: 59% !important;
      margin-left: 71px !important;
    } */

  .turnkey-solu {
    width: 88% !important;
    margin-left: 28px !important;
  }

  input#phone-foot {
    width: 227px !important;
  }

  h2.title.abour-recogn {
    text-align: left !important;
  }
}

/*------------------------- Banner Strip Code --------------------------------*/
.top-left {
  position: absolute;
  left: 16px;
  z-index: 100;
  margin-top: -34px;
  color: white;
  font-size: 17px;
  margin-left: 107px;
}

.breadcrumb-item.active {
  color: white !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left !important;
  padding-right: 0.5rem !important;
  color: white !important;
  content: ">" !important;
}

.page-banner-wrap {
  position: relative;
  /* height: 35px; */
  align-items: center;
}

.breadcrum1 {
  margin-top: 5px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.breadcrum1 img {
  width: 100vw;
  height: 40px;
}

/*h2.title.main-ban-head {
    margin-top: 75px;
}



.breadcrum1 img {
    height: 5vh;
}

.breadcrum1 {
    margin-top: -104px !important;
}

*/
@media only screen and (min-width: 812px) and (max-width: 900px) {
  .images img {
    max-width: 45vw !important;
  }

  div.language_es {
    height: 27vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  input#phone-foot {
    width: 260px !important;
  }

  .carousel-control-next-icon.solu {
    margin-left: -3rem !important;
  }

  .carousel-control-next-icon.solu,
  .carousel-control-prev-icon.solu {
    width: 1.5rem !important;
    height: 2rem !important;
  }

  #winner-award {
    max-width: 100vw !important;
    width: 20vw !important;
  }

  .top-left {
    margin-left: 47px !important;
  }

  .breadcrum1 img {
    width: 100vw;
    height: 40px;
    max-width: 100vw !important;
  }

  .title.pro-sol {
    font-size: 20px !important;
    display: table;
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 8px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 6px !important;
  }

  .tech-hero-section-6 .hero-content {
    margin-top: -37px !important;
  }

  .section.Brand-section {
    height: 26vh !important;
  }

  .footer-logo img {
    width: 146px !important;
  }

  .info-text {
    font-size: 12px !important;
  }

  .widget-link .link li a {
    font-size: 13px !important;
    margin-left: 3px !important;
  }

  .title.aos-init.aos-animate b {
    font-size: 26px !important;
  }

  #interview {
    padding: 0 28px !important;
  }
}

@media only screen and (max-width: 1150px) {
  /* .page-banner-section {
    padding-top: 60px;
  } */
}

@media only screen and (max-width: 991px) {
  section.Brand-section {
    /* height: 26vh !important; */
  }

  #phones {
    width: 32vw !important;
  }

  .top-left {
    margin-left: 47px;
  }

  .faq-section
    .faq-wrap
    .faq-accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    font-size: 16px !important;
  }

  /* .breadcrum1 img {
      width: 100vw;
      height: 40px;
      max-width: 100vw !important;
    } */
}

@media only screen and (min-width: 1500px) and (max-width: 1680px) {
  .top-left {
    margin-top: -34px;
  }

  /* div.language_es {
        height: 33vh !important;
    } */

  #phone-foot {
    width: 337px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 3800px) and (max-width: 3840px) {
  /* .modal-dialog.contact {
        margin-left: 208pc !important;
        margin-top: 14pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  input#phones {
    width: 9vw !important;
  }

  .head-sec {
    padding: 3px 0px !important;
    width: 365px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 9px !important;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2560px) {
  /* .modal-dialog.contact {
        margin-left: 127pc !important;
        margin-top: 14pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */
  #phone-foot {
    width: 333px !important;
  }

  input#phones {
    width: 13vw !important;
  }

  .head-sec {
    padding: 3px 0px !important;
    width: 365px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 9px !important;
  }
}

@media only screen and (min-width: 1660px) and (max-width: 1690px) {
  /* .modal-dialog.contact {
        margin-left: 75pc !important;
        margin-top: 23pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  input#phones {
    width: 20vw !important;
  }

  .head-sec {
    padding: 7px 0px !important;
    width: 366px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 57% !important;
    height: 19vh !important;
  }

  .sticky-button label {
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 1590px) and (max-width: 1600px) {
  /* .modal-dialog.contact {
        margin-left: 69pc !important;
        margin-top: 15pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  input#phones {
    width: 21vw !important;
  }

  .head-sec {
    padding: 3px 0px !important;
    width: 365px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 367px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 56% !important;
    height: 19vh !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .section-padding-03 {
    padding-top: 26px !important;
  }

  /* .hero-images.sk img {
        width: 80% !important;
    }

    .hero-images.sk1 img {
        width: 83.5% !important;
    }

    .hero-images.sk2 img {
        width: 83.5% !important;
    } */

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .sticky-button label {
    font-size: 16px !important;
  }
}

.modal-content.download {
  width: 367px !important;
  padding-bottom: 9px !important;
}

@media only screen and (min-width: 1500px) and (max-width: 1580px) {
  .section-padding-03 {
    padding-top: 37px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .head-sec {
    padding: 7px 0px !important;
    width: 367px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  input#phones {
    width: 22vw !important;
  }

  .head-sec {
    padding: 7px 0px !important;
    width: 367px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  /* .modal-content.download {
    border-radius: 44px 29px 0px 0px !important;
    width: 367px !important;
    padding-bottom: 9px !important;
  } */

  .sticky-button {
    top: 55% !important;
    height: 22vh !important;
  }
}

@media only screen and (min-width: 1460px) and (max-width: 1490px) {
  #phone-foot {
    width: 260px !important;
  }

  .head-sec.download {
    width: 288px !important;
  }
}

@media only screen and (min-width: 1410px) and (max-width: 1450px) {
  /* .modal-dialog.contact {
        margin-left: 65pc !important;
        margin-top: 14.7pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */
  #phone-foot {
    width: 260px !important;
  }

  button.btn {
    font-size: 14px;
  }

  input#phones {
    width: 18vw !important;
  }

  .head-sec {
    padding: 5px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 56% !important;
    height: 19vh !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  /* .hero-images.sk img {
        width: 80% !important;
    }

    .hero-images.sk1 img {
        width: 83.5% !important;
    }

    .hero-images.sk2 img {
        width: 83.5% !important;
    } */
  .section-padding-03 {
    padding-top: 35px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .sticky-button label {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1390px) and (max-width: 1405px) {
  /* .modal-dialog.contact {
        margin-left: 65pc !important;
        margin-top: 24pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  button.btn {
    font-size: 14px;
  }

  input#phones {
    width: 18.4vw !important;
  }

  .head-sec {
    padding: 5px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 56% !important;
    height: 20vh !important;
  }

  .sticky-button label {
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1370px) {
  input#phone-foot {
    width: 258px !important;
  }

  input#phones {
    width: 19vw !important;
  }

  .head-sec {
    padding: 4px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 58% !important;
    height: 21vh !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 45px !important;
    padding-bottom: 52px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 47px !important;
    padding-bottom: 50px !important;
  }

  .hero-images.sk img {
    width: 100% !important;
  }

  .hero-images.sk1 img {
    width: 100% !important;
  }

  .hero-images.sk2 img {
    width: 100% !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 1210px) and (max-width: 1258px) {
  /* .modal-dialog.contact {
        margin-left: 57pc !important;
        margin-top: 4pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 21vw !important;
  }

  .head-sec {
    padding: 5px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 18px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 34% !important;
    height: 16vh !important;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1280px) {
  /* div.language_es {
        height: 39vh !important;
    } */

  /* .modal-dialog.contact {
        margin-left: 57pc !important;
        margin-top: 7.5pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 21vw !important;
  }

  .head-sec {
    padding: 5px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 18px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 53% !important;
    height: 23vh !important;
  }

  .sticky-button label {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1160px) and (max-width: 1205px) {
  /* .modal-dialog.contact {
        margin-left: 57pc !important;
        margin-top: 4pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 21vw !important;
  }

  .head-sec {
    padding: 5px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 18px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 34% !important;
    height: 16vh !important;
  }
}

@media only screen and (min-width: 1341px) and (max-width: 1349px) {
  .head-sec.download {
    width: 256px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1340px) {
  .head-sec.download {
    width: 288px !important;
  }
}

@media only screen and (min-width: 1160px) and (max-width: 1199px) {
  #phone-foot {
    width: 261px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .hero-images.sk img {
    width: 81% !important;
  }

  .hero-images.sk1 img {
    width: 74% !important;
  }

  .hero-images.sk2 img {
    width: 85% !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1155px) and (max-width: 1159px) {
  .head-sec.download {
    width: 288px !important;
  }

  #phone-foot {
    width: 259px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .hero-images.sk img {
    width: 81% !important;
  }

  .hero-images.sk1 img {
    width: 74% !important;
  }

  .hero-images.sk2 img {
    width: 85% !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1142px) and (max-width: 1154px) {
  input#phone-foot {
    width: 258px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 52px !important;
    padding-bottom: 63px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 47px !important;
    padding-bottom: 50px !important;
  }

  .hero-images.sk img {
    width: 48vw !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }

  .hero-images.sk1 img {
    width: 50vw !important;
  }

  .hero-images.sk2 img {
    width: 46vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  /* .modal-dialog.contact {
        margin-left: 47pc !important;
        margin-top: 4pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 23vw !important;
  }

  .head-sec {
    padding: 2px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .sticky-button {
    top: 58% !important;
    height: 19vh !important;
  }

  .head-sec p span {
    font-size: 25px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1140px) {
  input#phone-foot {
    width: 254px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .hero-images.sk img {
    width: 81% !important;
  }

  .hero-images.sk1 img {
    width: 74% !important;
  }

  .hero-images.sk2 img {
    width: 85% !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  /* .modal-dialog.contact {
        margin-left: 47pc !important;
        margin-top: 12pc !important;
    } */

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 23vw !important;
  }

  .head-sec {
    padding: 2px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 25px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 32% !important;
    height: 16vh !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1090px) {
  /* .modal-dialog.contact {
        margin-left: 38pc !important;
        margin-top: 0pc !important;
    } */
  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 53px !important;
    padding-bottom: 58px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 25px !important;
    padding-bottom: 24px !important;
  }

  .hero-images.sk img {
    width: 49vw !important;
  }

  .hero-images.sk1 img {
    width: 45vw !important;
  }

  .hero-images.sk2 img {
    width: 53vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  button.btn-close.download {
    margin-top: 18px !important;
    margin-left: -16px !important;
  }

  /* div#contactModal {
        overflow-y: hidden !important;
    } */

  /* button.btn {
        font-size: 14px;
    } */

  input#phones {
    width: 25vw !important;
  }

  .head-sec {
    padding: 4px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 32% !important;
    height: 21vh !important;
  }

  .header-menu .main-menu li a {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 790px) and (max-width: 810px) {
  input#phones {
    width: 32vw !important;
  }

  div.language_es {
    height: 27vh !important;
    text-align: justify !important;
    padding: 0px;
  }

  /* .title.aos-init.aos-animate b {
        font-size: 21px !important;
    }

    .tech-hero-section-6 .hero-content1 .title {
        font-size: 18px !important;
    } */

  /* h3.sub-title.aos-init.aos-animate {
        font-size: 15px !important;
    } */

  input#phone-foot {
    width: 260px !important;
  }

  .head-sec {
    padding: 2px 0px !important;
    width: 289px !important;
    margin-left: 0px !important;
    border-radius: 44px 29px 0px 0px !important;
  }

  .head-sec p span {
    font-size: 25px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .tech-hero-section-6 .hero-images.sk {
    padding-top: 33px !important;
    padding-bottom: 34px !important;
  }

  .hero-images.sk img {
    width: 46vw !important;
  }

  .hero-images.sk1 img {
    width: 46vw !important;
  }

  .hero-images.sk2 img {
    width: 43.9vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }

  .modal-content.download {
    /* border-radius: 44px 29px 0px 0px !important; */
    width: 290px !important;
    padding-bottom: 9px !important;
  }

  .sticky-button {
    top: 46% !important;
    height: 23vh !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .tech-hero-section-6 .hero-images.sk {
    padding-top: 48px !important;
    padding-bottom: 55px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 45px !important;
    padding-bottom: 48px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 45px !important;
    padding-bottom: 48px !important;
  }

  .hero-images.sk img {
    width: 32vw !important;
  }

  .hero-images.sk1 img {
    width: 33vw !important;
  }

  .hero-images.sk2 img {
    width: 33vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1480px) {
  .tech-hero-section-6 .hero-images.sk {
    padding-top: 48px !important;
    padding-bottom: 56px !important;
  }

  .tech-hero-section-6 .hero-images.sk1 {
    padding-top: 45px !important;
    padding-bottom: 48px !important;
  }

  .tech-hero-section-6 .hero-images.sk2 {
    padding-top: 45px !important;
    padding-bottom: 48px !important;
  }

  .hero-images.sk img {
    width: 40vw !important;
  }

  .hero-images.sk1 img {
    width: 41vw !important;
  }

  .hero-images.sk2 img {
    width: 41vw !important;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-top: 0px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  .top-left {
    margin-top: -34px;
  }
}

@media only screen and (max-width: 767px) {
  .top-left {
    margin-top: -30px;
  }

  .modal-dialog.download {
    top: 0px !important;
    position: fixed !important;
    float: right !important;
    right: -1px !important;
    margin-top: 65px !important;
  }

  .head-sec {
    /* width: auto;
        margin-left: 0px; */
    width: 500px;
    margin-left: 0px;
  }

  .page-banner-wrap {
    position: relative;
    height: auto;
    align-items: center;
  }

  h2.title.main-ban-head {
    margin-top: 27px;
  }

  .breadcrum1 {
    /* margin-top: 42px !important; */
    padding-bottom: 11px;
  }

  .game_mobile_animation {
    display: none;
  }

  .game_order_3 {
    order: -1;
  }

  .modal-content.download {
    width: auto !important;
    padding-bottom: 9px !important;
  }
}

@media only screen and (min-width: 399px) and (max-width: 403px) {
  .section.Brand-section {
    height: 84vh !important;
  }

  .head-sec {
    width: 367px !important;
    margin-left: 0px;
  }

  input#phones {
    width: 336px !important;
  }
}

@media only screen and (min-width: 470px) and (max-width: 480px) {
  .head-sec {
    width: 379px !important;
  }

  .head-sec.download {
    width: 347px !important;
  }

  input#phone-foot {
    width: 319px !important;
  }

  .modal-dialog.contact {
    left: 31px;
  }

  .head-sec {
    width: 401px !important;
  }
}

@media only screen and (min-width: 431px) and (max-width: 439px) {
  .head-sec {
    width: 377px !important;
    margin-left: 0px !important;
  }

  .head-sec.download {
    width: 312px !important;
  }

  input#phone-foot {
    width: 282px !important;
  }

  input#phonenum {
    width: 93vw !important;
  }

  input#phones {
    width: 341px !important;
  }

  .modal-dialog.contact {
    margin-left: -77px !important;
  }
}

@media only screen and (min-width: 417px) and (max-width: 430px) {
  .head-sec {
    width: 372px !important;
    margin-left: 0px !important;
  }

  input#phonenum {
    width: 93vw !important;
  }

  input#phones {
    width: 341px !important;
  }

  .modal-dialog.contact {
    margin-left: -77px !important;
  }
}

@media only screen and (min-width: 404px) and (max-width: 416px) {
  .image.sol img {
    max-width: 100vw !important;
    width: 100vw !important;
  }

  .head-sec {
    width: 362px !important;
    margin-left: 0px !important;
  }

  .head-sec.download {
    width: 298px !important;
  }

  input#phone-foot {
    width: 270px !important;
  }

  input#phonenum {
    width: 93vw !important;
  }

  .top-left {
    margin-top: -30px;
  }

  h2.title.discover {
    font-size: 26px;
    text-align: center;
  }

  .section.Brand-section {
    height: 66vh !important;
  }

  input#phones {
    width: 330px !important;
  }

  .modal-dialog.contact {
    margin-left: -77px !important;
  }
}

/***********Add CSS***************/
.nextSliderClassButton {
  right: -64px;
}

.nextSliderClassSpan {
  background-color: #6d6a6adb;
  margin-left: 7rem;
  height: 8vh;
}

.prevSliderClassButton {
  left: -58px;
  width: 4.5% !important;
}

.prevSliderClassSpan {
  background-color: #6d6a6adb;
  height: 8vh;
}

.ourPresenceBg {
  background-image: url("../images/bg/bg-maps.webp");
}

.gameBg {
  background-image: url("../images/solutions/igamings/Game-Page1.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.text_blue {
  color: #00aeef;
}

span.fa.fa-home,
span.fa.fa-trophy,
span.fa.fa-globe,
span.fa.fa-mobile {
  color: white;
}

.icon span {
  color: orange;
  margin-left: 5px;
  margin-right: 5px;
}

.font17 {
  font-size: 17px;
}

.full-width {
  width: 100%;
  height: auto;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.zoomInAnimation {
  animation: zoomIn 4s forwards;
}

.zoomOutAnimation {
  animation: zoomOut 4s backwards;
}

.paperLotteryTopBg {
  background: linear-gradient(
    90deg,
    rgba(238, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(238, 233, 233, 1) 100%
  );
}

.paperLotteryHeading {
  background-color: #00aeef;
  text-align: left;
  color: white;
  margin-top: 65px;
  width: 43%;
  padding: 1px 11px;
  font-size: 23px;
  margin-bottom: -12px;
  font-weight: 600;
  margin-left: 50px;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  font-size: 22px;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #ffffff;
  color: #00aeef;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid #efe9e9;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.accordion-header {
  background-color: #ffffff;
  border-radius: 50px;
}

.accordion-header button {
  font-weight: bold;
  color: #00aeef;
  text-align: left;
  width: 100%;
}

.accordion-header button.collapsed {
  color: #4c4d56;
  font-size: 20px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  color: blue;
  font-size: 20px;
  font-weight: 900;
}

.accordion-button::after {
  transition: transform 0.2s ease-in-out;
  color: blue;
}

.accordion-button:focus {
  box-shadow: none;
}

.digit_number {
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #333;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 50%;
  margin-right: 1%;
}

.upper-roman {
  list-style: none;
  font-size: 16px;
  padding: 2px 38px;
}

.list-dotted {
  list-style-type: disc;
  padding-left: 5%;
}

.color_4c4d56 {
  color: #4c4d56;
  font-size: 25px;
}

.faq_body {
  padding: 0px 80px 20px 80px;
}

.cgame_testiBg {
  background-image: url("../images/bg/testi-bg.jpg");
}

.more_solution {
  color: grey;
  font-size: 16px;
  font-weight: 500;
  margin-top: 18px;
}

.heading_font {
  /* font-size: 42px; */
  font-size: 48px;
  font-weight: 800;
  color: #333;
}

.heading_font span {
  color: #00aeef;
}

.font_on_mobile {
  font-size: 16px;
}

.title .pro-sol {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin-left: 48px;
  text-align: left;
  color: grey;
}

@media only screen and (max-width: 767px) {
  .upper-roman {
    list-style: none;
    font-size: 14px;
    padding: 2px 0px;
  }

  .color_4c4d56 {
    font-size: 18px;
    margin-top: 10px;
  }

  .accordion-button:not(.collapsed) {
    font-size: 14px;
  }

  .accordion-header button.collapsed {
    font-size: 14px;
  }

  .accordion-button:not(.collapsed)::after {
    font-size: 14px;
  }

  .accordion-header button {
    padding: 5px 0px;
  }

  .faq_body {
    padding: 5px 27px;
  }

  .heading_font {
    font-size: 20px;
  }

  .font_on_mobile {
    font-size: 14px;
  }
}

/* now for bug fixed */
.react-tel-input input[type="tel"] {
  padding-left: 10px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0px;
}

.react-tel-input {
  background-color: #f1f1f1;
  padding-left: 0px;
}

.bg_gray_select {
  background-color: #f1f1f1;
}

.mobile-sub-menu {
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: opacity 0.6s ease, transform 0.6s ease;
  max-height: 0;
  overflow: hidden;
}

.mobile-sub-menu.open {
  opacity: 1;
  max-height: 1000px;
  transform: translateY(0);
  visibility: visible;
}

.offcanvas-backdrop {
  pointer-events: none;
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #00aeef;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-phone-input {
  width: 85% !important;
  height: 40px !important;
  left: 15%;
}

.modalImage {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.blog_dynamic_list {
  list-style: disc;
  padding-left: 20px;
  font-weight: bold;
  line-height: 28px;
}

.blog_ol_list {
  list-style: auto;
  font-size: 14px;
  padding: 5px 20px;
  line-height: 28px;
}

.ol_blog_dynamic_list {
  font-weight: bold;
}

.color_blue {
  color: #00aeef;
}

ul.summary-list li {
  list-style: disc;
  padding: 6px;
  margin-left: 26px;
}

.font_16px {
  font-size: 16px;
}

.font_16px button {
  font-size: 15px !important;
}

.font_16px .accordion-button {
  font-size: 15px;
}

.swiper-button-next:after, .swiper-button-prev:after{
  color: #fefefea6;
}